import { Component, OnInit } from '@angular/core';
import { NotificationService } from 'src/app/notification.service';
import { Injectable } from '@angular/core';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.css']
})

@Injectable({
  providedIn: 'root'
})

export class NotificationsComponent implements OnInit {

  constructor(private notificationService: NotificationService) { }

  ngOnInit() {
  }


}
