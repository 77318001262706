

<div class="custom-cardss radius-10  border-0 border-3  my-3">
    <div class="custom-cardds-header p-3 text-black">
        <div>

        
        <h5 class="mb-1"><strong>{{contractorProfile.candidateNum}}</strong></h5>
        <h5 class="mb-1">Rank: <strong>{{rank}}</strong></h5>
    </div>
 
        <div class="my-3" >
            <span class="badge rounded-pill mx-1 " style="background-color: #BCE6FF; color: black;
            box-shadow:  0 2px 6px 0 rgb(218 218 253 / 65%), 0 2px 6px 0 rgb(206 206 238 / 54%)" *ngFor="let skill of contractorProfile.skills  | slice:0:1"> {{skill.description}}</span>
            
        </div>
    
    <div class="custom-card-body p-3">
        <!-- <p>Location based in {{contractorProfile.city}}</p> -->
        <p>Availability: <strong>{{contractorProfile.noticePeriod}}</strong></p>

    </div>
    <!-- <mat-divider class="mb-3"></mat-divider> -->
    <div class="custom-card-actions px-3 pb-3   text-white">
        <button class="text-white" (click) = "viewCandidate(contractorProfile)" mat-button>VIEW</button>
    </div>
</div>
</div>