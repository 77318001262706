import { Races, Titles, Provinces } from './user-profile-models';

export class UserProfileData {

    static races(): Races[] {
        return [
            { description: 'Black' },
            { description: 'Indian' },
            { description: 'Coloured' },
            { description: 'White' }
        ];
    }
    static titles(): Titles[] {
        return [
            { description: 'Dr' },
            { description: 'Prof' },
            { description: 'Mr' },
            { description: 'Mrs' },
            { description: 'Ms' },
            { description: 'Miss' },
        ];
    }
    static provinces(): Provinces[] {
        return [
            { description: 'Western Cape' },
            { description: 'Eastern Cape' },
            { description: 'Northern Cape' },
            { description: 'North West' },
            { description: 'Free State' },
            { description: 'KwaZulu-Natal' },
            { description: 'Gauteng' },
            { description: 'Limpopo' },
            { description: 'Mpumalanga' },
        ];
    }

    static citis(){
        return[
            { description: 'Alice' },
            { description: 'Butterworth' },
            { description: 'East London' },
            { description: 'Graaff-Reinet' },
            { description: 'Grahamstown' },
            { description: 'King William’s Town' },
            { description: 'Mthatha' },
            { description: 'Port Elizabeth' },
            { description: 'Queenstown' },
            { description: 'Uitenhage' },
            { description: 'Zwelitsha' } ,
            { description: 'Bethlehem' },
            { description: 'Bloemfontein' },
            { description: 'Jagersfontein' },
            { description: 'Kroonstad' },
            { description: 'Odendaalsrus' },
            { description: 'Parys' },
            { description: 'Phuthaditjhaba' },
            { description: 'Sasolburg' },
            { description: 'Virginia' },
            { description: 'Welkom' },
            { description: 'Benoni' },
            { description: 'Boksburg' },
            { description: 'Brakpan' },
            { description: 'Carletonville' },
            { description: 'Germiston' },
            { description: 'Johannesburg' },
            { description: 'Krugersdorp' },
            { description: 'Pretoria' },
            { description: 'Randburg' },
            { description: 'Randfontein' },
            { description: 'Roodepoort' },
            { description: 'Soweto' },
            { description: 'Springs' },
            { description: 'Vanderbijlpark' },
            { description: 'Vereeniging' },
            { description: 'Durban' },
                { description: 'Empangeni' },
                { description: 'Ladysmith' },
                { description: 'Newcastle' },
                { description: 'Pietermaritzburg' },
                { description: 'Pinetown' },
                { description: 'Ulundi' },
                { description: 'Umlazi' },
                { description: 'Giyani' },
                { description: 'Lebowakgomo' },
                { description: 'Musina' },
                { description: 'Phalaborwa' },
                { description: 'Polokwane' },
                { description: 'Seshego' },
                { description: 'Sibasa' },
                { description: 'Thabazimbi' },
                { description: 'Emalahleni' },
                { description: 'Nelspruit' },
                { description: 'Secunda' } ,
                { description: 'Klerksdorp' },
                { description: 'Mahikeng' },
                { description: 'Potchefstroom' },
                { description: 'Rustenburg' } ,
                { description: 'Kimberley' },
                { description: 'Kuruman' },
                { description: 'Port Nolloth' },
                { description: 'Bellville' },
                { description: 'Cape Town' },
                { description: 'Constantia' },
                { description: 'George' },
                { description: 'Hopefield' },
                { description: 'Oudtshoorn' },
                { description: 'Paarl' },
                { description: 'Simon’s Town' },
                { description: 'Stellenbosch' },
                { description: 'Swellendam' },
                { description: 'Worcester' }
        ]
    }
    static cities(){
        return {
            EasternCape: [
                { description: 'Alice' },
                { description: 'Butterworth' },
                { description: 'East London' },
                { description: 'Graaff-Reinet' },
                { description: 'Grahamstown' },
                { description: 'King William’s Town' },
                { description: 'Mthatha' },
                { description: 'Port Elizabeth' },
                { description: 'Queenstown' },
                { description: 'Uitenhage' },
                { description: 'Zwelitsha' }
            ],
            FreeState: [
                { description: 'Bethlehem' },
                { description: 'Bloemfontein' },
                { description: 'Jagersfontein' },
                { description: 'Kroonstad' },
                { description: 'Odendaalsrus' },
                { description: 'Parys' },
                { description: 'Phuthaditjhaba' },
                { description: 'Sasolburg' },
                { description: 'Virginia' },
                { description: 'Welkom' }
            ],
            Gauteng: [
                { description: 'Benoni' },
                { description: 'Boksburg' },
                { description: 'Brakpan' },
                { description: 'Carletonville' },
                { description: 'Germiston' },
                { description: 'Johannesburg' },
                { description: 'Krugersdorp' },
                { description: 'Pretoria' },
                { description: 'Randburg' },
                { description: 'Randfontein' },
                { description: 'Roodepoort' },
                { description: 'Soweto' },
                { description: 'Springs' },
                { description: 'Vanderbijlpark' },
                { description: 'Vereeniging' }
            ],
            KwaZuluNatal: [
                { description: 'Durban' },
                { description: 'Empangeni' },
                { description: 'Ladysmith' },
                { description: 'Newcastle' },
                { description: 'Pietermaritzburg' },
                { description: 'Pinetown' },
                { description: 'Ulundi' },
                { description: 'Umlazi' }
            ],
            Limpopo: [
                { description: 'Giyani' },
                { description: 'Lebowakgomo' },
                { description: 'Musina' },
                { description: 'Phalaborwa' },
                { description: 'Polokwane' },
                { description: 'Seshego' },
                { description: 'Sibasa' },
                { description: 'Thabazimbi' }
            ],
            Mpumalanga: [
                { description: 'Emalahleni' },
                { description: 'Nelspruit' },
                { description: 'Secunda' }
            ],
            NorthWest: [
                { description: 'Klerksdorp' },
                { description: 'Mahikeng' },
                { description: 'Potchefstroom' },
                { description: 'Rustenburg' }
            ],
            NorthernCape: [
                { description: 'Kimberley' },
                { description: 'Kuruman' },
                { description: 'Port Nolloth' }
            ],
            WesternCape: [
                { description: 'Bellville' },
                { description: 'Cape Town' },
                { description: 'Constantia' },
                { description: 'George' },
                { description: 'Hopefield' },
                { description: 'Oudtshoorn' },
                { description: 'Paarl' },
                { description: 'Simon’s Town' },
                { description: 'Stellenbosch' },
                { description: 'Swellendam' },
                { description: 'Worcester' }
            ]
        }
    } 

    static noticePeriods(){
        return [
            { description: 'Immediately' },
            { description: '1 Week' },
            { description: '2 Weeks' },
            { description: '1 Month' },
            { description: '2 Months' },
            { description: '3 Months' },
            { description: '4 Months' },
            { description: '5 Months' },
            { description: '6 Months' },
            { description: '7 Months' },
            { description: '8 Months' },
            { description: '9 Months' },
            { description: '10 Months' },
            { description: '11 Months' },
            { description: '12 Months' }
        ]
    }

    static genders() {
        return [    
            {description: 'Male'},
            {description: 'Female'},
            {description: 'Other'},
         
        ]
    }

    static levels() {
        return [
            { description: 'Junior' },
            { description: 'Intermediate' },
            { description: 'Senior' }
        ]
    }

    static nationalities() {
        return [
            { description: 'Afghan', flagClass: 'fi-af', countryCode: 'AF' },
            { description: 'Albanian', flagClass: 'fi-al', countryCode: 'AL' },
            { description: 'Algerian', flagClass: 'fi-dz', countryCode: 'DZ' },
            { description: 'Argentine', flagClass: 'fi-ar', countryCode: 'AR' },
            { description: 'Argentinian', flagClass: 'fi-ar', countryCode: 'AR' },
            { description: 'Australian', flagClass: 'fi-au', countryCode: 'AU' },
            { description: 'Austrian', flagClass: 'fi-at', countryCode: 'AT' },
            { description: 'Bangladeshi', flagClass: 'fi-bd', countryCode: 'BD' },
            { description: 'Belgian', flagClass: 'fi-be', countryCode: 'BE' },
            { description: 'Bolivian', flagClass: 'fi-bo', countryCode: 'BO' },
            { description: 'Botswanan', flagClass: 'fi-bw', countryCode: 'BW' },
            { description: 'Brazilian', flagClass: 'fi-br', countryCode: 'BR' },
            { description: 'Bulgarian', flagClass: 'fi-bg', countryCode: 'BG' },
            { description: 'Cambodian', flagClass: 'fi-kh', countryCode: 'KH' },
            { description: 'Cameroonian', flagClass: 'fi-cm', countryCode: 'CM' },
            { description: 'Canadian', flagClass: 'fi-ca', countryCode: 'CA' },
            { description: 'Chilean', flagClass: 'fi-cl', countryCode: 'CL' },
            { description: 'Chinese', flagClass: 'fi-cn', countryCode: 'CN' },
            { description: 'Colombian', flagClass: 'fi-co', countryCode: 'CO' },
            { description: 'Costa Rican', flagClass: 'fi-cr', countryCode: 'CR' },
            { description: 'Croatian', flagClass: 'fi-hr', countryCode: 'HR' },
            { description: 'Cuban', flagClass: 'fi-cu', countryCode: 'CU' },
            { description: 'Czech', flagClass: 'fi-cz', countryCode: 'CZ' },
            { description: 'Danish', flagClass: 'fi-dk', countryCode: 'DK' },
            { description: 'Dominican', flagClass: 'fi-do', countryCode: 'DO' },
            { description: 'Ecuadorian', flagClass: 'fi-ec', countryCode: 'EC' },
            { description: 'Egyptian', flagClass: 'fi-eg', countryCode: 'EG' },
            { description: 'Salvadorian', flagClass: 'fi-sv', countryCode: 'SV' },
            { description: 'English', flagClass: 'fi-gb', countryCode: 'GB' },
            { description: 'Estonian', flagClass: 'fi-ee', countryCode: 'EE' },
            { description: 'Ethiopian', flagClass: 'fi-et', countryCode: 'ET' },
            { description: 'Fijian', flagClass: 'fi-fj', countryCode: 'FJ' },
            { description: 'Finnish', flagClass: 'fi-fi', countryCode: 'FI' },
            { description: 'French', flagClass: 'fi-fr', countryCode: 'FR' },
            { description: 'German', flagClass: 'fi-de', countryCode: 'DE' },
            { description: 'Ghanaian', flagClass: 'fi-gh', countryCode: 'GH' },
            { description: 'Greek', flagClass: 'fi-gr', countryCode: 'GR' },
            { description: 'Guatemalan', flagClass: 'fi-gt', countryCode: 'GT' },
            { description: 'Haitian', flagClass: 'fi-ht', countryCode: 'HT' },
            { description: 'Honduran', flagClass: 'fi-hn', countryCode: 'HN' },
            { description: 'Hungarian', flagClass: 'fi-hu', countryCode: 'HU' },
            { description: 'Icelandic', flagClass: 'fi-is', countryCode: 'IS' },
            { description: 'Indian', flagClass: 'fi-in', countryCode: 'IN' },
            { description: 'Indonesian', flagClass: 'fi-id', countryCode: 'ID' },
            { description: 'Iranian', flagClass: 'fi-ir', countryCode: 'IR' },
            { description: 'Iraqi', flagClass: 'fi-iq', countryCode: 'IQ' },
            { description: 'Irish', flagClass: 'fi-ie', countryCode: 'IE' },
            { description: 'Israeli', flagClass: 'fi-il', countryCode: 'IL' },
            { description: 'Italian', flagClass: 'fi-it', countryCode: 'IT' },
            { description: 'Jamaican', flagClass: 'fi-jm', countryCode: 'JM' },
            { description: 'Japanese', flagClass: 'fi-jp', countryCode: 'JP' },
            { description: 'Jordanian', flagClass: 'fi-jo', countryCode: 'JO' },
            { description: 'Kenyan', flagClass: 'fi-ke', countryCode: 'KE' },
            { description: 'Kuwaiti', flagClass: 'fi-kw', countryCode: 'KW' },
            { description: 'Lao', flagClass: 'fi-la', countryCode: 'LA' },
            { description: 'Latvian', flagClass: 'fi-lv', countryCode: 'LV' },
            { description: 'Lebanese', flagClass: 'fi-lb', countryCode: 'LB' },
            { description: 'Libyan', flagClass: 'fi-ly', countryCode: 'LY' },
            { description: 'Lithuanian', flagClass: 'fi-lt', countryCode: 'LT' },
            { description: 'Malagasy', flagClass: 'fi-mg', countryCode: 'MG' },
            { description: 'Malaysian', flagClass: 'fi-my', countryCode: 'MY' },
            { description: 'Malian', flagClass: 'fi-ml', countryCode: 'ML' },
            { description: 'Maltese', flagClass: 'fi-mt', countryCode: 'MT' },
            { description: 'Mexican', flagClass: 'fi-mx', countryCode: 'MX' },
            { description: 'Mongolian', flagClass: 'fi-mn', countryCode: 'MN' },
            { description: 'Moroccan', flagClass: 'fi-ma', countryCode: 'MA' },
            { description: 'Mozambican', flagClass: 'fi-mz', countryCode: 'MZ' },
            { description: 'Namibian', flagClass: 'fi-na', countryCode: 'NA' },
            { description: 'Nepalese', flagClass: 'fi-np', countryCode: 'NP' },
            { description: 'Dutch', flagClass: 'fi-nl', countryCode: 'NL' },
            { description: 'New Zealander', flagClass: 'fi-nz', countryCode: 'NZ' },
            { description: 'Nicaraguan', flagClass: 'fi-ni', countryCode: 'NI' },
            { description: 'Nigerian', flagClass: 'fi-ng', countryCode: 'NG' },
            { description: 'Norwegian', flagClass: 'fi-no', countryCode: 'NO' },
            { description: 'Pakistani', flagClass: 'fi-pk', countryCode: 'PK' },
            { description: 'Panamanian', flagClass: 'fi-pa', countryCode: 'PA' },
            { description: 'Paraguayan', flagClass: 'fi-py', countryCode: 'PY' },
            { description: 'Peruvian', flagClass: 'fi-pe', countryCode: 'PE' },
            { description: 'Philippine', flagClass: 'fi-ph', countryCode: 'PH' },
            { description: 'Polish', flagClass: 'fi-pl', countryCode: 'PL' },
            { description: 'Portuguese', flagClass: 'fi-pt', countryCode: 'PT' },
            { description: 'Romanian', flagClass: 'fi-ro', countryCode: 'RO' },
            { description: 'Russian', flagClass: 'fi-ru', countryCode: 'RU' },
            { description: 'Saudi', flagClass: 'fi-sa', countryCode: 'SA' },
            { description: 'Scottish', flagClass: 'fi-gb-sct', countryCode: 'GB' },
            { description: 'Senegalese', flagClass: 'fi-sn', countryCode: 'SN' },
            { description: 'Serbian', flagClass: 'fi-rs', countryCode: 'RS' },
            { description: 'Singaporean', flagClass: 'fi-sg', countryCode: 'SG' },
            { description: 'Slovak', flagClass: 'fi-sk', countryCode: 'SK' },
            { description: 'South African', flagClass: 'fi-za', countryCode: 'ZA' },
            { description: 'Korean', flagClass: 'fi-kr', countryCode: 'KR' },
            { description: 'Spanish', flagClass: 'fi-es', countryCode: 'ES' },
            { description: 'Sri Lankan', flagClass: 'fi-lk', countryCode: 'LK' },
            { description: 'Sudanese', flagClass: 'fi-sd', countryCode: 'SD' },
            { description: 'Swedish', flagClass: 'fi-se', countryCode: 'SE' },
            { description: 'Swiss', flagClass: 'fi-ch', countryCode: 'CH' },
            { description: 'Syrian', flagClass: 'fi-sy', countryCode: 'SY' },
            { description: 'Taiwanese', flagClass: 'fi-tw', countryCode: 'TW' },
            { description: 'Tajikistani', flagClass: 'fi-tj', countryCode: 'TJ' },
            { description: 'Thai', flagClass: 'fi-th', countryCode: 'TH' },
            { description: 'Tongan', flagClass: 'fi-to', countryCode: 'TO' },
            { description: 'Tunisian', flagClass: 'fi-tn', countryCode: 'TN' },
            { description: 'Turkish', flagClass: 'fi-tr', countryCode: 'TR' },
            { description: 'Ukrainian', flagClass: 'fi-ua', countryCode: 'UA' },
            { description: 'Emirati', flagClass: 'fi-ae', countryCode: 'AE' },
            { description: 'British', flagClass: 'fi-gb-eng', countryCode: 'GB' },
            { description: 'American', flagClass: 'fi-us', countryCode: 'US' },
            { description: 'Uruguayan', flagClass: 'fi-uy', countryCode: 'UY' },
            { description: 'Venezuelan', flagClass: 'fi-ve', countryCode: 'VE' },
            { description: 'Vietnamese', flagClass: 'fi-vn', countryCode: 'VN' },
            { description: 'Welsh', flagClass: 'fi-gb-wls', countryCode: 'GB' },
            { description: 'Zambian', flagClass: 'fi-zm', countryCode: 'ZM' },
            { description: 'Zimbabwean', flagClass: 'fi-zw', countryCode: 'ZW' }
        ];
    }
    
    
}
