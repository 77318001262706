<mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
 
<!-- <nav class="navbar" style="background: linear-gradient(90deg, #40B9F0 0%, rgba(23, 14, 102, 0.90) 100%);">
    <div class="container-fluid d-flex justify-content-center">
        <h4 class="text-white text-weight-bold">Blue Desk</h4>
       
    </div>
   
</nav>  -->
<!-- <div>
    <button style=" float:right;
    margin-left: 5px;" class="rounded-pill m-1 actd" (click)="ratePerHour()" mat-raised-button>
        Rate per hour fee 
    </button>
</div> -->


<div class="row mb-5 mt-4">
    <div class="col"  >
        <h5><b>Job Posts</b></h5>
        
        <h6 class="font-weight-light" >All jobs you have posted are presented here.</h6>  
  
    </div>
    <hr>
    <div class="col d-flex justify-content-center   m-1 ">
        
        <button style="background-color: #017DB5; color: white;" class="rounded-pill m-1  act" (click)="onClickAddProfile()"  mat-raised-button>
            <b> + Add a new Job</b> 
            </button>
    </div>
   
</div>
<div class="row">
<div class="col">
    <mat-form-field class="mt-1" >
        <mat-label>Select an option</mat-label>
        <mat-select [(value)]="selected" >
       
          <mat-option  (click)="setStep(1)" value="1">Active</mat-option>
          <!-- <mat-option value="option2">ALL</mat-option> -->
          <mat-option (click)="setStep(2)" value="2">Expired</mat-option>
          <mat-option (click)="setStep(3)" value="2">Placed Jobs</mat-option>
        </mat-select>
      </mat-form-field>
</div>

</div>
<div class="container-fluid" >
    <div class=" ">
       
       
        
       
       
          <!-- <mat-divider class="my-2"></mat-divider>  -->

        <div *ngIf="jobStep === 1">
            <div *ngIf="isShown" class="row">
                <!-- | paginate: {itemsPerPage: size, currentPage : page, totalItems:count};  let i= index" -->
                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-3 col-xl-3 " *ngFor="let jobProf of activeJobProfiles "> 
                    <div class="card mb-3">
                        <div class="card-body" style="background:#F4F7FF !important;height:290px; border-radius: 1px; box-shadow:  0 2px 6px 0 rgb(218 218 253 / 65%), 0 2px 6px 0 rgb(206 206 238 / 54%);">
                            <span class="badge bg-warning" *ngIf="jobProf.duplicate">Copy</span>
                            

                                <div class="d-flex justify-content-between">
                                    <h5 class="d-flex align-items-center text-black card-title"><b>{{jobProf.title}}</b></h5>
                                      <div class="dropdown-center">
                                          <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                                              <mat-icon>more_vert</mat-icon>
                                            </button>
                                            <mat-menu #menu="matMenu" xPosition="before">
                                              <button mat-menu-item (click)="editProfile(jobProf)">
                                                <mat-icon>edit</mat-icon>
                                                <span>Edit</span>
                                              </button>
                                              <button mat-menu-item (click)="duplicateJob(jobProf)">
                                                <mat-icon>file_copy</mat-icon>
                                                <span>Copy</span>
                                              </button> 
                                             
                                             
                                              <button mat-menu-item  (click)="closeJob(jobProf, true,false)">
                                                <mat-icon style="color: rgb(248, 239, 65);" closeJob>brightness_1</mat-icon>
                                                <span>Close</span>
                                              </button>
                                              <button mat-menu-item (click)="closeJob(jobProf, false,true)">
                                                <mat-icon style="color: rgb(70, 242, 150);">brightness_1</mat-icon>
                                                <span>Placed</span>
                                              </button>
                                              <button mat-menu-item (click)="Delete(jobProf.identifier)">
                                                <mat-icon>delete_forever</mat-icon>
                                                <span>Delete</span>
                                              </button>
                                            </mat-menu>
                                        </div>
                                    </div>
                                    <div>
                                      
                                    </div>
                                    <div class="my-3" >
                                      
        <a class="btn" style="background-color: #BCE6FF; color: black;border-radius: 10px;height:7%;font-size: 11px;padding: 3px;font-weight: bold;
        box-shadow:  0 2px 6px 0 rgb(218 218 253 / 65%), 0 2px 6px 0 rgb(206 206 238 / 54%)" *ngFor="let skill of jobProf.skills  |slice:0:1"> {{skill.description}} </a>
        
                                    </div>

                             
                                    <div class="row text-black small">
                                        <div class="col-1">
                                            <i class="bi bi-geo-alt-fill"></i>
                                        </div>
                                        <div class="col">
                                            <strong>Based in:</strong>   {{jobProf.jobLocation}}
                                        </div>
                                        
                                      </div>
        
                                      <div class="row text-black small">
                                        <div class="col-1">
                                            <i class="bi bi-cash-stack"></i>
                                        </div>
                                        <div class="col">
                                            <strong>Rate/hr:</strong> {{jobProf.ratePerHour | currency:'R'}}
                                        </div>
                                        
                                      </div>
        
                                      <div class="row text-black small">
                                        <div class="col-1">
                                            <i class="bi bi-calendar-date"></i>
                                        </div>
                                        <div class="col">
                                            <strong>Closing date:</strong> {{jobProf.closingDate | date:'dd-MMM-yy'}}
                                        </div>
                                        
                                        
                                     </div>
                                      <div class="row text-black small">
                                        <div class="col-1">
                                            <i class="bi bi-clock"></i>
                                        </div>
                                        <div class="col">
                                            <strong>Contract duration:</strong> {{jobProf.contractDurationInMonths}} months
                                        </div>
                                        
                                      </div><br>
                                      <!--<div class="row text-black small">
                                        <div class="col-1">
                                            <i class="bi bi-clock" style="color: rgb(210, 204, 204);"></i>
                                        </div>
                                        <div class="col" style="color: rgb(210, 204, 204);font-size: smaller;" >
                                            <strong>Created by:</strong> {{jobProf.createdBy }}
                                        </div>
                                        
                                      </div>-->
                                      </div>
                        <div class=" d-flex justify-content-center " style="background-color: #F4F7FF;" >
                            <button class="text-white mb-3" style="background-color: #017DB5; color: white;" (click)="matchedProfiles(jobProf.identifier)" mat-button>
                                View Matches
                            </button>
                        </div>
                        <!--<div class=" d-flex justify-content-center " style="background-color: #F4F7FF;" >
                            <button class="text-white mb-3" style="background-color: #01b57f; color: white;" (click)="AImatch(jobProf.identifier)" mat-button>
                                <i class="fa-solid fa-wand-sparkles"></i> View Matches
                            </button>
                        </div>-->
                    </div>
                </div>
                <a href=""></a>
                <!-- <a class="myBtn" >
                    
                      <svg (click)="scrollToBottom()"  xmlns="http://www.w3.org/2000/svg" width="46" height="46" fill="currentColor" class="bi bi-arrow-down-square" viewBox="0 0 16 16">
                        <path fill-rule="evenodd" d="M15 2a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1zM0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm8.5 2.5a.5.5 0 0 0-1 0v5.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293z"/>
                      </svg>
                    </a> -->
<!-- <button (click)="scrollToBottom($event)" class="myBtn">
button
</button> -->
                <!-- <div class="bg-light pt-3 " >
                    <pagination-controls (pageChange)="page = $event"></pagination-controls>
                </div> -->
            </div>
            
        </div>
    
        <div *ngIf="jobStep === 2">
            <div class="row">
                <!-- | paginate: {itemsPerPage: size, currentPage : page, totalItems:count};  let i= index" -->
                <div class="col-xs-12 col-sm-12 col-md-4 col-lg-3 col-xl-3"  *ngFor="let jobProf of expiredJobProfiles">
                    <div class="card mb-3">
                        <div class="card-body" style="background:#F4F7FF !important;height:290px !important; border-radius: 1px; box-shadow:  0 2px 6px 0 rgb(218 218 253 / 65%), 0 2px 6px 0 rgb(206 206 238 / 54%);">
                  
                            <span class="badge bg-warning" *ngIf="jobProf.duplicate">Copy</span>
           
                                <div class="d-flex justify-content-between">
                                    <h5 class="d-flex align-items-center text-black card-title"><b>{{jobProf.title}}</b></h5>
                                      <div class="dropdown-center">
                                          <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                                              <mat-icon>more_vert</mat-icon>
                                            </button>
                                            <mat-menu #menu="matMenu" xPosition="before">
                                              <button mat-menu-item (click)="editProfile(jobProf)">
                                                <mat-icon>edit</mat-icon>
                                                <span>Edit</span>
                                              </button>
                                              <button mat-menu-item (click)="duplicateJob(jobProf)">
                                                <mat-icon>file_copy</mat-icon>
                                                <span>Copy</span>
                                              </button>
                                              <button mat-menu-item  (click)="closeJob(jobProf, true,false)">
                                                <mat-icon style="color: rgb(248, 239, 65);" closeJob>brightness_1</mat-icon>
                                                <span>Close</span>
                                              </button>
                                              <button mat-menu-item (click)="closeJob(jobProf, false,true)">
                                                <mat-icon style="color: rgb(70, 242, 150);">brightness_1</mat-icon>
                                                <span>Placed</span>
                                              </button>
                                              <button mat-menu-item (click)="Delete(jobProf.identifier)">
                                                <mat-icon>delete_forever</mat-icon>
                                                <span>Delete</span>
                                              </button>
                                            </mat-menu>
                                        </div>
                                                            </div>
                        <div class="my-1">
                            <div class="mb-3">
                                <!-- <p class=""> {{jobProf.description}}</p> -->
                            
                            </div>

                            <div class="my-3">
                                <a  class="btn"
                             style="background-color: #BCE6FF; color: black;border-radius: 1px;height:7%;font-size: 11px;padding: 3px;font-weight: bold;
                                box-shadow:  0 2px 6px 0 rgb(218 218 253 / 65%), 0 2px 6px 0 rgb(206 206 238 / 54%)" *ngFor="let skill of jobProf.skills  | slice:0:1"> {{skill.description}} </a>
                               
                            </div>
                           
                        </div>
                                                        

                           
                            <div class="row text-black small">
                                <div class="col-1">
                                    <i class="bi bi-geo-alt-fill"></i>
                                </div>
                                <div class="col">
                                    <strong>Based in:</strong>   {{jobProf.jobLocation}}
                                </div>
                                
                              </div>

                              <div class="row text-black small">
                                <div class="col-1">
                                    <i class="bi bi-cash-stack"></i>
                                </div>
                                <div class="col">
                                    <strong>Rate/hr:</strong> {{jobProf.ratePerHour | currency:'R'}}
                                </div>
                                
                              </div>

                              <div class="row text-black small">
                                <div class="col-1">
                                    <i class="bi bi-clock"></i>
                                </div>
                                <div class="col">
                                    <strong>Closing date:</strong> {{jobProf.closingDate | date:'dd-MMM-yy'}}
                                </div>
                                
                                
                             </div>
                              <div class="row text-black small">
                                <div class="col-1">
                                    <i class="bi bi-clock"></i>
                                </div>
                                <div class="col">
                                    <strong>Contract duration:</strong> {{jobProf.contractDurationInMonths}} months
                                </div>
                                
                              </div>
                              <div class="row text-black small">
                                <div class="col-1">
                                    <i class="bi bi-clock"></i>
                                </div>
                                <div class="col" >
                                    <strong>Created by:</strong> {{jobProf.createdBy }}
                                </div>
                                
                              </div>
                             
                              
                        </div>
                        <div class=" d-flex justify-content-center " style="background-color: #F4F7FF;" >
                            <button class="text-white mb-3" style="background-color:#017DB5; color: white;" (click)="matchedProfiles(jobProf.identifier)" mat-button>
                                View Matches
                            </button>
                        </div>
                    </div>
                    
                </div>
                <div class="bg-light pt-3 " >
                    <pagination-controls (pageChange)="page = $event"></pagination-controls>
                </div>
               

               
                
            </div>
           
        </div>
    
        <div *ngIf="jobStep === 3">
            <div class="row">
                <!-- | paginate: {itemsPerPage: size, currentPage : page, totalItems:count};  let i= index" -->
                <div class="col-xs-12 col-sm-12 col-md-4 col-lg-3 col-xl-3"  *ngFor="let jobProf of closedWonJobProfiles ">
                    <div class="card mb-3">
                        <div class="card-body" style="background:#F4F7FF !important;height:290px !important; border-radius: 1px; box-shadow:  0 2px 6px 0 rgb(218 218 253 / 65%), 0 2px 6px 0 rgb(206 206 238 / 54%);">
                  
                            <span class="badge bg-warning" *ngIf="jobProf.duplicate">Copy</span>
           
                                <div class="d-flex justify-content-between">
                                    <h5 class="d-flex align-items-center text-black card-title"><b>{{jobProf.title}}</b></h5>
                                      <div class="dropdown-center">
                                          <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                                              <mat-icon>more_vert</mat-icon>
                                            </button>
                                            <mat-menu #menu="matMenu" xPosition="before">
                                              <button mat-menu-item (click)="editProfile(jobProf)">
                                                <mat-icon>edit</mat-icon>
                                                <span>Edit</span>
                                              </button>
                                              <!-- <button mat-menu-item (click)="duplicateJob(jobProf)">
                                                <mat-icon>file_copy</mat-icon>
                                                <span>Copy</span>
                                              </button> -->
                                              <button mat-menu-item (click)="Delete(jobProf.identifier)">
                                                <mat-icon>delete_forever</mat-icon>
                                                <span>Delete</span>
                                              </button>
                                            </mat-menu>
                                        </div>
                                                            </div>
                        <div class="my-1">
                            <div class="mb-3">
                                <!-- <p class=""> {{jobProf.description}}</p> -->
                            
                            </div>

                            <div class="my-3">
                                <a  class="btn"
                             style="background-color: #BCE6FF; color: black;border-radius: 1px;height:7%;font-size: 11px;padding: 3px;font-weight: bold;
                                box-shadow:  0 2px 6px 0 rgb(218 218 253 / 65%), 0 2px 6px 0 rgb(206 206 238 / 54%)" *ngFor="let skill of jobProf.skills  | slice:0:1"> {{skill.description}} </a>
                               
                            </div>
                           
                        </div>
                                                        

                           
                            <div class="row text-black small">
                                <div class="col-1">
                                    <i class="bi bi-geo-alt-fill"></i>
                                </div>
                                <div class="col">
                                    <strong>Based in:</strong>   {{jobProf.jobLocation}}
                                </div>
                                
                              </div>

                              <div class="row text-black small">
                                <div class="col-1">
                                    <i class="bi bi-cash-stack"></i>
                                </div>
                                <div class="col">
                                    <strong>Rate/hr:</strong> {{jobProf.ratePerHour | currency:'R'}}
                                </div>
                                
                              </div>

                              <div class="row text-black small">
                                <div class="col-1">
                                    <i class="bi bi-clock"></i>
                                </div>
                                <div class="col">
                                    <strong>Closing date:</strong> {{jobProf.closingDate | date:'dd-MMM-yy'}}
                                </div>
                                
                                
                             </div>
                              <div class="row text-black small">
                                <div class="col-1">
                                    <i class="bi bi-clock"></i>
                                </div>
                                <div class="col">
                                    <strong>Contract duration:</strong> {{jobProf.contractDurationInMonths}} months
                                </div>
                                
                              </div>
                              <div class="row text-black small">
                                <div class="col-1">
                                    <i class="bi bi-clock"></i>
                                </div>
                                <div class="col" >
                                    <strong>Created by:</strong> {{jobProf.createdBy }}
                                </div>
                                
                              </div>
                              
                        </div>
                        <div class=" d-flex justify-content-center " style="background-color: #F4F7FF;" >
                            <button class="text-white mb-3" style="background-color:#017DB5; color: white;" (click)="matchedProfiles(jobProf.identifier)" mat-button>
                                View Matches
                            </button>
                        </div>
                    </div>
                    
                </div>
                <!-- <div class="bg-light pt-3 " >
                    <pagination-controls (pageChange)="page = $event"></pagination-controls>
                </div> -->
            </div>
        </div>
        
    </div>
    
</div>


