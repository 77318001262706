import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { SovernService } from '../modules/contractor-profile/sovern.service';
import { WizardService } from 'src/app/modules/onboarding-wizard/wizard.service'
import { SharedDataService } from '../shared-data.service';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-contractor-onboarding',
  templateUrl: './contractor-onboarding.component.html',
  styleUrls: ['./contractor-onboarding.component.css']
})
export class ContractorOnboardingComponent {
  @ViewChild('fileInput') fileInput!: ElementRef<HTMLInputElement>;
  selectedFile: File | null = null;
  isLoading: boolean = false;
fileName: any;

  constructor(
    private sovrenService: SovernService,
    private router: Router,
    private wizardService: WizardService,
    private dialog: MatDialog,
    private sharedDataService: SharedDataService,
    public dialogRef: MatDialogRef<ContractorOnboardingComponent>
  ) {}

  handleFileInput(event: any): void {
    const files = event.target.files;
    if (files && files.length > 0) {
      this.selectedFile = files[0];
    }
  }

  uploadResume(): void {
    if (!this.selectedFile) {
      return;
    }
    this.isLoading = true;

    this.sovrenService.uploadResume(this.selectedFile).subscribe({
      next: (result) => {
        this.isLoading = false; // Set to false when loading is complete

        // Save the result in local storage for later retrieval in profile edit component
        localStorage.setItem('uploadResult', JSON.stringify(result));
        // Use SharedDataService to pass data to the profile edit component
        this.sharedDataService.updateResumeData(result);
        // Navigate to profile edit page
        this.router.navigate(['main/user-profile/edit']);
        this.dialogRef.close(result);
        this.wizardService.isContractorFirstTimeRegistration = false;
        // Clear the marker in local storage
        localStorage.removeItem('isContractorFirstTimeRegistration');
      },
      error: (error) => {
        this.isLoading = false; // Set to false in case of error
        console.error('Error uploading resume:', error);
      }
    });
  }

  LinkedinSignin(): void {
    // Specify the URL you want to open in a new tab
    const url = 'https://www.linkedin.com/uas/login?s';

    // Open a new tab with the specified URL
    window.open(url, '_blank');
  }

  LinkedinResume(): void {
    // Specify the URL you want to open in a new tab
    const url = 'https://www.linkedin.com/jobs/?showResumeBuilderModal=true';

    // Open a new tab with the specified URL
    window.open(url, '_blank');
  }
}
