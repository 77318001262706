import { Component, ElementRef, HostListener, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NavigationStart, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AuthenticationManagementService } from 'src/app/authentication-management.service';
import { WizardService } from 'src/app/modules/onboarding-wizard/wizard.service';
import { CognitoError } from 'src/app/shared/http-error-handler';

@Component({
  selector: 'app-login-modal',
  templateUrl: './login-modal.component.html',
  styleUrls: ['./login-modal.component.css']
})
export class LoginModalComponent implements OnInit, OnDestroy {
  private errorSubscription!: Subscription;
  private routerSubscription!: Subscription;
  email!: FormControl;
  password!: FormControl;
  errorMessage!: string;
  loading!: boolean;
  passwordType = "password"
  checkboxValue: boolean = false;
  buttonDisabled: boolean = true;
  checkbox = new FormControl();
  userType: any;
  username: any;
  notificationService: any;
  notification: any;
  modalContent: HTMLDivElement | null = null;

  constructor(
    private wizardService: WizardService,
    public dialogRef: MatDialogRef<LoginModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private authManagementService: AuthenticationManagementService,
    private elementRef: ElementRef,
    private router: Router
  ) {}

  ngOnInit() {
    this.modalContent = this.elementRef.nativeElement.querySelector('.modal-content');

    this.errorSubscription = this.wizardService.errorEvent$.subscribe((errorMessage) => {
      this.errorMessage = errorMessage;
      this.loading = false;
    });

    this.email = new FormControl('', [Validators.required, Validators.email]);
    this.password = new FormControl('', [Validators.required]);
    this.errorMessage = "";
    this.checkbox.valueChanges.subscribe((value) => {
      this.buttonDisabled = !value;
    });

    if (this.userType && this.username) {
      this.notificationService.getNotifications(this.username)
        .subscribe(notifications => {
          if (notifications) {
            this.notification = notifications;
          } else {
            this.notification = [];
          }
        });
    }

    this.closeModalOnNavigation();
  }

  ngOnDestroy() {
    if (this.errorSubscription) {
      this.errorSubscription.unsubscribe();
    }
    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }
  }

  onClose(): void {
    this.dialogRef.close();
  }

  get hasErrors(): boolean {
    return this.errorMessage.length > 0;
  }

  login() {
    this.emailAddressOnBlur();
    if (this.email.invalid || this.password.invalid) {
      this.errorMessage = "Your username or password is invalid";
      return;
    }

    this.loading = true;
    this.wizardService.login(this.email.value.toLowerCase(), this.password.value)
      .subscribe(() => {
        this.wizardService.getUserProfile();
        this.wizardService.clearAuthData();
        this.dialogRef.close();
      }, (loginError: CognitoError) => {
        if (loginError.message == 'User is not confirmed.') {
          this.wizardService.resendVerificationCode();
          this.wizardService.loginSubject.next('verify_email');
        } else {
          this.authManagementService.triggerEvent(false);
          this.wizardService.errorSubject.next(loginError.message);
        }
      });
  }

  toggleVisibility() {
    this.passwordType = (this.passwordType === "password") ? "text" : "password";
  }

  get passwordVisible(): boolean {
    return this.passwordType === "text";
  }

  emailAddressOnBlur() {
    this.email.setValue(this.email.value.toLowerCase());
    this.email.updateValueAndValidity();
  }

  onOutsideClick(event: MouseEvent) {
    const target = event.target as HTMLElement;
    if (this.modalContent && !this.modalContent.contains(target)) {
      this.dialogRef.close();
    }
  }
  
  closeModalOnNavigation() {
    this.routerSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.dialogRef.close();
      }
    });
  }

  @HostListener('document:keydown.escape', ['$event'])
  onKeydownHandler(event: KeyboardEvent) {
    this.dialogRef.close();
  }
}