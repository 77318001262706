// import { Component } from '@angular/core';
import { animate, keyframes, style, transition, trigger } from '@angular/animations';
import { Component, Output, EventEmitter, OnInit, HostListener, Input } from '@angular/core';
// import { navbarData } from './data';
import { LogoutComponent } from '../shared/logout/logout.component';
import { AuthenticationManagementService } from '../authentication-management.service';
import { SessionService } from '../shared/session.service';
import { PayWizardService } from '../modules/payments/pay-wizard.service';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';

interface SideNavToggle {
  screenWidth: number;
  collapsed: boolean;
}
@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css'],

  animations: [
    trigger('fadeInOut', [
      transition(':enter', [
        style({opacity: 0}),
        animate('350ms',
          style({opacity: 1})
        )
      ]),
      transition(':leave', [
        style({opacity: 1}),
        animate('350ms',
          style({opacity: 0})
        )
      ])
    ]),
    trigger('rotate', [
      transition(':enter', [
        animate('1000ms', 
          keyframes([
            style({transform: 'rotate(0deg)', offset: '0'}),
            style({transform: 'rotate(2turn)', offset: '1'})
          ])
        )
      ])
    ])
  ]
})
export class NavbarComponent {
  @Input() collapsed = false;
  @Input() screenWidth = 0;

  getBodyClass(): string {
    let styleClass = '';
    if(this.collapsed && this.screenWidth > 768) {
      styleClass = 'body-trimmed';
    } else if(this.collapsed && this.screenWidth <= 768 && this.screenWidth > 0) {
      styleClass = 'body-md-screen'
    }
    return styleClass;
  }
  
  @Output() onToggleSideNav: EventEmitter<SideNavToggle> = new EventEmitter();
  // collapsed = false;
  // screenWidth = 0;
  // navData = navbarData;

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.screenWidth = window.innerWidth;
    if(this.screenWidth <= 768 ) {
      this.collapsed = false;
      this.onToggleSideNav.emit({collapsed: this.collapsed, screenWidth: this.screenWidth});
    }
  }
 constructor (private router:Router,private dialog:MatDialog,private authManagementService:AuthenticationManagementService, private sessionService:SessionService, private payWizardService: PayWizardService){

 }
  ngOnInit(): void {
      this.screenWidth = window.innerWidth;
  }

  toggleCollapse(): void {
    this.collapsed = !this.collapsed;
    this.onToggleSideNav.emit({collapsed: this.collapsed, screenWidth: this.screenWidth});
  }
  get profileComplete(): boolean {
    return this.sessionService.getUserProfileComplete();
}
  closeSidenav(): void {
    this.collapsed = false;
    this.onToggleSideNav.emit({collapsed: this.collapsed, screenWidth: this.screenWidth});
  }
  onClickLogout() {

    const logoutRef = this.dialog.open(LogoutComponent);

    logoutRef.afterClosed().subscribe((result:any) => {
        if (result === true) {
            this.authManagementService.loggedIn = false;
            this.sessionService.clearStorage();
            this.payWizardService.clearStorage();
            this.authManagementService.signOut();
            this.router.navigateByUrl('/landing');
        }
        
    })
}
}
