import { Injectable, Inject } from '@angular/core';
import { Observable, catchError, forkJoin, from } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SessionService } from 'src/app/shared/session.service';
import { BASE_URL } from 'src/app/app.provider';
import { ClientProfile } from './client-profile-models';

@Injectable({
  providedIn: 'root',
})
export class ClientProfileService {
  private inviteUserUrl = this.baseUrl + '/profile/invite-client?emailAddress=';
  private getCompanyUsersUrl =
    this.baseUrl + '/profile/all-clients?emailAddress=';
  private getRequestedUsersUrl =
    this.baseUrl + '/profile/accept-invite?emailAddress=';
  private approveClientRequestsUrl = this.baseUrl + '/profile/client-approval';
  private clientProfileUrl = this.baseUrl + '/profile/client?emailAddress=';
  private updateClientUrl = this.baseUrl + '/profile/client?emailAddress=';
  private acceptInvite = this.baseUrl + '/profile/accept-invite';
  private createClientUrl = this.baseUrl + '/profile/client';
  private deleteclientFromCompanyURL = this.baseUrl + '/profile/delete';

  constructor(
    @Inject(BASE_URL) protected baseUrl: string,
    private http: HttpClient,
    private sessionClass: SessionService
  ) {}

  createClientProfile(clientProfileForm: any){
    return this.http.post(this.createClientUrl, clientProfileForm)
  }
  
  updateClientProfile(clientProfileForm: any){
    return this.http.put(this.updateClientUrl + this.sessionClass.getUsername(), clientProfileForm)
  }
  updateClientProfileOnboarding(clientProfileForm: any){
    return this.http.put(this.updateClientUrl + clientProfileForm.emailAddress, clientProfileForm)
  }

  loadProfile(email?: string): Observable<ClientProfile> {
    return this.http.get<ClientProfile>(this.clientProfileUrl + this.sessionClass.getUsername())
  }

  getCompanyUsers(): Observable<any> {
    return this.http.get(
      this.getCompanyUsersUrl + this.sessionClass.getUsername()
    );
  }

  getRequestedUsers(): Observable<any> {
    return this.http.get(
      this.getRequestedUsersUrl + this.sessionClass.getUsername()
    );
  }

  inviteNewUser(email: string, name: string, role: string): Observable<any> {
    var postData = {
        userName: name,
        userEmail: email,
        selectedRole: role,
    };
    return this.http.post(
        this.inviteUserUrl +email,
        JSON.stringify(postData),
        {
            headers: {
                'Content-Type': 'application/json'
            }
        }
    );
}

  approveClientRequest(
    companyName: string,
    companyRegistrationNumber: string
  ): Observable<any> {
    var postData = {
      userEmail: this.sessionClass.getUsername(),
      companyName: companyName,
      companyRegistrationNumber: companyRegistrationNumber,
    };
    return this.http.post(
      this.approveClientRequestsUrl,
      JSON.stringify(postData)
    );
  }

  deleteclientFromCompany(emailAddress: string) {
    return this.http.delete(this.deleteclientFromCompanyURL, {
      params: {
        emailAddress: this.sessionClass.getUsername(),
        clientEmail: emailAddress,
      },
    });
  }

  acceptInvitation(): Observable<any> {
    const payload = {
        emailAddress: this.sessionClass.getUsername(),
    };
    return this.http.post(
        this.acceptInvite,
        JSON.stringify(payload),
        {
            headers: {
                'Content-Type': 'application/json'
            }
        }
    );
}


  deactivateAccount(profile: any, emailAddress: string): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const deactivateClientUrl = this.baseUrl + '/profile/deactivate-account';

    const payload = { emailAddress: emailAddress };

    let options = { params: { emailAddress: emailAddress } };

    const deleteRequest = from(
      this.http.put(
        this.baseUrl + '/registration/user',
        JSON.stringify(profile),
        options
      )
    );

    const putRequest = from(
      this.http
        .put(deactivateClientUrl, JSON.stringify(payload), {
          headers,
        })
        .toPromise()
    );

    return forkJoin([deleteRequest, putRequest]).pipe(
      catchError((err) => {
        console.log('Error: ', err);
        throw err;
      })
    );
  }
}




