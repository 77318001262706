import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { SessionService } from 'src/app/shared/session.service';
import { HttpClient } from '@angular/common/http';
import { CompanyJobProfile, PaginatedJobOffers, SavedJobDTO } from 'src/app/shared/company-profile-models';
import { environment } from 'src/environments/environment';
import { AuthenticationManagementService } from 'src/app/authentication-management.service';
import { JobProfileComponent } from 'src/app/shared/job-profile/job-profile.component'; // Import the shared component
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BASE_URL } from 'src/app/app.provider';
import { ContractorProfileRepositoryService } from '../contractor-profile-repository.service';
import { UserProfile } from 'src/app/shared/user-profile-models';
import { Profile } from '../user-profile-models';
import { Pipe, PipeTransform } from '@angular/core';
import { SavedJobService } from 'src/app/SavedJobService.service';
import { MatDialog } from '@angular/material/dialog';
import { WizardService } from '../../onboarding-wizard/wizard.service';
import { ContractorOnboardingComponent } from 'src/app/contractor-onboarding/contractor-onboarding.component';


@Component({
  selector: 'app-contractor-dashboard',
  templateUrl: './contractor-dashboard.component.html',
  styleUrls: ['./contractor-dashboard.component.css']
})

export class ContractorDashboardComponent implements OnInit {

  defaultCompanyLogoUrl = '/assets/images/BLUE-Logo-Icon-Large-min.png';

  private allJobProfilesUrl = this.baseUrl + '/profile/jobs';
  private matchedJobProfilesUrl = this.baseUrl + '/profile/match-jobs?emailAddress=';

  allJobProfiles: CompanyJobProfile[] = [];
  matchedJobProfiles: CompanyJobProfile[] =[];
  totalElements!: number;
  pageSize!: number;
  numberOfElements!: number;
  totalPages!: number;
  firstPage!: boolean;
  lastPage!: boolean;
  empty!: boolean;
  pageNumber!: number;
  userProfile: any = null;
  savedJobs: SavedJobDTO[] = [];


  @ViewChild('jobProfilesContainer') jobProfilesContainer: ElementRef;
  @ViewChild('jobProfilesContainerMatched') jobProfilesContainerMatched: ElementRef;

  @ViewChild('titleSearchInput', { static: false }) titleSearchInput!: ElementRef;
  @ViewChild('locationSearchInput', { static: false }) locationSearchInput!: ElementRef;

  isShown = false;
  loading = false;
  username = '';
  userEmail = '';
  errorMessage: string;

  constructor(
    @Inject(BASE_URL) protected baseUrl: string,
    private http: HttpClient,
    private myAuth: AuthenticationManagementService,
    private sessionClass: SessionService,
    private router: Router,
    private contractorProfileRepository: ContractorProfileRepositoryService,
    private savedJobService: SavedJobService,
    private dialog: MatDialog,
    private wizardService: WizardService,

  ) { }


  ngOnInit() {
    this.fetchData();
    this.username = this.getUsername();
    this.getSavedJobProfiles();
    this.loadUserProfile();
    this.fetchUserSpecificJobs();  // Fixed method name
    this.getSavedJobs();
    // Check the flag and open ContractorOnboardingComponent if needed
    if (this.wizardService.isContractorFirstTimeRegistration) {
      this.openContractorOnboardingPopup();
    }
    this.loadSavedJobs();
  }

  openContractorOnboardingPopup() {
    const dialogRef = this.dialog.open(ContractorOnboardingComponent, {
      width: '500px',  // Set the width and other configurations as needed
    });

    dialogRef.afterClosed().subscribe(result => {
      // Handle the result if needed
    });
  }

  fetchData(page: number = 0, size: number = 10) {
    this.loading = true;

    this.http.get<PaginatedJobOffers>(this.allJobProfilesUrl, {
      params: {
        page: page.toString(),
        size: size.toString(),
      }
    }).subscribe(response => {
      this.allJobProfiles = response.content;
      this.totalElements = response.totalElements;
      this.totalPages = response.totalPages;
      this.pageSize = response.size;
      this.pageNumber = response.number;
      this.firstPage = response.first;
      this.lastPage = response.last;
      this.empty = response.empty;

      this.loading = false;
      this.isShown = !this.isShown;
    }, error => {
      console.error('Error fetching all job profiles:', error);  // Improved error logging
      this.loading = false;
      this.isShown = !this.isShown;
    });
  }

  fetchUserSpecificJobs() {  // Updated method name
    this.http.get<CompanyJobProfile[]>(this.matchedJobProfilesUrl + this.sessionClass.getUsername()).subscribe(response => {
      this.matchedJobProfiles = response;
      
      this.isShown = !this.isShown;
    }, error => {
      console.error('Error fetching user-specific jobs:', error);  // Improved error logging
      this.isShown = !this.isShown;
    });
  }



  loadUserProfile(): void {
    const email = this.getUsername();
    this.contractorProfileRepository.loadProfile(email).subscribe(
      response => {
        this.userProfile = response;  // Fix the typo here
      },
      error => {
        console.error(error);
      }
    );
  }



  isJobSavedForJob(job: CompanyJobProfile): boolean {
    return this.matchedJobProfiles.some(savedJob => savedJob.identifier === job.identifier);
  }

  toggleJobSavedStatus(jobProfile: any) {
    const userId = this.getUsername();
    const jobProfileId = jobProfile.identifier;
    const apiEndpoint = `${environment.baseUrl}/profile/saved-job-profile`;

    if (jobProfile.isJobSaved) {
      const deleteApiEndpoint = `${environment.baseUrl}/profile/saved-job-profile/${userId}/${jobProfileId}`;

      this.http.delete(deleteApiEndpoint).subscribe(
        () => {
          jobProfile.isJobSaved = false;
        },
        (error) => {
          console.error('Error removing job from saved jobs:', error);
        }
      );
    } else {
      const savedJobData = {
        userId: userId,
        jobProfileId: jobProfileId
      };

      this.http.post(apiEndpoint, savedJobData).subscribe(() => {
        jobProfile.isJobSaved = true;
      }, (error) => {
        console.error('Error saving job to saved jobs:', error);
      });
    }
  }

  getSavedJobProfiles() {
    const userId = this.getUsername();
    const savedJobProfilesUrl = `${environment.baseUrl}/profile/saved-job-profile?userId=${userId}`;

    this.http.get<CompanyJobProfile[]>(savedJobProfilesUrl).subscribe(
      (response) => {
        this.matchedJobProfiles = response;
        this.populateIsJobSavedProperty();
      },
      (error) => {
        console.error('Error fetching saved job profiles:', error);
      }
    );
  }

  populateIsJobSavedProperty() {
    const savedJobIds = this.matchedJobProfiles.map((jobProfile) => jobProfile.identifier);

    this.allJobProfiles.forEach((jobProf) => {
      jobProf.isJobSaved = savedJobIds.includes(jobProf.identifier);
    });
  }


  private getUsername(): string {
    return this.sessionClass.getUsername();
  }


  viewProfile(jobProfile: CompanyJobProfile) {
    // Extract the job ID from the jobProf object (assuming there's a property like 'id')
    const jobId = jobProfile.identifier;
    const savedJobId =  jobProfile.identifier;

    // Use the router to navigate to the 'job-spec' route with the job ID as a parameter
    this.router.navigate(['/main/user-profile/job-spec', jobId]);
    this.router.navigate(['/main/user-profile/job-spec', savedJobId]);
  }



  viewAllJobs() {
    // Use the router to navigate to the 'view-jobs' route
    this.router.navigate(['/main/user-profile/view-jobs']);
  }

  searchJobs() {
    const titleSearchText = this.titleSearchInput.nativeElement.value.toLowerCase();
    const locationSearchText = this.locationSearchInput.nativeElement.value.toLowerCase();

    this.allJobProfiles = this.allJobProfiles.filter(jobProf => {
      const titleMatch = jobProf.title.toLowerCase().includes(titleSearchText);
      const locationMatch = jobProf.jobLocation.toLowerCase().includes(locationSearchText);
      return titleMatch && locationMatch;
    });
  }

  resetFilters() {
    this.titleSearchInput.nativeElement.value = '';
    this.locationSearchInput.nativeElement.value = '';
    this.fetchData();
  }


  // New Dashboard changes//

  Profile(){
    this.router.navigateByUrl("main/user-profile/profileview");
  }

  // Pagination for reconmended jobs.

  currentScrollIndex = 0;

  get visibleJobProfiles() {
    const startIndex = Math.max(0, this.currentScrollIndex);
    return this.allJobProfiles.slice(startIndex, startIndex + 3);
  }

  scrollToLeft() {
    this.currentScrollIndex = Math.max(0, this.currentScrollIndex - 3);
    this.scrollContainer();
  }

  scrollToRight() {
    const maxIndex = this.allJobProfiles.length - 1;
    this.currentScrollIndex = Math.min(maxIndex, this.currentScrollIndex + 3);
    this.scrollContainer();
  }

  private scrollContainer() {
    const container = this.jobProfilesContainer.nativeElement;
    const scrollAmount = this.currentScrollIndex * container.clientWidth;
    container.scrollLeft = scrollAmount;
  }


  // Pagination for reconmended jobs.

  currentScrollIndexMatched = 0;

  get visibleMatched() {
    const startIndex = Math.max(0, this.currentScrollIndexMatched);
    return this.matchedJobProfiles.slice(startIndex, startIndex + 3);
  }


  private scrollContainerMatched() {
    const container = this.jobProfilesContainerMatched.nativeElement;
    const scrollAmount = this.currentScrollIndexMatched * container.clientWidth;
    container.scrollLeft = scrollAmount;
  }

  scrollToLeftMatched() {
    this.currentScrollIndexMatched = Math.max(0, this.currentScrollIndexMatched - 3);
    this.scrollContainerMatched();
  }


  scrollToRightMatched() {
    const maxIndex = this.matchedJobProfiles.length - 1;
    this.currentScrollIndexMatched = Math.min(maxIndex, this.currentScrollIndexMatched + 3);
    this.scrollContainerMatched();
  }

  get hasMatchedJobs(): boolean{
    return this.matchedJobProfiles && this.matchedJobProfiles.length > 0;
  }

  getSavedJobs() {
    this.savedJobService.getSavedJobs(this.username).subscribe(
      (response: SavedJobDTO[]) => {
        this.savedJobs = response;
        
      },
      (error) => {
        console.error('Error fetching saved jobs:', error);
        // Optionally, load from local storage if the backend call fails
        this.errorMessage = 'Failed to fetch saved jobs. Please try again later.';
      }
    );
  }

  loadSavedJobs(): void {
    const jobsFromLocalStorage = localStorage.getItem('savedJobs');
    if (jobsFromLocalStorage) {
      const parsedJobs = JSON.parse(jobsFromLocalStorage);
      // Validate each job's structure or minimum required data
      this.savedJobs = parsedJobs.filter((job: any) => job.title && job.description && job.company);
      
    } else {
      // Handle the case where there are no saved jobs gracefully
      this.savedJobs = [];
    }
  }















}
