import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AppNotification } from '../notification.service'; // Import the Notification interface

@Component({
  selector: 'app-notification-details',
  templateUrl: './notification-details.component.html',
  styleUrls: ['./notification-details.component.css', './dialog-styles.css'],
})
export class NotificationDetailsComponent {
  constructor(
    public dialogRef: MatDialogRef<NotificationDetailsComponent>,
    @Inject(MAT_DIALOG_DATA) public notification: AppNotification
  ) {}

  closeDialog(): void {
    this.dialogRef.close();
  }
}