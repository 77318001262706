import { Injectable } from '@angular/core';
import * as AWS from 'aws-sdk';
import { SendEmailRequest } from 'aws-sdk/clients/ses';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EmailService {

  private ses: AWS.SES;

  constructor() { 

    AWS.config.update({
      accessKeyId: environment.AWS_ACCESS_KEY_ID,
      secretAccessKey: environment.AWS_SECRET_ACCESS_KEY,
      region: environment.AWS_REGION
    });

    
   this.ses = new AWS.SES({apiVersion: '2010-12-01'});

  }

  async sendEmail(to: string, subject: string, body: string) {
    const params: SendEmailRequest = {
      Destination: {
        ToAddresses: [to]
      },
      Message: {
        Subject: {
          Data: subject
        },
        Body: {

          Html: {
            Charset: "UTF-8",
            Data: body,
          },
          Text: {
            Charset: "UTF-8",
            Data: body,
          }
        }
      },
      Source: 'support@blueapp.blue' // Specify the sender email address here
    };

    try {
      const result = await this.ses.sendEmail(params).promise();
      
    } catch (error) {
      console.error('Error sending email:', error);
      // Log the error to a remote logging service or database here.
    }
  }


  async sendEmailClient(to: string, subject: string, body: string) {
    const params: SendEmailRequest = {
      Destination: {
        ToAddresses: [to]
      },
      Message: {
        Subject: {
          Data: subject
        },
        Body: {
          Html: {
            Charset: "UTF-8",
            Data: body,
          },
          Text: {
            Charset: "UTF-8",
            Data: body,
          }
        }
      },
      Source: 'support@blueapp.blue' // Specify the sender email address here
    };

    try {
      const result = await this.ses.sendEmail(params).promise();
      
    } catch (error) {
      console.error('Error sending email:', error);
      // Log the error to a remote logging service or database here.
    }
  }

  async applyContractor(to: string, subject: string, body: string) {
    const params: SendEmailRequest = {
      Destination: {
        ToAddresses: [to]
      },
      Message: {
        Subject: {
          Data: subject
        },
        Body: {
          Html: {
            Charset: "UTF-8",
            Data: body,
          },
          Text: {
            Charset: "UTF-8",
            Data: body,
          }
        }
      },
      Source: 'support@blueapp.blue' // Specify the sender email address here
    };

    try {
      const result = await this.ses.sendEmail(params).promise();
      
    } catch (error) {
      console.error('Error sending email:', error);
      // Log the error to a remote logging service or database here.
    }
  }


  async RequestInterview(to: string, subject: string, body: string) {
    const params: SendEmailRequest = {
      Destination: {
        ToAddresses: [to]
      },
      Message: {
        Subject: {
          Data: subject
        },
        Body: {
          Html: {
            Charset: "UTF-8",
            Data: body,
          },
          Text: {
            Charset: "UTF-8",
            Data: body,
          }
        }
      },
      Source: 'support@blueapp.blue' // Specify the sender email address here
    };

    try {
      const result = await this.ses.sendEmail(params).promise();
      
    } catch (error) {
      console.error('Error sending email:', error);
      // Log the error to a remote logging service or database here.
    }
  }



  async PaymentConfirmation(to: string, subject: string, body: string) {
    const params: SendEmailRequest = {
      Destination: {
        ToAddresses: [to]
      },
      Message: {
        Subject: {
          Data: subject
        },
        Body: {
          Html: {
            Charset: "UTF-8",
            Data: body,
          },
          Text: {
            Charset: "UTF-8",
            Data: body,
          }
        }
      },
      Source: 'support@blueapp.blue' // Specify the sender email address here
    };

    try {
      const result = await this.ses.sendEmail(params).promise();
      
    } catch (error) {
      console.error('Error sending email:', error);
      // Log the error to a remote logging service or database here.
    }
  }
}



  


