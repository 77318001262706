<mat-progress-bar *ngIf = "loading"  mode="indeterminate"></mat-progress-bar>

<div class="custom-card p-3 my-3">
    <div class="stepOptions mb-2">
        <button class="rounded-pill m-1" (click)="setInfoStep(1)" mat-raised-button>
          All Jobs
        </button>
        <!-- show work history -->
        <button class="rounded-pill m-1" (click)="setInfoStep(2)" mat-raised-button>
            Matched Jobs
        </button>

    </div>
    
    <mat-divider class="my-2"></mat-divider>

    <div *ngIf="jobStep === 1">
        <!-- all jobs -->
        <div class="row jobProfiles mt-2">
            <div>

            
            <div class="col-sm-12 col-md-6 col-lg-4 mx-left my-2" *ngFor = "let jobProf of allJobProfiles">
                <div class="custom-card my-3">
                    <div class="custom-card-header p-3 text-white">
                        <h5 class="mb-1"><strong>{{jobProf.title}}</strong></h5>
                        <p class="mb-1">{{jobProf.company.name}}</p>
                    </div>
                    <div class="custom-card-body p-3">
                        <p class=""><strong>Based in:</strong> {{jobProf.jobLocation}}</p>
                        <p class=""><strong>Rate per hour:</strong> {{jobProf.ratePerHour | currency:'R'}}</p>
                        <p class=""><strong>Closing date:</strong> {{jobProf.closingDate | date}}</p>
                        <p class=""><strong>Contract duration:</strong> {{jobProf.contractDurationInMonths}} Months</p>
                    </div>
                    <mat-divider class="mb-3"></mat-divider>
                    <div class="custom-card-actions px-3 pb-3">
                        <button
                            (click) = "viewProfile(jobProf)" 
                            mat-button>
                                MORE DETAILS
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
        <div class="row mt-2">
            <div class="col-sm-12">
                <mat-paginator [length]="totalElements" [pageSize]="pageSize" [pageIndex]="pageNumber" (page)="pageChanged($event)"></mat-paginator>
            </div>
        </div>
    </div>

    <div *ngIf="jobStep === 2">
        <!-- matched jobprofiles -->
        <div class="row jobProfiles" *ngIf="hasMatchedJobs">
            <div class="col-md-6 col-lg-4 mx-left my-5" *ngFor = "let jobProf of matchedJobProfiles">
                <div>

               
                <div class="custom-card my-3">
                    <div class="custom-card-header p-3 text-white">
                        <h5 class="mb-1"><strong>{{jobProf.title}}</strong></h5>
                        <!-- <p class="mb-1"><strong>{{jobProf.employee_salary}}</strong></p> -->
                    </div>
                    <div class="custom-card-body p-3">
                        <p><strong>Contract Duration:</strong> {{jobProf.contractDurationInMonths}} Months</p>
                        <p><strong>Job Description:</strong>{{jobProf.description}}</p>
                    </div>
                    <mat-divider class="mb-3"></mat-divider>
                    <div class="custom-card-actions px-3 pb-3">
                        <button
                            (click) = "viewProfile(jobProf)" 
                            mat-button>
                                VIEW DETAILS
                        </button>
                    </div>
                </div> 
            </div>
            </div>
        </div>
        <div class="row" *ngIf="!hasMatchedJobs">
            <mat-card class="col-md-12 col-lg-12 mt-3">
                <mat-card-content>
                    <p class="text-center w-100">No jobs were found that could be matched to your current profile.</p>
                </mat-card-content>
            </mat-card>
        </div>
    </div>
</div>
