import { NgModule, isDevMode } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FeeStructureComponent } from './fee-structure/fee-structure.component';
import { HelpComponent } from './help-support/help/help.component';
import { ContractorsComponent } from './home/contractors/contractors.component';

import { NextStepsComponent } from './home/next-steps/next-steps.component';
import { NotificationsComponent } from './home/notifications/notifications.component';
import { PaymentsComponent } from './home/payments/payments.component';
import { SignContractorTermsComponent } from './home/sign-contractor-terms/sign-contractor-terms.component';
import { ViewCandidateComponent } from './home/view-candidate/view-candidate.component';
import { JobProfileComponent } from './job-profile/job-profile.component';
import { LandingComponent } from './landing/landing.component';
import { MainComponent } from './main/main.component';

import { TermsComponent } from './terms/terms.component';
import { CustomMaterialModule } from './material/material.module';
import { SharedModule } from './shared/shared.module';
import { ServiceWorkerModule } from '@angular/service-worker';
import { HTTP_INTERCEPTORS, HttpClientModule, HttpClient } from '@angular/common/http';
import { AppAuthHttpInterceptor } from './app-auth-http.interceptor';
import { BASE_URL_PROVIDER } from './app.provider';
import { AuthGuard } from './auth.guard';
import { AvailableContractorsComponent } from './home/contractors/available-contractors/available-contractors.component';
import { ViewContractorDetailsComponent } from './home/contractors/view-contractor-details/view-contractor-details.component';
import { ContractorJobProfilesViewComponent } from './home/job-profiles/contractor-job-profiles-view/contractor-job-profiles-view.component';
import { JobProfilesAddComponent } from './home/job-profiles/job-profiles-add/job-profiles-add.component';
import { JobProfilesMatchComponent } from './home/job-profiles/job-profiles-match/job-profiles-match.component';
import { JobProfilesRootComponent } from './home/job-profiles/job-profiles-root/job-profiles-root.component';
import { JobProfilesViewComponent } from './home/job-profiles/job-profiles-view/job-profiles-view.component';
import { ContractorCardComponent } from './home/job-profiles/contractor-card/contractor-card.component';
import { DashboardComponent } from './home/dashboard/dashboard.component';
import { LogoutDialogComponent } from './home/dialogs/logout-dialog/logout-dialog.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { JobprofileviewComponent } from './home/BlueDesk/jobprofileview/jobprofileview.component';
import { NavbarComponent } from './navbar/navbar.component';
import { JobDetailsComponent } from './home/job-profiles/job-details/job-details.component';
import { EmailService } from './modules/onboarding-wizard/email.service';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { NotificationDropdownComponent } from './notification-dropdown/notification-dropdown.component';
import { NotificationDetailsComponent } from './notification-details/notification-details.component';
import { UserRolesComponent } from './modules/user-roles/user-roles.component';
import { RegisterComponent } from './Landingupdates/register/register.component';
import { LoginComponent } from './Landingupdates/login/login.component';
import { LoginModalComponent } from './app/shared/login-modal/login-modal.component';
// import { UpdatedregisterComponent } from './updatedregister/updatedregister.component';
import { MatBadgeModule } from '@angular/material/badge';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ContractorOnboardingComponent } from './contractor-onboarding/contractor-onboarding.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { UpdatePasswordComponent } from './update-password/update-password.component';
import { JobDescriptionDialogComponent } from './home/job-profiles/job-description-dialog/job-description-dialog.component';




@NgModule({
  declarations: [
    AppComponent,
    LandingComponent,
    MainComponent,
    JobProfileComponent,
    HelpComponent,
    DashboardComponent,
    ContractorsComponent,
    PaymentsComponent,
    NotificationsComponent,
    AvailableContractorsComponent,
    JobProfilesAddComponent,
    JobProfilesRootComponent,
    JobProfilesViewComponent,
    JobDetailsComponent,
    LogoutDialogComponent,
    ViewContractorDetailsComponent,
    JobProfilesMatchComponent,
    ViewCandidateComponent,
    TermsComponent,
    ContractorJobProfilesViewComponent,
    FeeStructureComponent,
    NextStepsComponent,
    SignContractorTermsComponent,
    ContractorCardComponent,
    JobprofileviewComponent,
    NavbarComponent,
    NotificationDropdownComponent,
    NotificationDetailsComponent,
    UserRolesComponent,
    RegisterComponent,
    LoginComponent,
    LoginModalComponent,
    ContractorOnboardingComponent,
    UpdatePasswordComponent,
    JobDescriptionDialogComponent,
    // UpdatedregisterComponent,
  ],
  imports: [
    MatProgressSpinnerModule,
    MatButtonModule,
    MatMenuModule,
    MatIconModule,
    MatBadgeModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    FormsModule,
    CustomMaterialModule,
    SharedModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
    HttpClientModule,
    NgxPaginationModule,
  ],
  providers:
    [BASE_URL_PROVIDER,
    { provide: HTTP_INTERCEPTORS, useClass: AppAuthHttpInterceptor, multi: true },
    AuthGuard,
    EmailService
  ],
  bootstrap: [AppComponent],

})
export class AppModule {private emailService!: EmailService; }
