
<div class="container-fluid">
  <div class="max-w-4xl mx-auto p-6 bg-white shadow rounded-lg m-5" *ngIf="privacy">
    <div class="flex justify-between items-center mb-6 ">
      <h3 class="text-2xl font-bold m-1">Blue Terms and Conditions</h3>
      <button  id="headbutton" mat-flat-button
      (click)="toggleSection()"
      [ngStyle]="getButtonStyle(activeSection)"
      (click)="showterms()"
      [ngClass]="{'active-button': activeSection === 'terms', 'inactive-button': activeSection !== 'terms' }">
  {{ activeSection === 'terms' ? 'Terms and Conditions' : 'Privacy Policy' }}
</button>
    </div>
    <div>
      
    </div>
    <div class="border-b-2 border-gray-200 mb-4">
      <nav class="flex space-x-4" aria-label="Tabs">
        <div dir="ltr" data-orientation="horizontal">
          <div>
            <div class="whitespace-nowrap py-4 px-1 border-b-2 border-transparent font-medium text-gray-500 hover:text-gray-700 hover:border-gray-300">
              Effective Date: 04 April 2023 
            </div>
          </div>
        </div>
      </nav>
    </div>
    <article class="prose lg:prose-xl">
     
      <p class="mt-1 mx-5">
        <strong>Introduction</strong><br><br>
         We (BLUE), are committed to protecting the privacy of our users. This privacy policy explains how we collect, use, disclose, and protect personal information collected through our website, software applications, and services (collectively, the "Services").<br>
        <br>
        <strong>1. Collection of Information</strong><br><br>
        1.1 We may collect the following information when you use our Services:
        Personal information, such as your name, email address, phone number, or other contact information, that you voluntarily provide to us when you register for an account or use certain features of our Services;
        Information that you provide to us when you communicate with us, such as through email or customer support inquiries;
        Information about your device and usage of our Services, such as your IP address, browser type, operating system, and access times;
        Cookies and similar technologies that allow us to recognize you and provide you with a personalized experience when you use our Services. <br><br>
        1.2 We may also collect personal information from third-party sources, such as social media platforms, public databases, or marketing partners. We will only collect this information when we have obtained your consent or when the law permits us to do so.<br>
        <br>
        <strong>2. Use of Information</strong><br> <br>
        2.1 We may use the information we collect to:<br>
        <svg xmlns="http://www.w3.org/2000/svg" width="7" height="7" fill="currentColor" class="bi bi-circle-fill" viewBox="0 0 16 16">
          <circle cx="8" cy="8" r="8"/>
        </svg>         Provide and improve our Services, including customizing the content and layout of our Services and enabling certain features and functionality.<br>
        <svg xmlns="http://www.w3.org/2000/svg" width="7" height="7" fill="currentColor" class="bi bi-circle-fill" viewBox="0 0 16 16">
          <circle cx="8" cy="8" r="8"/>
        </svg> Respond to your inquiries and customer support requests.<br>
        <svg xmlns="http://www.w3.org/2000/svg" width="7" height="7" fill="currentColor" class="bi bi-circle-fill" viewBox="0 0 16 16">
          <circle cx="8" cy="8" r="8"/>
        </svg> Communicate with you about our Services, including sending promotional and marketing communications that we believe may be of interest to you.<br>
        <svg xmlns="http://www.w3.org/2000/svg" width="7" height="7" fill="currentColor" class="bi bi-circle-fill" viewBox="0 0 16 16">
          <circle cx="8" cy="8" r="8"/>
        </svg> Analyze and understand how our Services are used, including monitoring user traffic and usage patterns.<br>
        <svg xmlns="http://www.w3.org/2000/svg" width="7" height="7" fill="currentColor" class="bi bi-circle-fill" viewBox="0 0 16 16">
          <circle cx="8" cy="8" r="8"/>
        </svg> Prevent, detect, and investigate fraud and other prohibited or illegal activities.<br>
        <svg xmlns="http://www.w3.org/2000/svg" width="7" height="7" fill="currentColor" class="bi bi-circle-fill" viewBox="0 0 16 16">
          <circle cx="8" cy="8" r="8"/>
        </svg> Enforce our Terms of Use and other agreements.<br><br>
        2.2 We may also use non-personal information, such as aggregated data or de-identified information, for any purpose, except where prohibited by applicable law.<br><br>
        <br>
        <strong>3. Disclosure of Information</strong><br><br>
        3.1 We may disclose your personal information to third-party service providers and partners who assist us in providing our Services, such as hosting providers, payment processors, and marketing partners. These third-party service providers are required to maintain the confidentiality and security of your personal information and are prohibited from using your information for any purpose other than providing the services we have requested.<br><br>
        3.2 We may also disclose your personal information if we believe that such disclosure is necessary to:
        Comply with applicable laws, regulations, or legal process;
        Enforce our Terms of Use or other agreements;
        Protect our rights, property, or safety, or the rights, property, or safety of others.<br>
        
        <br><strong>4. Security</strong><br><br>
         We take reasonable measures to protect the confidentiality and security of your personal information. However, no data transmission over the Internet or storage system can be guaranteed to be 100% secure. As a result, we cannot guarantee the security of any information you transmit to us, and you do so at your own risk.<br><br>
        
        <strong>5. Third-Party Links</strong><br><br>
         Our Services may contain links to third-party websites or services that are not owned or controlled by us. We are not responsible for the privacy practices or content of these third-party websites or services. We encourage you to review the privacy policies of these third-party websites or services before providing any personal information.<br><br>
        <strong>6. Children's Privacy</strong><br><br>
         Our Services are not directed to children under the age of 18.
  
      </p>
    </article>
  </div>
  
  
  
  <div class="max-w-4xl mx-auto p-6 bg-white shadow rounded-lg mt-5 "*ngIf="terms">
    <div>
      <div class="flex justify-between items-center mb-6">
        <h3 class="text-2xl font-bold m-1"> Blue Privacy Policy</h3>
        <button mat-flat-button id="headbutton"
          (click)="toggleSection()"
          [ngStyle]="getButtonStyle(activeSection)"
          [ngStyle]="getButtonStyle('terms')"
          (click)="showprivacy()"
          [ngClass]="{'active-button': activeSection === 'terms', 'inactive-button': activeSection !== 'terms' }">
      {{ activeSection === 'terms' ? 'Terms and Conditions' : 'Privacy Policy' }}
  </button>
      </div>
      <div class="border-b-2 border-gray-200 mb-4">
        <nav class="flex space-x-4" aria-label="Tabs">
          <div dir="ltr" data-orientation="horizontal">
            <div>
              <div class="whitespace-nowrap py-4 px-1 border-b-2 border-transparent font-medium text-gray-500 hover:text-gray-700 hover:border-gray-300">
                Effective Date: 04 April 2023
              </div>
            </div>
          </div>
        </nav>
      </div>
      <article class="prose lg:prose-xl">
        
         
        <p class="mt-1 mx-5">
          <strong>Introduction</strong><br><br>
          To use our Services, you must be at least 18 years old and have the legal capacity to enter into a contract. By using our Services, you represent and warrant that you meet these eligibility requirements.
        <br><br>
  
      
                  <strong>1. Eligibility</strong><br><br>
                  To use our Services, you must be at least 18 years old and have the legal capacity to enter into a contract. By using our Services, you represent and warrant that you meet these eligibility requirements.<br>
                  <br>
                  <strong>2. License and Use</strong><br>
                  We grant you a limited, non-exclusive, non-transferable, revocable license to use our Services for your personal and non-commercial use. You may not use our Services for any illegal or unauthorized purpose, or in a way that harms us, our affiliates, or any other person or entity.<br>
                  <br>
                  <strong>3. User Content</strong><br>
                  You are solely responsible for any content you submit or upload to our Services, including text, images, videos, and other materials (collectively, "User Content"). You represent and warrant that you own or have the necessary licenses, rights, consents, and permissions to use and authorize us to use your User Content as necessary to provide our Services and as otherwise permitted by these terms.<br>
                  <br>
                  <strong>4. Intellectual Property</strong><br>
                  We own or have the right to use all content and materials on our Services, including but not limited to text, images, graphics, logos, and software (collectively, "Our Content"). You may not use, reproduce, modify, distribute, or display Our Content without our prior written consent.<br>
                  <br>
                  <strong>5. Third-Party Links and Content</strong><br>
                  Our Services may contain links to third-party websites or services, as well as content provided by third parties. We have no control over, and assume no responsibility for, the content, privacy policies, or practices of any third-party websites or services. You acknowledge and agree that we shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with the use of or reliance on any such content, goods, or services available on or through any such third-party websites or services. <br>
                  <br>
                  <strong>6. Disclaimer of Warranties</strong><br>
                  Our Services are provided "as is" and without warranties of any kind, whether express or implied. We do not warrant that our Services will be uninterrupted, error-free, or free from viruses or other harmful components. We disclaim all warranties, express or implied, including but not limited to warranties of merchantability, fitness for a particular purpose, and non-infringement.<br>
                  <br>
                  <strong>7. Limitation of Liability</strong><br>
                  In no event shall we be liable for any direct, indirect, incidental, special, consequential, or punitive damages arising out of or in connection with your use of our Services, whether based on contract, tort, strict liability, or any other legal theory, even if we have been advised of the possibility of such damages.<br>
                  <br>
                  <strong>8. Indemnification</strong><br>
                  You agree to indemnify and hold us and our affiliates, officers, directors, agents, and employees harmless from any claims, actions, suits, proceedings, liabilities, damages, losses, costs, and expenses (including reasonable attorneys' fees) arising out of or in connection with your use of our Services, your User Content, or your breach of these terms.<br>
                  <br>
                  <strong>9. Governing Law and Dispute Resolution</strong><br>
                  These terms and conditions shall be governed by and construed in accordance with the laws of [insert governing law]. Any dispute arising out of or in connection with these terms and conditions, including any question regarding its existence, validity, or termination, shall be resolved through arbitration <br><br>
                  <strong>10. Use of Information</strong> <br>
                  We may use the information we collect to improve our services, communicate with you, and provide offers and
                  updates, as well as for other purposes disclosed at the time of collection.
  
  
        </p>
      </article>
    </div>
    </div>
      
  
    
</div>
