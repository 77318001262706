import { Component } from '@angular/core';

@Component({
  selector: 'app-jobprofileview',
  templateUrl: './jobprofileview.component.html',
  styleUrls: ['./jobprofileview.component.css']
})
export class JobprofileviewComponent {

}
