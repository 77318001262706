import { Injectable, importProvidersFrom } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CompanyJobProfile, PaginatedJobOffers, SavedJobDTO } from 'src/app/shared/company-profile-models';
import {environment} from 'src/environments/environment';




@Injectable({
  providedIn: 'root',
})
export class SavedJobService {
  private baseUrl = 'https://api.dev.blueapp.blue/v2'; // Update this with your actual base URL

  constructor(private http: HttpClient) {}

  

  getSavedJobs(userId: string): Observable<SavedJobDTO[]> {

    const endpoint = `${this.baseUrl}/profile/job/saved-job/${userId}`;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.http.get<SavedJobDTO[]>(endpoint,{ headers });
  }
}
