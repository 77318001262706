<div class="dashboardview ">

<!-- Greeting Section -->
<div class="greeting-container text-black  p-4 mt-4">
  <div>
    <h4 class="mb-3">Hello, <!-- {{username}} --></h4>
    <h3><span>{{ userProfile?.name }} {{ userProfile?.surname }}</span></h3>
    <p>Welcome to  Blueapp!</p>
  </div>
  <button class="btn btn-primary searbutton" type="button" (click)="Profile()">View Profile</button>
</div>



<div class="Recommendedsection mt-4">
  <h3>Recommended jobs for you</h3>
  <p>Jobs for you based on our matching engine</p>
  <div *ngIf="!hasMatchedJobs">
    <p>No matched jobs found.</p>
  </div>
  <!-- Display matched job profiles -->
  <div class="jobProfiles row m-1" #jobProfilesContainerMathed >
    <div *ngFor="let jobProf of visibleMatched" class="col-12 col-sm-6 col-md-4 col-lg-4 mb-3 job-profile">
      <!-- Job Card -->
      <div class="company-logo d-flex" *ngIf="hasMatchedJobs">
        <img *ngIf="jobProf.company_logo" src="{{ jobProf.company_logo }}" alt="Company Logo">
        <img *ngIf="!jobProf.company_logo" [src]="defaultCompanyLogoUrl" alt="Default Company Logo">
        <div class="d-flex row">
          <!--<div class="company-name">{{jobProf.company}}</div>-->
          <h3 class="job-title hoverable" (click)="viewProfile(jobProf)">{{ jobProf.title }}</h3>
        </div>
      </div>
      <p class="job-description limited-lines">{{ jobProf.description }}</p>
      <div class="d-flex col flex-wrap">
        <div *ngFor="let skill of jobProf.skills | slice:0:3">
          <span class="pill small-text skill-pill">{{ skill.description }}</span>
        </div>
      </div>
      <div class="row small-text job-salary">
        <span class="d-block d-md-inline iconza"><i class="fa-solid fa-location-dot"></i> Location :{{jobProf.jobLocation}}</span>
        <span class="d-block d-md-inline"><i class="fa-regular fa-clock"></i> Closing date :{{jobProf.closingDate | date : "dd-MMM-YY"}}</span>
        <span> <i class="bi bi-cash-stack"></i> {{jobProf.ratePerHour | currency:'R'}}</span>
      </div>
    </div>
  </div>

  <!-- Pagination -->
  <div class="pagination-container small-text">
    <button class="btn btn-primary small-text" (click)="scrollToLeftMatched()"><mat-icon>chevron_left</mat-icon></button>
    <button class="btn btn-primary small-text" (click)="scrollToRightMatched()"><mat-icon>chevron_right</mat-icon></button>
  </div>


</div>




<div class="Yourjobs mt-4">
  <h3>Your Saved Jobs</h3>
  <div *ngIf="savedJobs.length === 0" class="no-jobs-message">
    <mat-icon class="no-jobs-icon">error_outline</mat-icon>
    No saved jobs found. Start by viewing all the available jobs.
    <button class="btn btn-link no-jobs-button" (click)="viewAllJobs()">View Jobs</button>
  </div>
  <div *ngIf="savedJobs.length > 0" class="appliedsavedview m-1">
    <div class="jobProfiles row">
      <div *ngFor="let jobProf of savedJobs" class="col-12 col-sm-6 col-md-4 col-lg-4 mb-3 job-profile">
        <div class="company-logo d-flex" *ngIf="hasMatchedJobs">
          <img *ngIf="!jobProf.company_logo" [src]="defaultCompanyLogoUrl" alt="Default Company Logo">
          <div class="d-flex row">
            <div class="company-name">{{jobProf.company}}</div>
            <h3 class="job-title hoverable" (click)="viewProfile(jobProf.id)">{{ jobProf.title }}</h3>
          </div>
        </div>
        <p class="job-description limited-lines">{{ jobProf.description }}</p>
        <div class="row small-text job-salary">
          <span class="d-block d-md-inline iconza"><i class="fa-solid fa-location-dot"></i> Location:{{jobProf.jobLocation}}</span>
          <span class="d-block d-md-inline"><i class="fa-regular fa-clock"></i> Closing date :{{jobProf.closingDate | date : "dd-MMM-YY"}}</span>
          <span> <i class="bi bi-cash-stack"></i> {{jobProf.ratePerHour | currency:'R'}}</span>
        </div>
      </div>
    </div>

      <!-- Pagination -->
      <div class="pagination-container small-text">
        <button class="btn btn-primary small-text" (click)="scrollToLeft()"><mat-icon>chevron_left</mat-icon></button>
        <button class="btn btn-primary small-text" (click)="scrollToRight()"><mat-icon>chevron_right</mat-icon></button>
      </div>
    </div>
  </div>



  </div>





    <!--<div class="search-container bg-blue mt-4">
      <div class="input-group mb-3">
        <input #titleSearchInput type="text" class="form-control search m-1 col" placeholder="Search by title or company name" (input)="searchJobs()">
        <input #locationSearchInput type="text" class="form-control search m-1 col" placeholder="Search by Location ..." (input)="searchJobs()">
        <button class="btn btn-primary mr-2 searbutton" type="button" (click)="searchJobs()">Find Job</button>
      </div>
    </div> -->






  <!-- Jobs Matching Section --
  <div class="job-matching-section mt-0 .parent-container">
    <div class="head d-flex">
      <h2 class="section-title mt-2 mb-3 tittle">Job for you</h2>
      <a (click)="viewAllJobs()" class="viewJ">View All Jobs</a>
    </div>



     Display matched job profiles
    <div class="jobProfiles row">
      <div *ngFor="let jobProf of allJobProfiles" class="col-12 col-sm-6 col-md-4 col-lg-4 mb-3 job-profile">
        <!-- Company Logo on top right --
        <div class="company-logo d-flex">
          <img *ngIf="jobProf.company_logo" src="{{ jobProf.company_logo }}" alt="Company Logo">
          <img *ngIf="!jobProf.company_logo" [src]="defaultCompanyLogoUrl" alt="Default Company Logo">
          <div class="blue-star" (click)="toggleJobSavedStatus(jobProf)">
            <mat-icon>{{ jobProf.isJobSaved ? 'star' : 'star_border' }}</mat-icon>
          </div>
        </div>
        <!-- Company Name on its own row -
        <div class="company-name">{{jobProf.company.name}}</div>
        <!-- Job Title --
        <h3 class="job-title hoverable" (click)="viewProfile(jobProf)">{{ jobProf.title }}</h3>
        <!-- Job Description --
        <p><span class="job-salary1">{{jobProf.ratePerHour | currency:'R'}}</span></p>
        <!-- Salary, Location, Start Time on the bottom row --
    <div class="bottom-row small-text job-salary">
      <span>Rate: {{jobProf.ratePerHour | currency:'R'}}</span>
      <span class="d-block d-md-inline"> <i class="fa-solid fa-location-dot"></i> {{jobProf.jobLocation}}</span>
      <span class="d-block d-md-inline"> <i class="fa-regular fa-clock"></i> {{jobProf.closingDate | date}}</span>
    </div>
    </div>
    </div>








  </div> -->

