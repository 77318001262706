
<div class="container pt-3">
    <!-- back button -->
    <div class="row pb-3">
        <div class="col-sm-12">
            <button
            mat-stroked-button 
            (click) = "goBack()">
                <mat-icon>arrow_back</mat-icon> Back </button>
        </div>
    </div>
    <div class="alert alert-light mt-3" *ngIf="noDataExists">
        No profiles are available, most likely this means you have refreshed the page. please click back and re-select the position to view available profiles
    </div>

    <mat-card *ngIf="noMatchesFound">
        <mat-card-content>
            <p class="text-center mb-5">No matched Consultant profiles were found <br>
                
            </p>
            <!-- <div class="form-check">
                <input class="form-check-input" type="checkbox" id="check1" name="option1" value="something" checked>
                <label class="form-check-label">By checking this box, you are flagging your job spec to receive notifications should anyone of this skills be sign on to the application</label>
              </div>
              <a class="btn btn-primary btn-sm " role="button">Notify Blue Admin </a> -->
        </mat-card-content>
    </mat-card>
    <div class="row" style="height: 30%;">
<!-- <div class="col" style="height: 30%;background-color: blueviolet;">
<p>hugyftdrsfgyhujkiol</p>
</div> -->
    </div>
    <div class="row">
        <div class="col-md-6 col-lg-4  contractorView" style="display: flex;justify-content: center;" *ngFor="let contractorProfile of contractorProfiles; let i = index">
            <app-contractor-card  [contractorProfile]="contractorProfile" [rank]="i+1"></app-contractor-card>
        </div>
    </div>
</div>


