import { Component, OnInit } from '@angular/core';
import { UserProfileService } from '../modules/contractor-profile/user-profile-service.service';
import { Router } from '@angular/router';
import { FormControl, FormGroup } from '@angular/forms';
import { AuthenticationManagementService, CognitoError } from '../authentication-management.service';
import { AuthenticationService } from '../authentication.service';
import { ContractorProfileRepositoryService } from '../modules/contractor-profile/contractor-profile-repository.service';
import { SessionService } from '../shared/session.service';
import { UserProfile } from '../shared/user-profile-models';
import { ClientProfileService } from '../modules/client-profile/client-profile.service';
import { ClientProfile } from '../modules/client-profile/client-profile-models';

@Component({
  selector: 'app-update-password',
  templateUrl: './update-password.component.html',
  styleUrls: ['./update-password.component.css']
})

export class UpdatePasswordComponent implements OnInit {

  changePasswordForm: any;
  passwordError = "";
  disableButton = false;
  currentPasswordType = "password";
  newPasswordType = "password";
  confirmPasswordType = "password";

  constructor(private userProfileService: UserProfileService, private router: Router, private authentication:AuthenticationManagementService, private ProfileRepo : ContractorProfileRepositoryService, private sessionService:SessionService, private client:ClientProfileService, ) { 
      this.changePasswordForm =new FormGroup({})
  }

  ngOnInit() {
      this.changePasswordForm = this.userProfileService.getChangePasswordForm();
      const UserType = this.sessionService.getUserType();
      console.log("if user type"+UserType);
  }

  private getUsername(): string {
    return this.sessionService.getUsername();
  }




  onChangePassword() {
    if (!this.passwordValid || this.changePasswordForm.get('currentPassword').value.length === 0) {
        this.passwordError = "You need to input your current password";
        return;
    }
    if (!this.newPasswordValid || this.changePasswordForm.get('newPassword').value.length === 0) {
        this.passwordError = "You need to input a valid new password and your passwords need to match";
        return;
    }
    if (!this.confirmPasswordValid || this.changePasswordForm.get('confirmPassword').value.length === 0) {
        this.passwordError = "Your new passwords need to match";
        return;
    }
    this.disableButton = true;
    let values = this.changePasswordForm.value;

    const UserType = this.sessionService.getUserType();
      console.log("if user type"+UserType);

      if(UserType == "client"){
        this.userProfileService.changePassword(values).then(success => {
          this.client.loadProfile().subscribe(
            (Client: ClientProfile) => {

              Client.passwordDate = new Date();

              this.client.updateClientProfile(Client).subscribe(
                (success) => {
                  this.disableButton = false;
                  this.authentication.signOut();
                  this.router.navigateByUrl('/landing');
                },
                (error) => {
                  this.disableButton = false;
                  this.passwordError = error.message;
                }
              )

            }

          )

        }) 
      
      }else if(UserType == "contractor"){ 
      this.userProfileService.changePassword(values).then(success => {
        // Get the user profile data from the frontend
        const email = this.getUsername();
        console.log(email); 
        const user = this.sessionService.getUserType();
        console.log(user);
        this.ProfileRepo.loadProfile(email).subscribe(
            (userProfile: UserProfile) => {
                console.log(userProfile);

                // Update the passwordDate field
                userProfile.passwordDate = new Date();

                // Call the updateContractorProfile method to update the entire profile
                this.ProfileRepo.updateContractorProfile(userProfile)
                    .subscribe(
                        profileUpdateSuccess => {
                            //console.log(profileUpdateSuccess);
                            this.disableButton = false;
                            this.authentication.signOut();
                            this.router.navigateByUrl('/landing');
                        },
                        profileUpdateError => {
                            this.disableButton = false;
                            this.passwordError = profileUpdateError.message;
                        }
                    );
            },
            error => {
                this.disableButton = false;
                this.passwordError = error.message;
            }
        );
    }).catch((error: CognitoError) => {
        this.disableButton = false;
        this.passwordError = error.message;
    });
      }
}


  toggleVisibility(name: string){
      switch (name.toLowerCase()){
          case "currentpassword":
              this.currentPasswordType = (this.currentPasswordType === "password") ? "text" : "password";
              break;
          case "newpassword":
              this.newPasswordType = (this.newPasswordType === "password") ? "text" : "password";
              this.confirmPasswordType = (this.confirmPasswordType === "password") ? "text" : "password";
              break;
      }
  }

  get currentPasswordVisible(): boolean{
      return this.currentPasswordType === "text";
  }

  get newPasswordVisible(): boolean{
      return this.newPasswordType === "text";
  }

  get confirmPasswordVisible(): boolean{
      return this.confirmPasswordType === "text";
  }

  get hasErrors():boolean {
      return this.passwordError.length > 0;
  }

  get passwordValid(): boolean {
      let element = this.changePasswordForm.get('currentPassword') as FormControl;
      return !(element.invalid && (element.dirty || element.touched));
  }

  get newPasswordValid(): boolean {
      let element = this.changePasswordForm.get('newPassword') as FormControl;
      let element2 = this.changePasswordForm.get('confirmPassword') as FormControl;
      return !(element.invalid && (element.dirty || element.touched)) && (element.value == element2.value);
  }

  get confirmPasswordValid(): boolean {
      let element = this.changePasswordForm.get('newPassword') as FormControl;
      let element2 = this.changePasswordForm.get('confirmPassword') as FormControl;
      return !(element.invalid && (element.dirty || element.touched)) && (element.value == element2.value);
  }
}

