import { Inject, Injectable } from '@angular/core';
import { AuthenticationManagementService } from 'src/app/authentication-management.service';
import { SessionService } from 'src/app/shared/session.service';
import { CognitoError } from 'src/app/shared/http-error-handler';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { UserManagementService } from './user-management.service';
import {
  ClientRegisterStep,
  ContractorRegisterStep,
  AuthData,
  LoginStep,
  ForgotPasswordStep,
  InvitedUserProfile,
  CompanyProfile,
  UserType,
  RegistrationStatusEnum,
  UserTypeEnum,
} from './user-profile-models';
import { DataService } from 'src/app/shared/data.service';
import { UserProfileData } from '../contractor-profile/user-profile-data';
/* import { EmailServiceService } from 'src/app/email-service.service'; */
import { EmailService } from './email.service';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { ResumeStep } from './user-profile-models';
import { ClientProfileService } from '../client-profile/client-profile.service';

@Injectable({
  providedIn: 'root',
})
export class WizardService {
  private resumeStep: ResumeStep = 'upload_resume';
  private resumeSubject: Subject<ResumeStep> = new Subject();
  private loginStep: LoginStep = 'login';
  private clientStep: ClientRegisterStep = 'register';
  private contractorStep: ContractorRegisterStep = 'register';
  private authData: AuthData = { email: '', password: '', userType: 'client' };
  private cellNumber!: string;
  private clientProfile: any = {};
  private contractorProfile: any = {};
  loginSubject: Subject<LoginStep> = new Subject();
  private registerClientSubject: Subject<ClientRegisterStep> = new Subject();
  private registerContractorSubject: Subject<ContractorRegisterStep> =
    new Subject();
  private forgotPasswordSubject: BehaviorSubject<ForgotPasswordStep> =
    new BehaviorSubject<any>('forgot_password');
  errorSubject: BehaviorSubject<string> = new BehaviorSubject('');
  loginEvent$ = this.loginSubject.asObservable();
  registerClientEvent$ = this.registerClientSubject.asObservable();
  registerContractorEvent$ = this.registerContractorSubject.asObservable();
  forgotPasswordEvent$ = this.forgotPasswordSubject.asObservable();
  errorEvent$ = this.errorSubject.asObservable();
  resumeEvent$ = this.resumeSubject.asObservable();
  isContractorFirstTimeRegistration: boolean = false;
  resumeUploadEvent$ = new Subject();
  loading: boolean = false;

  constructor(
    private router: Router,
    private http: HttpClient,
    private authManagementService: AuthenticationManagementService,
    private sessionService: SessionService,
    private userManagementService: UserManagementService,
    private dataClass: DataService,
    private emailService: EmailService,
    private clientService: ClientProfileService
  ) {}

  setAuthData(email: string, password: string) {
    this.authData.email = email;
    this.authData.password = password;
  }

  clearAuthData() {
    this.authData.email = '';
    this.authData.email = '';
    // Also clear the cell number
    this.clearCellNumber();
  }

  setCellNumber(cellnumber: string) {
    this.cellNumber = cellnumber;
  }

  clearCellNumber() {
    this.cellNumber = '';
  }

  reset() {
    this.loginStep = 'login';
    this.clientStep = 'register';
    this.contractorStep = 'register';
  }

  login(email: string, password: string): Observable<any> {
    this.setAuthData(email, password);
    this.sessionService.setUsername(email);
    return this.authManagementService.signIn(email, password);
  }

  //create a delete that will be called on
  deactivateAccount(): Observable<any> {
    this.sessionService.clearCurrentCandidate(); //ToDo uncomment
    return this.authManagementService.deleteUser();
  }

  verifyEmailAddress(verificationCode: string) {
    let email = this.sessionService.getUsername();
    this.authManagementService
      .confirmSignUpLogin(email, verificationCode)
      .subscribe(
        () => {
          this.updateUserStatus(email, RegistrationStatusEnum.VERIFIED);
          this.loginSubject.next('verify_email_complete');
          this.registerContractorSubject.next('verify_email_complete');
          this.registerClientSubject.next('verify_email_complete');
        },
        (verifyEmailAddressError: CognitoError) => {
          this.errorSubject.next(verifyEmailAddressError.message);
        }
      );
  }

  resendVerificationCode() {
    let email = this.sessionService.getUsername();
    this.authManagementService
      .resendCodeForUser(email)
      .catch((resendVerificationCodeError: CognitoError) => {
        this.errorSubject.next(resendVerificationCodeError.message);
      });
  }

  requestPasswordReset(email: string) {
    this.authManagementService
      .forgotPassword(email)
      .then(() => {
        this.sessionService.setUsername(email);
        this.forgotPasswordSubject.next('create_password');
      })
      .catch((forgotPasswordError: CognitoError) => {
        this.errorSubject.next(forgotPasswordError.message);
      });
  }

  resetPassword(code: string, password: string) {
    let email = this.sessionService.getUsername();
    this.authManagementService
      .forgotPasswordSubmit(email, code, password)
      .then(() => {
        this.forgotPasswordSubject.next('login');
      })
      .catch((resetPasswordError: CognitoError) => {
        this.errorSubject.next(resetPasswordError.message);
      });
  }

  register() {
    this.authManagementService
      .signUp(this.authData.email, this.authData.password)
      .subscribe(
        () => {
          this.registerContractorSubject.next('verify_email');
          this.registerClientSubject.next('verify_email');
          this.sessionService.setUsername(this.authData.email);
          this.sessionService.setCellnumber(this.cellNumber);
          this.sessionService.setUserType(this.authData.userType.toString());
        },
        (registerUserError: CognitoError) => {
          this.errorSubject.next(registerUserError.message);
        }
      );
  }

  manualLogin() {
    this.authManagementService
      .signIn(this.authData.email, this.authData.password)
      .subscribe(
        () => {
          this.loginSubject.next('load_user_details');
          this.registerContractorSubject.next('contractor_profile');
          this.registerClientSubject.next('client_profile');
          this.clearAuthData();
        },
        (loginError: CognitoError) => {
          this.errorSubject.next(loginError.message);
        }
      );
  }

  createClientProfile() {
    let email = this.sessionService.getUsername();
    this.clientProfile.emailAddress = email;
    this.userManagementService
      .createClientProfile(this.clientProfile)
      .subscribe(
        () => {
          this.registerClientSubject.next('accept_permissions');
          this.updateUserStatus(email, RegistrationStatusEnum.COMPLETE);
          this.sessionService.setUserProfileComplete();
          const ClientName = this.clientProfile.companyName;
          this.sendEmailClient(email, ClientName);
        },
        (updateClientProfileError) => {
          this.clientService
            .updateClientProfileOnboarding(this.clientProfile)
            .subscribe((data: any) => {
              this.updateUserStatus(email, RegistrationStatusEnum.COMPLETE);
              this.registerClientSubject.next('accept_permissions');
              this.sessionService.setUserProfileComplete();
              this.router.navigateByUrl('/main/client-profile');
            });
        }
      );
  }

  async sendEmailClient(email: string, ClientName: string) {
    try {
      // Retrieve the HTML template
      const htmlBody = await this.http
        .get('assets/WelcomeEmailClient.html', { responseType: 'text' })
        .toPromise();
      //

      // Check if htmlBody is defined before using it
      if (htmlBody) {
        // Replace placeholders with actual data
        const processedHtmlBody = htmlBody.replace(
          '{{ClientName}}',
          ClientName
        );

        // Prepare email parameters
        const params = {
          email: email,
          subject: 'Welcome to Blueapp Platform',
          ClientName,
          htmlBody: processedHtmlBody,
          textBody: '', // You can add a text body if needed
        };

        // Send the email with processed HTML body
        await this.emailService.sendEmailClient(
          email,
          params.subject,
          processedHtmlBody
        );
      } else {
        console.error('Error: HTML body is undefined');
      }
    } catch (error) {
      console.error('Error sending email:', error);
    }
  }

  loadClientProfile() {
    this.sessionService.loadClientProfile().subscribe(
      () => {
        this.loginSubject.next('accept_permissions');
      },
      (clientValidationError: any) => {
        this.errorSubject.next(clientValidationError.error.message);
      }
    );
  }

  createContractorProfile() {
    let email = this.sessionService.getUsername();
    const ContractorName = this.contractorProfile.name;
    this.contractorProfile.email = email;

    this.userManagementService
      .createContractorProfile(this.contractorProfile)
      .subscribe(
        () => {
          this.updateUserStatus(email, RegistrationStatusEnum.COMPLETE);
          this.sendEmail(email, ContractorName);
          this.isContractorFirstTimeRegistration = true;

          this.loading = true;
          this.loadContractorProfile();
          localStorage.setItem('isContractorFirstTimeRegistration', 'true');

          this.router.navigate(['main/user-profile']);
        },
        (createContractorError) => {
          this.errorSubject.next(createContractorError.error.message);
        }
      );
  }

  loadContractorProfile() {
    this.sessionService.loadContractorProfile().subscribe(
      () => {
        /*  this.loginSubject.next('accept_permissions'); */
      },
      (contractorValidationError: any) => {
        this.errorSubject.next(contractorValidationError.error.message);
        this.isContractorFirstTimeRegistration;
      }
    );
  }

  // Send a welcome email to the user using SES. Contractor
  async sendEmail(email: string, ContractorName: string) {
    try {
      // Retrieve the HTML template
      const htmlBody = await this.http
        .get('assets/WelcomeEmailContractor.html', { responseType: 'text' })
        .toPromise();

      // Check if htmlBody is defined before using it
      if (htmlBody) {
        // Replace placeholders with actual data
        const processedHtmlBody = htmlBody.replace(
          '{{ContractorName}}',
          ContractorName
        );

        // Prepare email parameters
        const params = {
          email: email,
          subject: 'Welcome to Blue!',
          htmlBody: processedHtmlBody,
          textBody: '', // You can add a text body if needed
        };

        // Send the email with processed HTML body
        await this.emailService.sendEmail(
          email,
          params.subject,
          processedHtmlBody
        );
      } else {
        console.error('Error: HTML body is undefined');
      }
    } catch (error) {
      console.error('Error sending email:', error);
    }
  }

  getCompanyUsers() {
    return new Promise((resolve, reject) => {
      this.userManagementService
        .getCompanyUsers(this.sessionService.getUsername())
        .subscribe(
          (users: InvitedUserProfile[]) => resolve(users),
          (error) => reject(error)
        );
    });
  }

  getCompanyDetails(companyRegistrationNumber: string) {
    return new Promise((resolve, reject) => {
      this.userManagementService
        .getCompanyDetails(companyRegistrationNumber)
        .subscribe(
          (profile: CompanyProfile) => resolve(profile),
          (error) => reject(error)
        );
    });
  }

  requestToJoinCompany(
    userEmail: string,
    companyName: string,
    companyRegistrationNumber: string
  ) {
    let profileData: any = {
      userEmail: userEmail,
      companyName: companyName,
      companyRegistrationNumber: companyRegistrationNumber,
    };
    return new Promise((resolve, reject) => {
      this.userManagementService.requestToJoinCompany(profileData).subscribe(
        (result) => resolve(result),
        (error) => reject(error)
      );
    });
  }

  inviteNewUser(name: string, email: string, role: string) {
    var postData = {
      userName: name,
      userEmail: email,
      selectedRole: role,
    };
    return new Promise((resolve, reject) => {
      this.userManagementService
        .inviteNewUser(postData, this.sessionService.getUsername())
        .subscribe(
          (result) => resolve(result),
          (error) => reject(error)
        );
    });
  }

  createUserProfile(
    email: string,
    cellnumber: string,
    password: string,
    type: UserType
  ) {
    this.setAuthData(email, password);
    this.setCellNumber(cellnumber);
    let userType: UserTypeEnum =
      type === 'client' ? UserTypeEnum.CLIENT : UserTypeEnum.CONTRACTOR;
    let userProfile = {
      emailAddress: email,
      cellNumber: cellnumber,
      type: userType,
      status: RegistrationStatusEnum.UNVERIFIED,
    };
    this.userManagementService.createUserProfile(userProfile).subscribe(
      (res: any) => {
        if (type === 'client')
          this.registerClientSubject.next('cognito_profile');
        if (type === 'contractor')
          this.registerContractorSubject.next('cognito_profile');
      },
      (error) => {
        console.error(error);
        this.errorSubject.next(error.error.message);
      }
    );
  }

  updateUserStatus(emailAddress: string, status: RegistrationStatusEnum) {
    let userProfile = {
      status: status,
    };
    this.userManagementService
      .updateUserProfile(userProfile, emailAddress)
      .subscribe(
        (result) => {},
        (error) => {
          this.errorSubject.next(error.error.message);
        }
      );
  }

  getUserProfile() {
    let emailAddress = this.sessionService.getUsername();
    this.userManagementService.retrieveUserProfile(emailAddress).subscribe(
      (profile) => {
        if (profile.status === 'COMPLETE') {
          this.sessionService.setUserProfileComplete();
          this.authManagementService.triggerEvent(true);
          if (profile.type === 'CLIENT') this.loadClientProfile();
          if (profile.type === 'CONTRACTOR') this.loadContractorProfile();
        } else if (profile.status === 'VERIFIED') {
          if (profile.type === 'CLIENT') {
            this.sessionService.setUserType('client');
            this.loginSubject.next('client_incomplete');
          }
          if (profile.type === 'CONTRACTOR') {
            this.sessionService.setUserType('contractor');
            this.loginSubject.next('contractor_incomplete');
          }
        } else {
          this.errorSubject.next(
            'Could not determine your profile state. Please contact support'
          );
        }
        return profile;
      },
      (error) => {
        console.error(error);
        this.errorSubject.next(error.error.message);
      }
    );
  }

  uploadResume() {
    this.resumeSubject.next('upload_resume');
  }

  clearClientProfile() {
    this.clientProfile = {};
  }

  setClientProfile(profile: any) {
    this.clientProfile = { ...this.clientProfile, ...profile };
  }

  getClientProfile() {
    return this.clientProfile;
  }

  setClientStep(step: ClientRegisterStep) {
    this.clientStep = step;
  }

  clearContractorProfile() {
    this.contractorProfile = {};
  }

  setContractorProfile(profile: any) {
    this.contractorProfile = { ...this.contractorProfile, ...profile };
  }

  getContractorProfile() {
    return this.contractorProfile;
  }

  setContractorStep(step: ContractorRegisterStep) {
    this.contractorStep = step;
  }

  getLoginStep(): LoginStep {
    return this.loginStep;
  }

  getClientStep(): ClientRegisterStep {
    return this.clientStep;
  }

  getContractorStep(): ContractorRegisterStep {
    return this.contractorStep;
  }

  triggerClientEvent(step: ClientRegisterStep) {
    this.registerClientSubject.next(step);
  }

  triggerContractorEvent(step: ContractorRegisterStep) {
    this.registerContractorSubject.next(step);
  }

  skip() {
    this.registerContractorSubject.next('accept_permissions');
    this.registerClientSubject.next('accept_permissions');
  }

  getUserName(): string {
    return this.sessionService.getUsername();
  }

  getCellNumber(): string {
    return this.sessionService.getCellnumber();
  }

  getLevels() {
    return UserProfileData.levels();
  }

  getTitles() {
    return UserProfileData.titles();
  }

  loadSkills() {
    this.dataClass.setSkills();
  }

  getSkills() {
    return this.dataClass.getSkills();
  }

  getRace() {
    return this.dataClass.getSkills();
  }
}
