import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { WatsonAIServiceJobDescription, WatsonResponse } from '../../../Job_Discription_Engine.service';
import { Clipboard } from '@angular/cdk/clipboard';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-job-description-dialog',
  templateUrl: './job-description-dialog.component.html',
  styleUrls: ['./job-description-dialog.component.css']
})
export class JobDescriptionDialogComponent {
  industry: string = '';
  jobTitle: string = '';
  skills: string = '';
  yearsExperience: number = 0;
  response: string = '';
  isLoading: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<JobDescriptionDialogComponent>,
    private watsonAIService: WatsonAIServiceJobDescription,
    private clipboard: Clipboard,
    private snackBar: MatSnackBar
  ) {}

  onCancel(): void {
    this.dialogRef.close();
  }

  onGenerate(): void {
    this.isLoading = true;
    this.response = '';
    const prompt = {
      job_title: this.jobTitle,
      industry: this.industry,
      years_experience: this.yearsExperience.toString(),
      skills: this.skills
    };

    this.watsonAIService.generateJobDescription(prompt).subscribe(
      (response: WatsonResponse) => {
        console.log('Raw response:', JSON.stringify(response, null, 2));
        
        if (response && response.results && response.results.length > 0) {
          this.response = this.formatResponse(response.results[0].generated_text);
        } else {
          this.response = 'No job description generated. Please try again.';
        }
        
        this.isLoading = false;
      },
      error => {
        console.error('Error generating job description:', error);
        this.response = 'Error generating job description. Please try again.';
        this.isLoading = false;
      }
    );
  }

  copyToClipboard(): void {
    this.clipboard.copy(this.response);
    this.snackBar.open('Job description copied to clipboard', 'Close', {
      duration: 2000,
    });
  }

  private formatResponse(text: string): string {
    // Split the text into sections
    const sections = text.split(/\n(?=[A-Z][a-z]+:|\*\*[A-Z\s]+:\*\*)/);
    
    // Format each section
    const formattedSections = sections.map(section => {
      const lines = section.split('\n');
      const header = lines[0].trim();
      const content = lines.slice(1).join('\n').trim();

      // Format header
      const formattedHeader = `\n${header}\n${'='.repeat(header.length)}`;

      // Format content
      let formattedContent = '';
      if (content.includes('*')) {
        // If content has bullet points, keep original formatting
        formattedContent = content.split('\n').map(line => line.trim()).join('\n');
      } else {
        // Otherwise, wrap the text
        formattedContent = this.wrapText(content, 80);
      }

      return `${formattedHeader}\n\n${formattedContent}`;
    });

    return formattedSections.join('\n\n');
  }

  private wrapText(text: string, maxLineLength: number): string {
    const words = text.split(' ');
    let lines = [];
    let currentLine = '';

    words.forEach(word => {
      if ((currentLine + word).length > maxLineLength) {
        lines.push(currentLine.trim());
        currentLine = '';
      }
      currentLine += word + ' ';
    });

    if (currentLine) {
      lines.push(currentLine.trim());
    }

    return lines.join('\n');
  }
}