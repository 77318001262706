<div class="mt-0 container" id="Home">
    <div class="customHero">
        <div class="container">
            <div class="textbottom mt-3">
                <p class="h4 text-weight-bold text-white textwhite"><span class="textwhite">An excellent portal for both </span> <span class="blue-text">clients and</span></p>
                <p class="h4 text-weight-bold text-white textwhite"><span class="blue-text">developer consultants</span> <span class="textwhite"> in the technology industry</span> </p>
            </div>
        </div>
        <div class="button-container">
            <button class="btn btn-primary customColorAButton" routerLink="/auth/register/client">Job Search</button>
            <button class="btn btn-primary customColorAButton" routerLink="/auth/register/consultant">Find A Talent</button>
        </div>
    </div>


    <div class="text-center md-5 p-5 bg-white  mb-5" id="Benefits">

        <div class="container">
            <h2 class="h1 text-dark display-4 my-3 text-wrap" style="font-size: 35px">The benefits of <span
                    class="_customColorSpan">Blue!</span></h2>

            <p>
                The BlueApp redefines your digital journey with a user-friendly interface, powerful features, and a
                commitment to seamless
                innovation—elevating your experience effortlessly.

            </p>

            <div class="my-3 p-3">
                <div class="row justify-content-center" style="flex-direction: row;">
                    <div class="col-md-2 col-sm-3 m-2 text-center">
                        <div>
                            <img src="/assets/icons/client.png" alt="" class="custom-img">
                        </div>
                        <p class="text-dark text-center">Easily accessible job opportunities</p>
                    </div>
                    <div class="col-md-2 col-sm-3 m-2 text-center">
                        <div>
                            <img src="/assets/icons/verified.png" alt="" class="custom-img">
                        </div>
                        <p class="text-dark text-center">Access database of highly qualified consultants</p>
                    </div>

                    <div class="col-md-2 col-sm-3 m-2 text-center">
                        <div>
                            <img src="/assets/icons/freelance.png" alt="" class="custom-img">
                        </div>
                        <p class="text-dark text-center">Free to sell hours to either one client or multiple clients</p>
                    </div>

                    <div class="col-md-2 col-sm-3 m-2 text-center">
                        <div>
                            <img src="/assets/icons/finger-print.png" alt="" class="custom-img">
                        </div>
                        <p class="text-dark text-center">We do all background checks for you!</p>
                    </div>

                    <div class="col-md-2 col-sm-3 m-2 text-center">
                        <div>
                            <img src="/assets/icons/gig-economy.png" alt="" class="custom-img">
                        </div>
                        <p class="text-dark text-center">Consultants are hired per the number of hours a client requires
                        </p>
                    </div>

                </div>
            </div>
        </div>
    </div>

    <div class="text-center col-lg-12 p-3 sm-5 mt-3 mb-4 "
        style=" background-color:  #F5F5F5; justify-content: center; align-items: center;" id="Benefits">
        <div class="row   flex-column">
            <div class="client my-3">
                <div style="font-size: 35px">

                    <p class="  text-dark fw-bold text-center mt-3">Our<span class="_customColorSpan"> Clients</span>
                    </p>
                </div>


                <h3 class=" mb-5 sm-5 text-centre">Several selected clients, who already believe in our services.</h3>
                <div class="body">
                    <div class=" slider">
                        <div class="slider-track">
                        <div  class="slide mx-3">
                                <img src="../../assets/images/download__1_-removebg-preview 1.png" class="img-fluid " alt="Blue Logo" style="width: 120px; height: auto; margin-top: 1%; margin-bottom: 3%;">
                        </div>
                        <div  class="slide mx-3">
                            <img src="../../assets/images/discovery_logo_0-removebg-preview 1.png" class="img-fluid " alt="Blue Logo" style="width: 130px; margin-top: 1%; margin-bottom: 3%; height: 45px;">
                        </div>
                        <div  class="slide mx-3">
                            <img src="../../assets/icons/Kyndryl_logo.svg (1).png" class="img-fluid " style="width: 100px; margin-top: 1%; margin-bottom: 3%; height: 35px;" alt="Blue Logo">

                        </div>
                        <div  class="slide mx-3">
                            <img src="assets/images/Deloitte_Logo.png" style="width: 100px; margin-top: 1%; margin-bottom: 3%; height: 35px;" class="img-fluid " alt="Blue Logo">

                        </div >

                        <div  class="slide mx-3">
                            <img src="../../assets/images/IBM_logo.png" class="img-fluid " style="margin-top: 1%; margin-bottom: 3%; height: 30px;"  alt="Blue Logo">

                        </div>
                        <div class="slide mx-3">
                            <img src="../../assets/icons/anglo-american-logo.png" class="img-fluid " style="width: 150px; height: auto; margin-top: 1%; margin-bottom: 3%;" alt="Blue Logo">

                        </div>
                        <div  class="slide mx-3">
                            <img src="../../assets/images/download__1_-removebg-preview 1.png" class="img-fluid " alt="Blue Logo" style="width: 120px; height: auto; margin-top: 1%; margin-bottom: 3%;">
                    </div>
                    <div  class="slide mx-3">
                        <img src="../../assets/images/discovery_logo_0-removebg-preview 1.png" class="img-fluid " alt="Blue Logo" style="width: 130px; margin-top: 1%; margin-bottom: 3%; height: 45px;">
                    </div>
                    <div  class="slide mx-3">
                        <img src="../../assets/images/kyndril.png" class="img-fluid " style="width: 100px; height: 35px;" alt="Blue Logo">

                    </div>
                    <div  class="slide mx-3">
                        <img src="assets/images/Deloitte_Logo.png" style="width: 100px; margin-top: 1%; margin-bottom: 3%; height: 35px;" class="img-fluid " alt="Blue Logo">

                    </div >

                    <div  class="slide mx-3">
                        <img src="../../assets/images/IBM_logo.png" class="img-fluid " style="margin-top: 1%; margin-bottom: 3%; height: 30px;"  alt="Blue Logo">

                    </div>
                    <div class="slide mx-3">
                        <img src="../../assets/icons/anglo-american-logo.png" class="img-fluid " style="width: 150px; height: auto; margin-top: 1%; margin-bottom: 3%;" alt="Blue Logo">

                    </div>
                    <div  class="slide mx-3">
                        <img src="../../assets/images/discovery_logo_0-removebg-preview 1.png" class="img-fluid " alt="Blue Logo" style="width: 130px; margin-top: 1%; margin-bottom: 3%; height: 45px;">
                    </div>
                    <div  class="slide mx-3">
                        <img src="../../assets/icons/Kyndryl_logo.svg (1).png" class="img-fluid " style="width: 100px; margin-top: 1%; margin-bottom: 3%; height: 35px;" alt="Blue Logo">

                    </div>
                    <div  class="slide mx-3">
                        <img src="assets/images/Deloitte_Logo.png" style="width: 100px; margin-top: 1%; margin-bottom: 3%; height: 35px;" class="img-fluid " alt="Blue Logo">

                    </div >

                    <div  class="slide mx-3">
                        <img src="../../assets/images/IBM_logo.png" class="img-fluid " style="margin-top: 1%; margin-bottom: 3%; height: 30px;"  alt="Blue Logo">

                    </div>
                    <div class="slide mx-3">
                        <img src="../../assets/icons/anglo-american-logo.png" class="img-fluid " style="width: 150px; height: auto; margin-top: 1%; margin-bottom: 3%;" alt="Blue Logo">

                    </div>
                    <div  class="slide mx-3">
                        <img src="../../assets/images/download__1_-removebg-preview 1.png" class="img-fluid " alt="Blue Logo" style="width: 120px; height: auto; margin-top: 1%; margin-bottom: 3%;">
                </div>
                <div  class="slide mx-3">
                    <img src="../../assets/images/discovery_logo_0-removebg-preview 1.png" class="img-fluid " alt="Blue Logo" style="width: 130px; margin-top: 1%; margin-bottom: 3%; height: 45px;">
                </div>
                <div  class="slide mx-3">
                    <img src="../../assets/images/kyndril.png" class="img-fluid " style="width: 100px; height: 35px;" alt="Blue Logo">

                </div>
                <div  class="slide mx-3">
                    <img src="assets/images/Deloitte_Logo.png" style="width: 100px; margin-top: 1%; margin-bottom: 3%; height: 35px;" class="img-fluid " alt="Blue Logo">

                </div >

                <div  class="slide mx-3">
                    <img src="../../assets/images/IBM_logo.png" class="img-fluid " style="margin-top: 1%; margin-bottom: 3%; height: 30px;"  alt="Blue Logo">

                </div>
                        </div>

                             </div>


                </div>





            </div>
        </div>
    </div>


    <div id="carouselExampleControls" class="carousel slide mt-4" data-bs-ride="carousel" style="background-color: white;">
        <div class="carousel-inner">
            <h1 class="  text-dark fw-bold text-center mt-4" style="text-align: center; margin-top: 6%; font-size: 32px;">What our clients <span
                class="_customColorSpan">say</span> </h1>

                <div style="margin: 4%; ">


            <div class="carousel-item active">
                <!-- <img src="../../assets/12oc23am.png" class="d-block w-100" alt="..."> -->
                <div class="d-block w-100" style="align-items: center;background-color: white; ">


                    <i class="fa-solid fa-quote-left" style="margin-left: -60%;"></i>
                    <p>
                        Blue has an awesome
                        matching engine<br>

                        that simplified my job search, the
                        application<br>
                         matches all the jobs related to my profile
                        and all I had to do was to select my
                        desired jobs and apply.<br> I would highly recommend this
                        application to all ICT prodessionals.

                    </p>
                    <i class="fa-solid fa-quote-right" style="margin-left: 60%;"></i>


                  <h6 class=" d-block w-100" style="text-align: center ;margin-bottom: 6%; ">A Lekota </h6>


                </div>


            </div>
            <div class="carousel-item ">
                <div class="d-block w-100" style="align-items: center;background-color: white; ">

                    <i class="fa-solid fa-quote-left" style="margin-left: -60%;"></i>
<p>


                    The phone and messaging conversations I had with

                    the Admin team<br> was nothing short of amazing.<br>
                    The whole process of navigating around the <br>
                    app was explained to me and that really helped.
                </p>
                    <i class="fa-solid fa-quote-right" style="margin-left: 60%;"></i>


                    <h5 class=" d-block w-100" style="text-align: center ;margin-bottom: 6%; ">I Tafadzwa</h5>




                </div>
            </div>
            <div class="carousel-item">
                <div class="d-block w-100" style="align-items: center;background-color: white; ">

                    <i class="fa-solid fa-quote-left" style="margin-left: -60%;"></i>
<p>
                   It was a great experience using Blue and

                      will partner with them again.<br> I have used the App for
                    a couple of months, and feel satisfied with <br>
                   it for the way they handled my projects.<br>
                     Blue App accurately matches my profile with current job roles.
                    </p>
                    <i class="fa-solid fa-quote-right" style="margin-left: 60%;"></i>


                    <h6 class=" d-block w-100" style="text-align: center ;margin-bottom: 6%; ">D Siziba </h6>


                </div>
            </div>
        </div>
        </div>
        <button class="carousel-control-prev" style="color: rgb(239, 232, 232);" type="button" data-bs-target="#carouselExampleControls"
            data-bs-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
        </button>
        <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls"
            data-bs-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden" >Next</span>
        </button>
    </div>





    <div class="text-center md-5 p-5 sm-5 mb-4 bg-white">
      <h2 class="h2 text-dark text-wrap">Find developers specializing in any of these<br><span
              class="_customColorSpan">technologies</span></h2>
      <hr>
      <div class="row gridLanguage align-items-center justify-content-center">
          <div class="col-6 col-sm-3 col-md-3 item"><img src="/assets/12oc23am.png" alt=""></div>
          <div class="col-6 col-sm-3 col-md-3 item"><img src="/assets/icons/uzn8s45w.png" alt=""></div>
          <div class="col-6 col-sm-3 col-md-3 item"><img src="/assets/icons/8z583ymv.png" alt=""></div>
          <div class="col-6 col-sm-3 col-md-3 item"><img src="/assets/icons/6mujdzoe.png" alt=""></div>
          <div class="col-6 col-sm-3 col-md-3 item"><img src="/assets/icons/bn1v9jm7.png" alt=""></div>
          <div class="col-6 col-sm-3 col-md-3 item"><img src="/assets/icons/fas733op.png" alt=""></div>
          <div class="col-6 col-sm-3 col-md-3 item"><img src="/assets/icons/6hj3czkt.png" alt=""></div>
          <div class="col-6 col-sm-3 col-md-3 item"><img src="/assets/icons/v05w8mkp.png" alt=""></div>
          <div class="col-6 col-sm-3 col-md-3 item"><i class="fab fa-php"></i></div>
          <div class="col-6 col-sm-3 col-md-3 item"><i class="fab fa-aws"></i></div>
          <div class="col-6 col-sm-3 col-md-3 item"><i class="fab fa-node"></i></div>
          <div class="col-6 col-sm-3 col-md-3 item"><i class="fas fa-cloud"></i></div>
          <div class="col-6 col-sm-3 col-md-3 item"><img src="/assets/icons/nfhuh29g.png" alt=""></div>
          <div class="col-6 col-sm-3 col-md-3 item"><i class="fa-brands fa-hubspot"></i></div>
          <div class="col-6 col-sm-3 col-md-3 item"><img src="/assets/icons/95rkhm9m.png" alt=""></div>
          <div class="col-6 col-sm-3 col-md-3 item"><img src="/assets/icons/hrz3k9du.png" alt=""></div>
          <div class="col-6 col-sm-3 col-md-3 item"><img src="/assets/icons/oe4kwo3r.png" alt=""></div>
          <div class="col-6 col-sm-3 col-md-3 item"><img src="/assets/icons/f0b34g0q.png" alt=""></div>
          <div class="col-6 col-sm-3 col-md-3 item"><img src="/assets/icons/46idvk7g.png" alt=""></div>
          <div class="col-6 col-sm-3 col-md-3 item"><img src="/assets/icons/7smjisdh.png" alt=""></div>
          <div class="col-6 col-sm-3 col-md-3 item"><img src="/assets/icons/g0fn2et3.png" alt=""></div>
          <div class="col-6 col-sm-3 col-md-3 item"><img src="/assets/icons/brw9klm6.png" alt=""></div>
      </div>
  </div>
        <!-- <button mat-fab class="back-to-top-button" (click)="scrollToTop()" [class.show]="showBackToTopButton">
            <i class="fa-solid fa-circle-chevron-up"></i>
        </button> -->




    </div>

    <!-- </div> -->

    <script>


    </script>
