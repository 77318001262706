import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { NotificationService, AppNotification } from '../notification.service';
import { SessionService } from '../shared/session.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { NotificationDetailsComponent } from '../notification-details/notification-details.component';
import { MatMenuTrigger } from '@angular/material/menu';

@Component({
  selector: 'app-notification-dropdown',
  template: `
    <button mat-button [matMenuTriggerFor]="menu">
      <mat-icon *ngIf="unreadNotificationsCount > 0" matBadge="{{ unreadNotificationsCount }}"matBadgeColor="warn" matBadgeSize="small">notifications</mat-icon>
    </button>
    <mat-menu #menu="matMenu" class="contentdisplay">
      <div *ngFor="let notification of filteredNotifications" class="content" (click)="openNotificationDetails(notification)">
        {{ notification.message }}
      </div>
    </mat-menu>
  `,
  styleUrls: ['./notification-dropdown.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class NotificationDropdownComponent implements OnInit {
  unreadNotificationsCount: number = 0;
  loggedInUserId: string = this.sessionService.getUsername();
  notifications: AppNotification[] = [];

  constructor(private notificationService: NotificationService, private sessionService: SessionService, private dialog: MatDialog) {}

  ngOnInit(): void {
    this.notificationService.getNotifications(this.loggedInUserId)
      .subscribe(notifications => {
        if (notifications) {
          this.notifications = notifications;
          this.updateUnreadCount();
          
        } else {
          this.notifications = [];
        }
      });
  }

  updateUnreadCount() {
    this.unreadNotificationsCount = this.notifications.filter(n => !n.read).length;
  }

  @ViewChild(MatMenuTrigger)
  menuTrigger!: MatMenuTrigger;

  onMenuClosed() {
    const notificationIds = this.notifications
      .filter(n => n.read)
      .map(n => n.id);

    if (notificationIds.length > 0) {
      this.notificationService.markAsRead(notificationIds);
      this.updateUnreadCount();
    }
  }

  get filteredNotifications(): AppNotification[] {
    return this.notifications.filter(notification => !notification.read);
  }

  openNotificationDetails(notification: AppNotification): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = notification;
    this.markNotificationAsRead(notification.id);
    dialogConfig.autoFocus = true;
    dialogConfig.hasBackdrop = true; // Close dialog on clicking outside
    dialogConfig.closeOnNavigation = true; // Close dialog if user navigates away
    this.dialog.open(NotificationDetailsComponent, dialogConfig);
  }

  markNotificationAsRead(id: any) {
    this.notificationService.markAsRead([id]); // Pass ID as an array
    
    const index = this.notifications.findIndex(n => n.id === id);
    if (index !== -1) {
      this.notifications[index].read = true;
      this.updateUnreadCount();
    }
  }
};
