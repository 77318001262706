import { Injectable } from '@angular/core';
import { Auth } from 'aws-amplify';
import { AuthenticationDetails, CognitoUser,CognitoUserPool,CognitoUserAttribute } from 'amazon-cognito-identity-js';
import { catchError, finalize, forkJoin, from, map, Observable, of, switchMap, tap, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { BehaviorSubject } from 'rxjs';
import { SessionStorageService } from './shared/session-storage.service';
import { ContractorProfileRepositoryService } from './modules/contractor-profile/contractor-profile-repository.service';
import { Router } from '@angular/router';
import { SessionService } from './shared/session.service';
import { UserManagementService } from './modules/onboarding-wizard/user-management.service';
import { SignupUserProfile } from './modules/onboarding-wizard/user-profile-models';
import { ClientProfileService } from './modules/client-profile/client-profile.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as moment from 'moment';

const poolData = {
  UserPoolId: environment.aws_user_pools_id, // Your user pool id here
  ClientId:  environment.aws_user_pools_web_client_id // Your client id here
};

const userPool = new CognitoUserPool(poolData);


@Injectable({
  providedIn: 'root'
})
export class AuthenticationManagementService {

  private authenticatedBehaviourSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);
  cognitoUser: any;
  loggedIn = false;
  public testLogin = "not tested";
  public authenticationEvent$: Observable<boolean> = this.authenticatedBehaviourSubject.asObservable();
  _data: any;
  isLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private sessionStorageService: SessionStorageService,
    private profileservice: ContractorProfileRepositoryService,
    private ClientProfile:ClientProfileService,
    private router: Router,                    
    private sessionService: SessionService,
    private user:UserManagementService,
    private snackBar: MatSnackBar
  ) {
    this.loggedIn = this.sessionStorageService.getItem('user-login-state', false);
  

}

signUp(email: string, password:string) {

  const attributeList:any[] = [];

  return Observable.create((observer:any) => {
      userPool.signUp(email, password, attributeList, [], (err, result:any) => {
          if (err) {
          observer.error(err);
          }

          this.cognitoUser = result.user;
          observer.next(result);
          observer.complete();
      });
  });
}

confirmSignUpLogin(username:string, code:string) {
  return Observable.create((observer:any) => {
      Auth.confirmSignUp(username, code).then(data =>
          observer.next(data))
        .catch(err =>
          observer.error(err));
  });
}

signIn(email: string, password: string): Observable<any> {
  this.setLoadingState(true);

  const authenticationData = {
    Username: email,
    Password: password,
  };

  const authenticationDetails = new AuthenticationDetails(authenticationData);

  const userData = {
    Username: email,
    Pool: userPool,
  };

  const cognitoUser = new CognitoUser(userData);
  return new Observable((observer: any) => {
    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: (result) => {
        this.loggedIn = true;
        this.sessionStorageService.setItem('user-login-state', 'true');
        observer.next(result);
        observer.complete();
        this.handlePostSignInActions(email);
      },
      onFailure: (err) => {
        observer.error(err);
        this.setLoadingState(false);
        this.showErrorMessage(err.message || 'An error occurred during sign in.');
      },
    });
  });
}

handlePostSignInActions(email: string) {
  this.user.retrieveUserProfile(email).pipe(
    switchMap((userProfile: any) => {
      const userType = userProfile.type;
      const profileService = userType === 'CONTRACTOR' ? this.profileservice : this.ClientProfile;

      return forkJoin([
        profileService.loadProfile(email),
        of(userProfile),
      ]).pipe(
        catchError((error) => {
          console.error('Error fetching user profile:', error);
          this.setLoadingState(false);
          this.showErrorMessage('Unable to load user profile. Please try again.');
          return throwError(error);
        }),
        map(([profile, userProfile]) => {
          return { profile, userProfile };
        })
      );
    }),
    catchError((error) => {
      console.error('Error retrieving user profile:', error);
      this.setLoadingState(false);
      this.showErrorMessage('Unable to retrieve user profile. Please try again.');
      return throwError(error);
    })
  ).subscribe(
    ({ profile, userProfile }) => {
      const passwordDate = moment.utc(profile.passwordDate);
      const sixMonthsAgo = moment.utc().subtract(6, 'months');
      if (passwordDate.isValid() && passwordDate.isSameOrAfter(sixMonthsAgo)) {
        console.log('Password is valid, allowing access');
        this.setLoadingState(false);
        this.router.navigateByUrl('/dashboard');
      } else {
        console.log('Navigating to update password page');
        this.setLoadingState(false);
        this.router.navigateByUrl('/main/Update-password');
      }
    },
    (error) => {
      console.error('Error in handlePostSignInActions:', error);
      this.setLoadingState(false);
      this.showErrorMessage('An unexpected error occurred. Please try again.');
    }
  );
}

setLoadingState(state: boolean) {
  this.isLoading.next(state);
}

showErrorMessage(message: string) {
  this.snackBar.open(message, 'Close', {
    duration: 5000,
    horizontalPosition: 'center',
    verticalPosition: 'bottom',
  });
}

  

triggerEvent(state: boolean){
  this.authenticatedBehaviourSubject.next(state);
}

validateEmail(code:string) {
  const user = {
      Username : this.cognitoUser.username,
      Pool : userPool
  };
  return Observable.create((observer:any) => {
      const cognitoUser = new CognitoUser(user);
      cognitoUser.confirmRegistration(code, true, function(err, result) {
          if (err) {
              observer.error(err);
          }
          observer.next(result);
          observer.complete();
      });
  });
}

resendCode() {
  return Auth.resendSignUp(this.cognitoUser.username)
}

resendCodeForUser(username: string){
  return Auth.resendSignUp(username);
}

// used to convert the callback method of authenticatedUser.getSession to a promise
getAccessTokenAsync(): Promise<string>{
  return new Promise((resolve, reject) => {
      var authenticatedUser: CognitoUser = this.getAuthenticatedUser();
      if(authenticatedUser == null) {
          resolve('');
      }
      authenticatedUser.getSession((error:any, session:any) => {
          if(error) {
            resolve('');
          }
          const token = session.getIdToken().getJwtToken();
          resolve(token);
      });
  });
}

getAuthenticatedUser() {
  // gets the current user from the local storage
  return userPool.getCurrentUser() as CognitoUser;
}


getUserRole(): Promise<string | null> {
  return new Promise((resolve) => {
    const authenticatedUser = this.getAuthenticatedUser();
    if (authenticatedUser) {
      authenticatedUser.getUserAttributes((err, attributes) => {
        if (!err && attributes) {
          console.log('User attributes:', attributes);
          const userRoleAttribute = attributes.find(attribute => attribute.getName() === 'custom:role');

          if (userRoleAttribute) {
            console.log('User role attribute:', userRoleAttribute);
            resolve(userRoleAttribute.getValue());
          } else {
            console.log('User role attribute not found');
            resolve(null);
          }
        } else {
          console.log('User is not authenticated or attributes are undefined');
          resolve(null);
        }
      });
    } else {
      console.log('User is not authenticated');
      resolve(null);
    }
  });
}







signOut() {
  this.getAuthenticatedUser().signOut();
  this.cognitoUser = null;
  this.loggedIn = false;
  this.sessionStorageService.clear()
  this.authenticatedBehaviourSubject.next(false);
}

changePassword(oldPassword:string, password:string): Promise<string> {
  let user  = this.getAuthenticatedUser();
  return Auth.changePassword(user, oldPassword, password);
}

forgotPassword(email: string) {
  //
  return Auth.forgotPassword(email);
}

isLoggedIn() {
  return this.loggedIn;
}

forgotPasswordSubmit(email:string, code:string, password:string) {

  // Collect confirmation code and new password, then
  return Auth.forgotPasswordSubmit(email, code, password)

}


deleteUser(): Observable<any> {
  return Observable.create(async (observer: any) => {
    const cognitoUser = userPool.getCurrentUser();
    if (!cognitoUser) {
      observer.error('No user is currently logged in');
    }

    cognitoUser.getSession((error: any, session: any) => {
      if (error) {
        observer.error(error);
      }

      if (!session.isValid()) {
        observer.error('User session is not valid.');
      }

      // observer.next(cognitoUser); //ToDo remove to allow below commented code to execute
      // observer.complete(); //ToDo remove to allow below commented code to execute

      cognitoUser.deleteUser((error: any, result: any) => {
        if (error) {
          observer.error(error);
        } else {
          this.cognitoUser = null;
          this.loggedIn = false;
          this.sessionStorageService.clear();
          this.authenticatedBehaviourSubject.next(false);
          observer.next(result);
          observer.complete();
        }
      });
    });
  });
}

















}


export interface CognitoError {
  code: string;
  message: string;
  name: string;
}





