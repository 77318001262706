<div class="container">
    <div class="card customclass">
        <div class="col-sm-12">
            <button
            mat-stroked-button 
            (click) = "goBack()">
                <mat-icon>arrow_back</mat-icon> Back </button>
        </div>
        <div class="card-body mb-2 align-items-center">
           
            <h3 class="text-black text-center heading "><strong>Our commitment to you is simple and clear<br>Here is how it works! </strong></h3>
            <!-- Introduction content here -->
            <section class="layout mt-4">
                <div>
                    <div class="card-body">
                        <img src="assets/money.png" alt="Icon Image" class="icon-image">
                        <p class="text-lead" style="color:  #017DB5"> Blue 3M Junior </p>       
                        <p>Consultant Rate : R300</p>  
                        <hr>
                    </div>
                </div>
                <div>
                    <div class="card-body">
                        <img src="assets/money.png" alt="Icon Image" class="icon-image">
                        <p class="text-lead" style="color:  #017DB5"> Blue 3M Junior </p>       
                        <p>Blue Commission  : R50 </p>  
                        <hr>
                    </div>
                </div>
                <div>
                    <div class="card-body">
                        <img src="assets/money.png" alt="Icon Image" class="icon-image">
                        <p class="text-lead" style="color:  #017DB5"> Blue 3M Junior </p>       
                        <p> Final Cost  : R350</p>  
                        <hr>
                    </div>
                </div>
              </section>
        </div>
    </div>
</div>

<div class="container m-2">
    <h1 class = "text-white" style="background: linear-gradient(90deg, #40B9F0 0%, rgba(57, 173, 106, 0.9) 100%);">Blue 3 (0-3) Months</h1>
    <div class="row">
      <div class="col-md-4">
          <!-- Content for card 1 in container 1 -->
          
            <div class="card" style="background: white !important;">
                <div class="card-body">
                    <p class="card-title"><strong class="fw-bolder fs-3">Blue 3M</strong></p>
                    <p class="text-lead"style="color:  #017DB5"> Junior</p>        
                    <p>BLUE's rate per hour will never be more than R100,00 per Consultant</p>  
                    <hr>
                    <div class="d-flex justify-content-start"><svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" id="tick" viewBox="0 0 18 18" fill="none">
                        <path d="M5.27594 12.5205L1.87177 9.1164L0 10.9882L4.49225 15.4804L4.4974 15.4752L5.31751 16.2953L18 3.61273L16.0919 1.70459L5.27594 12.5205Z" fill="#F3B007"/>
                        </svg> <p>R 50,00/h</p> 
                    </div>
                <!-- <div class="d-flex justify-content-center mb-3">
                    <button type="button" class="btn btn-outline-dark " >Your current plan</button>
                </div> -->
            </div>
            </div>
      </div>
      <div class="col-md-4">

        <div class="card" style="background: white !important;">
            <div class="card-body">
                <p class="card-title"><strong class="fw-bolder fs-3">Blue 3M</strong></p>
                <p class="text-lead"style="color:  #017DB5"> Intermediate</p>        
                <p>BLUE's rate per hour will never be more than R100,00 per Consultant</p>  
                <hr>
                <div class="d-flex justify-content-start"><svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" id="tick" viewBox="0 0 18 18" fill="none">
                    <path d="M5.27594 12.5205L1.87177 9.1164L0 10.9882L4.49225 15.4804L4.4974 15.4752L5.31751 16.2953L18 3.61273L16.0919 1.70459L5.27594 12.5205Z" fill="#F3B007"/>
                    </svg> <p>R 75,00/h</p> 
                </div>
            <!-- <div class="d-flex justify-content-center mb-3">
                <button type="button" class="btn btn-outline-dark " >Your current plan</button>
            </div> -->
            </div>
        </div>
        </div>
    
      <div class="col-md-4">
        <div class="card" style="background: white !important;">
            <div class="card-body">
                <p class="card-title"><strong class="fw-bolder fs-3">Blue 3M</strong></p>
                <p class="text-lead"style="color:  #017DB5"> Senior</p>        
                <p>BLUE's rate per hour will never be more than R100,00 per Consultant</p>  
                <hr>
                <div class="d-flex justify-content-start"><svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" id="tick" viewBox="0 0 18 18" fill="none">
                    <path d="M5.27594 12.5205L1.87177 9.1164L0 10.9882L4.49225 15.4804L4.4974 15.4752L5.31751 16.2953L18 3.61273L16.0919 1.70459L5.27594 12.5205Z" fill="#F3B007"/>
                    </svg> <p>R 100,00/h</p> 
                </div>
            </div>
        </div>
    </div>
    </div>
</div>
  
<div class="container mt-4">
    <h1 class = "text-white" style="background: linear-gradient(90deg, #40B9F0 0%, rgba(201, 230, 73, 0.9) 100%);">Blue 6 (3-6) Months</h1>
    <div class="row">
      <div class="col-md-4">
        <div class="card" style="background: white !important;">
            <div class="card-body">
                <p class="card-title"><strong class="fw-bolder fs-3">Blue 6M</strong></p>
                <p class="text-lead"style="color:  #017DB5"> Senior</p>        
                <p>BLUE's rate per hour will never be more than R100,00 per Consultant</p>  
                <hr>
                <div class="d-flex justify-content-start"><svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" id="tick" viewBox="0 0 18 18" fill="none">
                    <path d="M5.27594 12.5205L1.87177 9.1164L0 10.9882L4.49225 15.4804L4.4974 15.4752L5.31751 16.2953L18 3.61273L16.0919 1.70459L5.27594 12.5205Z" fill="#F3B007"/>
                    </svg> <p>R 40,00/h</p> 
                </div>
            </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="card" style="background: white !important;">
            <div class="card-body">
                <p class="card-title"><strong class="fw-bolder fs-3">Blue 6M</strong></p>
                <p class="text-lead"style="color:  #017DB5"> Senior</p>        
                <p>BLUE's rate per hour will never be more than R100,00 per Consultant</p>  
                <hr>
                <div class="d-flex justify-content-start"><svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" id="tick" viewBox="0 0 18 18" fill="none">
                    <path d="M5.27594 12.5205L1.87177 9.1164L0 10.9882L4.49225 15.4804L4.4974 15.4752L5.31751 16.2953L18 3.61273L16.0919 1.70459L5.27594 12.5205Z" fill="#F3B007"/>
                    </svg> <p>R 65,00/h</p> 
                </div>
            </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="card" style="background: white !important;">
            <div class="card-body">
                <p class="card-title"><strong class="fw-bolder fs-3">Blue 6M</strong></p>
                <p class="text-lead"style="color:  #017DB5"> Senior</p>        
                <p>BLUE's rate per hour will never be more than R100,00 per Consultant</p>  
                <hr>
                <div class="d-flex justify-content-start"><svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" id="tick" viewBox="0 0 18 18" fill="none">
                    <path d="M5.27594 12.5205L1.87177 9.1164L0 10.9882L4.49225 15.4804L4.4974 15.4752L5.31751 16.2953L18 3.61273L16.0919 1.70459L5.27594 12.5205Z" fill="#F3B007"/>
                    </svg> <p>R 90,00/h</p> 
                </div>
            </div>
        </div>
      </div>
    </div>
  </div>
  
<div class="container mt-4">
    <h1 class = "text-white" style="background: linear-gradient(90deg, #40B9F0 0%, rgba(177, 155, 32, 0.9) 100%);">Blue 9 (6-9) Months</h1>
    <div class="row">
      <div class="col-md-4">
        <div class="card" style="background: white !important;">
            <div class="card-body">
                <p class="card-title"><strong class="fw-bolder fs-3">Blue 9M</strong></p>
                <p class="text-lead"style="color:  #017DB5"> Senior</p>        
                <p>BLUE's rate per hour will never be more than R100,00 per Consultant</p>  
                <hr>
                <div class="d-flex justify-content-start"><svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" id="tick" viewBox="0 0 18 18" fill="none">
                    <path d="M5.27594 12.5205L1.87177 9.1164L0 10.9882L4.49225 15.4804L4.4974 15.4752L5.31751 16.2953L18 3.61273L16.0919 1.70459L5.27594 12.5205Z" fill="#F3B007"/>
                    </svg> <p>R 35,00/h</p> 
                </div>
            </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="card" style="background: white !important;">
            <div class="card-body">
                <p class="card-title"><strong class="fw-bolder fs-3">Blue 9M</strong></p>
                <p class="text-lead"style="color:  #017DB5"> Senior</p>        
                <p>BLUE's rate per hour will never be more than R100,00 per Consultant</p>  
                <hr>
                <div class="d-flex justify-content-start"><svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" id="tick" viewBox="0 0 18 18" fill="none">
                    <path d="M5.27594 12.5205L1.87177 9.1164L0 10.9882L4.49225 15.4804L4.4974 15.4752L5.31751 16.2953L18 3.61273L16.0919 1.70459L5.27594 12.5205Z" fill="#F3B007"/>
                    </svg> <p>R 60,00/h</p> 
                </div>
            </div>
        </div>
        </div>
      <div class="col-md-4">
        <div class="card" style="background: white !important;">
            <div class="card-body">
                <p class="card-title"><strong class="fw-bolder fs-3">Blue 9M</strong></p>
                <p class="text-lead"style="color:  #017DB5"> Senior</p>        
                <p>BLUE's rate per hour will never be more than R100,00 per Consultant</p>  
                <hr>
                <div class="d-flex justify-content-start"><svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" id="tick" viewBox="0 0 18 18" fill="none">
                    <path d="M5.27594 12.5205L1.87177 9.1164L0 10.9882L4.49225 15.4804L4.4974 15.4752L5.31751 16.2953L18 3.61273L16.0919 1.70459L5.27594 12.5205Z" fill="#F3B007"/>
                    </svg> <p>R 80,00/h</p> 
                </div>
            </div>
        </div>
      </div>
    </div>
</div>


<div class="container mt-2 ">
    <h1 class = "text-white" style="background: linear-gradient(90deg, #40B9F0 0%, rgba(243, 55, 55, 0.9) 100%);">Blue 12 Months+ </h1>
    <div class="row">
      <div class="col-md-4">
        <div class="card" style="background: white !important;">
            <div class="card-body">
                <p class="card-title"><strong class="fw-bolder fs-3">Blue 12M+</strong></p>
                <p class="text-lead"style="color:  #017DB5"> Junior</p>        
                <p>BLUE's rate per hour will never be more than R100,00 per Consultant</p>  
                <hr>
                <div class="d-flex justify-content-start"><svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" id="tick" viewBox="0 0 18 18" fill="none">
                    <path d="M5.27594 12.5205L1.87177 9.1164L0 10.9882L4.49225 15.4804L4.4974 15.4752L5.31751 16.2953L18 3.61273L16.0919 1.70459L5.27594 12.5205Z" fill="#F3B007"/>
                    </svg> <p>R 30,00/h</p> 
                </div>
            </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="card" style="background: white !important;">
            <div class="card-body">
                <p class="card-title"><strong class="fw-bolder fs-3">Blue 12M+</strong></p>
                <p class="text-lead"style="color:  #017DB5"> Intermediate </p>        
                <p>BLUE's rate per hour will never be more than R100,00 per Consultant</p>  
                <hr>
                <div class="d-flex justify-content-start"><svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" id="tick" viewBox="0 0 18 18" fill="none">
                    <path d="M5.27594 12.5205L1.87177 9.1164L0 10.9882L4.49225 15.4804L4.4974 15.4752L5.31751 16.2953L18 3.61273L16.0919 1.70459L5.27594 12.5205Z" fill="#F3B007"/>
                    </svg> <p>R 50,00/h</p> 
                </div>
            </div>
        </div>
        </div>
      <div class="col-md-4">
        <div class="card" style="background: white !important;">
            <div class="card-body">
                <p class="card-title"><strong class="fw-bolder fs-3">Blue 12M+</strong></p>
                <p class="text-lead"style="color:  #017DB5"> Senior</p>        
                <p>BLUE's rate per hour will never be more than R100,00 per Consultant</p>  
                <hr>
                <div class="d-flex justify-content-start"><svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" id="tick" viewBox="0 0 18 18" fill="none">
                    <path d="M5.27594 12.5205L1.87177 9.1164L0 10.9882L4.49225 15.4804L4.4974 15.4752L5.31751 16.2953L18 3.61273L16.0919 1.70459L5.27594 12.5205Z" fill="#F3B007"/>
                    </svg> <p>R 70,00/h</p> 
                </div>
            </div>
        </div>
      </div>
    </div>
</div>

  