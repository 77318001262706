<div class="sidenav" [ngClass]="collapsed ? 'sidenav-collapsed': ''">
    <div class="logo-container">
        <a class="btn " (click)="toggleCollapse()" >
            <img src="assets/logo-landing-min.png" class="logo" alt="Blueapp.blue">
          </a>

        <button class="btn-close" @rotate *ngIf="collapsed" (click)="closeSidenav()">
            <i class="bi bi-x-lg close-icon"></i>
        </button>
    </div>
    <ul class="sidenav-nav">
        <li class="sidenav-nav-item" *ngIf="profileComplete">
            <a class="sidenav-nav-link" [routerLink]="['/main/client-profile']" routerLinkActive="active"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{exact: true}"
            >
            <i class="bi bi-person-fill sidenav-link-icon"></i>
                <span class="sidenav-link-text" @fadeInOut *ngIf="collapsed">
                    Profile
                </span>
            </a>
        </li>
        <li class="sidenav-nav-item" *ngIf="profileComplete">
            <a class="sidenav-nav-link" [routerLink]="['/main/dashboardclient']" routerLinkActive="active"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{exact: true}"
            >
            <i class="bi bi-bar-chart-fill sidenav-link-icon"></i>
                <span class="sidenav-link-text" @fadeInOut *ngIf="collapsed">
                    Dashboard
                </span>
            </a>
        </li>
        <li class="sidenav-nav-item" *ngIf="profileComplete">
            <a class="sidenav-nav-link" [routerLink]="['/main/user-roles']" routerLinkActive="active"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{exact: true}"
            >
            <i class="bi bi-people-fill sidenav-link-icon"></i>
                <span class="sidenav-link-text" @fadeInOut *ngIf="collapsed">
                    User roles
                </span>
            </a>
        </li>




        <li class="sidenav-nav-item" *ngIf="profileComplete">
            <a class="sidenav-nav-link" [routerLink]="['/main/job-profiles']" routerLinkActive="active"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{exact: true}"
            >
            <i class="bi bi-briefcase-fill sidenav-link-icon"></i>
                <span class="sidenav-link-text" @fadeInOut *ngIf="collapsed">
                   Blue Desk
                </span>
            </a>
        </li>

        <li class="sidenav-nav-item" *ngIf="profileComplete">
            <a class="sidenav-nav-link" [routerLink]="['/main/dashboard']" routerLinkActive="active"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{exact: true}"
            >
            <i class="bi bi-search sidenav-link-icon"></i>
                <span class="sidenav-link-text" @fadeInOut *ngIf="collapsed">
                    Find a talent
                </span>
            </a>
        </li>
        <li class="sidenav-nav-item" *ngIf="profileComplete">
            <a class="sidenav-nav-link" [routerLink]="['/main/help']" routerLinkActive="active"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{exact: true}"
            >
            <i class="bi bi-question-circle-fill sidenav-link-icon"></i>
                <span class="sidenav-link-text" @fadeInOut *ngIf="collapsed">
                    Help
                </span>
            </a>
        </li>
        <li class="sidenav-nav-item" *ngIf="profileComplete">
            <a class="sidenav-nav-link" [routerLink]="['/main/terms-and-conditions']" routerLinkActive="active"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{exact: true}"
            >
            <i class="bi bi-file-check-fill sidenav-link-icon"></i>
                <span class="sidenav-link-text" @fadeInOut *ngIf="collapsed">
                    Terms and Conditions
                </span>
            </a>
        </li>

        <li class="sidenav-nav-item" *ngIf="profileComplete">
            <a class="sidenav-nav-link" [routerLink]="['/main/timesheets']" routerLinkActive="active"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{exact: true}"
            >
            <i class="bi bi-calendar2-check-fill sidenav-link-icon"></i>
                <span class="sidenav-link-text" @fadeInOut *ngIf="collapsed">
                    Timesheets
                </span>
            </a>
        </li>
        <li class="sidenav-nav-item" >
            <a class="sidenav-nav-link" [routerLink]="['/main/payments']" routerLinkActive="active"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{exact: true}"
            >
            <i class="bi bi-cash sidenav-link-icon"></i>
                <span class="sidenav-link-text" @fadeInOut *ngIf="collapsed">
                    Subscriptions
                </span>
            </a>
        </li>
        <li class="sidenav-nav-item" >
            <a class="sidenav-nav-link" (click)="onClickLogout()"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{exact: true}"
            >
            <i class="bi bi-box-arrow-right sidenav-link-icon"></i>
                <span class="sidenav-link-text" @fadeInOut *ngIf="collapsed">
                    Logout
                </span>
            </a>
        </li>

   </ul>




        <!-- <div class="sidecardcontainer" (click)="onClickLogout()">
            <div class="d-flex sidecard my-1">
                <mat-icon mat-list-icon>power_settings_new</mat-icon>
                <div class="nav-mat-line mx-2" mat-line> Logout</div>
                </div>
          </div> -->

    <!-- <div class="sidecardcontainer">
        <div class="d-flex sidecard my-1" *ngIf="profileComplete" (click)="autoCloseMobile()"  [routerLink]="['/main/timesheets']" routerLinkActive="active">
          <mat-icon mat-list-icon>access_time</mat-icon>
          <div class="nav-mat-line mx-2" mat-line> Timesheets</div>
            </div>
      </div>

    <div class="sidenav-nav" *ngIf="profileComplete"  [routerLink]="['/main/timesheets']" routerLinkActive="active">
        <div class="sidenav-nav-item" >
        <i class="bi bi-calendar2-check-fill"></i>
        </div>
    </div> -->
    <!-- <div class="sidenav-nav-item" (click)="onClickLogout()"> -->
            <!-- <div class="d-flex sidecard my-1">
                <i class="bi bi-box-arrow-right"></i>
                <div class="nav-mat-line mx-2" mat-line> Logout</div>
                </div>
          </div>

</div> -->
