import { Injectable, Inject } from '@angular/core';
import { BASE_URL } from 'src/app/app.provider';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UserProfile } from 'src/app/shared/user-profile-models';
import { Observable, catchError, forkJoin, from } from 'rxjs';
import { ClientProfile } from '../client-profile/client-profile-models';

@Injectable({
  providedIn: 'root',
})
export class ContractorProfileRepositoryService {
    
    constructor(@Inject(BASE_URL) protected baseUrl: string, private http: HttpClient) { }

  loadProfile(email: string): Observable<UserProfile> {
    return this.http.get<UserProfile>(this.baseUrl + '/profile/contractor', {
      params: {
        emailAddress: email,
      },
    });
  }

  createContractorProfile(profile: any): Observable<any> {
    let body = JSON.stringify(profile);
    return this.http.post(this.baseUrl + '/profile/contractor', body);
  }

  updateContractorProfile(profile: any): Observable<any> {
    let body = JSON.stringify(profile);
    return this.http.put(this.baseUrl + '/profile/contractor', body);
  }

  updateUserProfile(profile: any, emailAddress: any): Observable<any> {
    let options = { params: { emailAddress: emailAddress } };
    return this.http.put(
      this.baseUrl + '/registration/user',
      JSON.stringify(profile),
      options
    );
  }

  deactivateAccount(profile: any, emailAddress: string): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const deactivateContractorUrl =
      this.baseUrl + '/profile/contractor-deactivate-account';

    const payload = { emailAddress: emailAddress };

    let options = { params: { emailAddress: emailAddress } };

    const deleteRequest = from(
      this.http.put(
        this.baseUrl + '/registration/user',
        JSON.stringify(profile),
        options
      )
    );

    const putRequest = from(
      this.http
        .put(deactivateContractorUrl, JSON.stringify(payload), {
          headers,
        })
        .toPromise()
    );

    return forkJoin([deleteRequest, putRequest]).pipe(
      catchError((err) => {
        console.log('Error: ', err);
        throw err;
      })
    );
  }
}
