 import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { SessionService } from '../shared/session.service';
import { Router } from '@angular/router';

export interface PeriodicElement {
  name: string;
  position: string;
  weight: string;
  symbol: string;
  last: string
}

@Component({
  selector: 'app-fee-structure',
  templateUrl: './fee-structure.component.html',
  styleUrls: ['./fee-structure.component.css'],
  encapsulation: ViewEncapsulation.None
})


export class FeeStructureComponent implements OnInit {
  currentStep = 1;


  constructor(     private sessionClass: SessionService,private router:Router) { }

  ngOnInit() {
  }


  setStep(val: number): void {
    this.currentStep = val;
  }
  goBack() {
    this.sessionClass.clearCurrentCandidate();
    this.router.navigateByUrl('main/payments');
}
}
