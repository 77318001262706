<h2 mat-dialog-title></h2>
<mat-dialog-content (click)="onOutsideClick($event)" class="modal-overlay">
  <div class="modal-content" (click)="$event.stopPropagation()">
    <form class="card-body pt-0 pl-0 pr-0 m-0 login-form align-items-center">
      <div class="row">
        <div class="col-sm-12">
          <div class="error-alert" [class.active]="hasErrors">{{errorMessage}}</div>
        </div>
      </div>
      <div class="row center">
        <img class="image" src="assets/BLUE-Logo-Large-removebg-preview 2.png" alt="Blue logo" srcset="">
      </div>
      <div class="row small">
        <!-- email -->
        <mat-form-field class="col-sm-12 small" appearance="fill">
          <mat-label>Email</mat-label>
          <input required pattern="^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[a-z]{2,4}$" [formControl]="email" matInput placeholder="mail@example.com" type="email" (blur)="emailAddressOnBlur()">
          <mat-error *ngIf="email.invalid">Your email address is required</mat-error>
        </mat-form-field>
      </div>
      <div class="row small">
        <!-- password -->
        <mat-form-field class="col-sm-12 small" appearance="fill">
          <mat-label>Password</mat-label>
          <input required pattern="((?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,})" [formControl]="password" matInput placeholder="********" [type]="passwordType">
          <mat-icon matSuffix (click)="toggleVisibility()" class="password-visibility-toggle-button" *ngIf="!passwordVisible">visibility_off</mat-icon>
          <mat-icon matSuffix (click)="toggleVisibility()" class="password-visibility-toggle-button" *ngIf="passwordVisible">visibility</mat-icon>
          <mat-error *ngIf="password.invalid">Password must contain 1 uppercase letter, 1 lowercase letter, 1 number, 1 special character, and should be at least 8 characters long</mat-error>
        </mat-form-field>
      </div>
      <div class="row small mt-3 ml-4 check">
        <!-- Additional content as needed -->
      </div>
      <div class="col-sm-12 small mt-3 ml-4">
        <button (click)="login()" mat-button matTooltip="Sign In" style="background-color: #017DB5; color: white; margin-left: 40%;" class="customButton">Sign-in</button>
      </div>
      <div *ngIf="checkbox.invalid && !buttonDisabled" class="col-sm-12 small mt-3 ml-4">
        Please accept the privacy policy to proceed.
      </div>
      <div class="col-sm-12 small mt-3 ml-4">
        <a class='btn btn-link p-0 text-black small' style="margin-left: 35%;" routerLink="/auth/forgot-password" routerLinkActive="active">Forgot password?</a>
      </div>
    </form>
  </div>
</mat-dialog-content>