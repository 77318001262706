import { Component, OnInit, Inject } from '@angular/core';
import { SessionService } from 'src/app/shared/session.service';
import { DataService } from 'src/app/shared/data.service';
import { AuthenticationManagementService } from 'src/app/authentication-management.service';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { BASE_URL } from 'src/app/app.provider';
import { ContractorProfile } from 'src/app/shared/user-profile-models';
import { Profiles } from 'src/app/shared/job-profile-models';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DashboardService } from 'src/app/modules/dashboard/dashboard.service';
import { WatsonAIService } from 'src/app/Machingengine.service';
import { minify } from 'terser';


@Component({
    selector: 'app-job-profiles-view',
    templateUrl: './job-profiles-view.component.html',
    styleUrls: ['./job-profiles-view.component.css'],


})
export class JobProfilesViewComponent implements OnInit {

    selected = "1";


    jobProfiles: Profiles[] = [];

    allJobProfiles: Profiles[] = [];

    jobProfileUrl = this.baseUrl + '/profile/job';
    jobprofileUrlId = this.baseUrl + '/profile/job-profile-details';

    activeJobProfiles: Profiles[] = [];
    expiredJobProfiles: Profiles[] = [];
    closedWonJobProfiles: Profiles[] = [];
    isShown = false;
    loading = true;
    closedWon: boolean;
    isHidden = true;
    duplicate: boolean;
    username = '';
    dateLogged: Date; 
    page: number = 1;
    count: number = 0;
    size: number = 7;
    sizes: any = [5, 10, 15, 20];

    jobStep = 1;
    thumbLabel = false;

    private jobProfileEmailUrl = this.baseUrl + '/profile/job?emailAddress=';
    private jobProfileMatchUrl = this.baseUrl + '/profile/match-contractors?identifier=';
    private contrJobProfile = this.baseUrl + '/profile/match-jobs?emailAddress=';
    private allprofileslist = this.baseUrl + '/profile/all-contractors';

    constructor(@Inject(BASE_URL) protected baseUrl: string, private http: HttpClient, private custAuth: AuthenticationManagementService, private sessionClass: SessionService, private snackBar: MatSnackBar, private router: Router, private dataClass: DataService, private Watson: WatsonAIService,private dashboardService: DashboardService) { this. dateLogged = new Date();}

    ngOnInit() {







        this.snackBar.open('Searching...', '', {
            duration: 3000,
        });

        this.username = this.sessionClass.getUserType();

        if (this.sessionClass.getUserType() == 'client') {
            this.getJobProfiles();
            this.dataClass.setData();
        } else {
            this.router.navigateByUrl('/main/job-profiles/contractor-job-profiles');
        }

        


    }


    formatLabel(value: number): string {
        if (value >= 1000) {
            return Math.round(value / 1000) + 'k';
        }

        return `${value}`;
    }
    scrollToBottom(event: Event): void {
        event.preventDefault(); // Prevent default behavior of the anchor tag
        window.scrollTo({
          top: document.documentElement.scrollHeight,
          behavior: 'smooth'
        });
      }
    Ratefilter(data: any) {
        const value = data?.target?.value
        const results: any[] = this.activeJobProfiles.filter((a: any) => a.ratePerHour <= value);
        this.activeJobProfiles = [];
        this.activeJobProfiles.push(...results)
    }
    onClickAddProfile() {
        this.router.navigateByUrl('/main/job-profiles/add-job-profile').catch((error) => {
            console.error(error);
        });
    }


    checkDate(profileDate: string | number | Date): boolean {
        let profDate = new Date(profileDate);
        let today = new Date();


        if (profDate < today) {
            return false;
        } else {
            return true;
        }
    }
    // onTableChange(event: any) {
    //     this.page = event;
    //     this.getJobProfiles();
    // }
    // onTableSizeChange(event: any): void {
    //     this.size = event.target.value;
    //     this.page = 1;
    //     this.getJobProfiles();
    // }
    changeColor(e) {
        var color = e.value;
        e.style.color = color;
    }
    // contractors getting their relevant job profiles
    getContractorJobProfiles() {
        this.http.get<Profiles[]>(this.contrJobProfile + this.sessionClass.getUsername()).subscribe(response => {
            this.jobProfiles = response;
            this.snackBar.open("Success", '', {
                duration: 3000,
            });
            this.isShown = !this.isShown;
            this.loading = false;
        }, error => {
            this.isShown = !this.isShown;
            this.loading = false;
        });
    }

    getJobProfiles() {
        this.sessionClass.clearProfiles();
        this.http.get<Profiles[]>(this.jobProfileEmailUrl + this.sessionClass.getUsername()).subscribe(response => {
            this.jobProfiles = response;
            this.splittingJobProfile(this.jobProfiles);
            this.snackBar.open("Success", '', {
                duration: 3000,
            });
            this.isShown = !this.isShown;
            this.loading = false;
            
        }, error => {
            this.snackBar.open(error.message, '', {
                duration: 8000,
            });
            this.isShown = !this.isShown;
            this.loading = false;
        });
    }

    editProfile(jobProfile: any) {
        // job.duplicate=true;
        this.sessionClass.setCurrentJobProfile(jobProfile);
        this.router.navigateByUrl('/main/job-profiles/add-job-profile').catch((error) => {
            console.error(error);
        });
    }

    duplicateJob(job: any) {
        job.duplicate = true;
        this.http.post(this.jobProfileUrl, JSON.stringify(job)).subscribe((response: any) => {
            this.snackBar.open(response, '', {
                duration: 2000,
            });
            this.router.navigateByUrl('/main', { skipLocationChange: false }).then(() => {
                this.router.navigateByUrl('/main/job-profiles')
            });
        }, error => {

        })
    }

    closeJob(job: Profiles, close: boolean, won: boolean) {
        

        job.closed = close ? true : false;
        job.closedplaced = won ? true : false;
        this.http.put(this.jobProfileUrl, JSON.stringify(job)).subscribe((response: any) => {
            this.snackBar.open(response, '', {
                duration: 2000,
            });
            this.router.navigateByUrl('/main', { skipLocationChange: false }).then(() => {
                this.router.navigateByUrl('/main/job-profiles')
            });
        }, error => {

        })
    }


    matchedProfiles(identifier: string) {
        this.loading = true;
        this.snackBar.open('Searching for matching candidates', '', {
            duration: 3000,
        });
        this.http.get<ContractorProfile[]>(this.jobProfileMatchUrl + identifier).subscribe(response => {
            
            let message = response.length > 0 ? response.length + " Matches found" : "No matches found"
            this.snackBar.open(message, '', {
                duration: 3000,
            });
            this.sessionClass.setCurrentJobDetails(identifier);
            this.sessionClass.setJobProfileMatch(response);
            this.router.navigateByUrl('/main/job-profiles/match-job-profile');
        }, error => {
            this.snackBar.open(error.message, '', {
                duration: 8000,
            });
        });
    }

    async AImatch(identifier: string) {
        this.loading = true;
        this.snackBar.open('Searching for matching candidates', '', {
          duration: 3000,
        });
      
        try {
          // Await the Job promise to get the job specifications
          const Job = await this.dashboardService.getJobProfileDetails(identifier).toPromise();
          console.log('Job Profile:', Job);
      
          // Subscribe to the Observable to get the candidate profiles
          this.http.get(this.allprofileslist).subscribe(
            async (response: any) => {
              const Candidateprofiles = response as any[]; // Explicitly cast response to an array
              if (Job && Array.isArray(Candidateprofiles) && Candidateprofiles.length > 0) {
                const jobSpecificationsJSON = JSON.stringify(Job);
                console.log('Job Specifications:', jobSpecificationsJSON);
      
                // Get only the first candidate profile
                const firstProfileJSON = JSON.stringify([Candidateprofiles[0]]);
      
                try {
                  const response = await this.Watson.sendPrompt(
                    jobSpecificationsJSON,
                    firstProfileJSON
                  ).toPromise();
      
                  console.log('Response from Watson AI service:', response);
      
                  if (response) {
                    // Store the response in local storage
                    localStorage.setItem('watsonResponse_firstProfile', JSON.stringify(response));
      
                    // Handle the response from Watson AI service
                    const matchedProfiles = response.data; // Assuming the response contains matched profiles
                    this.sessionClass.setCurrentJobDetails(identifier);
                    this.sessionClass.setJobProfileMatch(matchedProfiles);
                    this.router.navigateByUrl('/main/job-profiles/match-job-profile');
                  } else {
                    console.error('No response data from Watson AI service');
                  }
                } catch (error) {
                  this.snackBar.open(`Error processing profile: ${error}`, '', {
                    duration: 8000,
                  });
                }
              } else {
                this.snackBar.open('Invalid job specifications or candidate profiles', '', {
                  duration: 8000,
                });
              }
            },
            error => {
              this.snackBar.open('Error fetching candidate profiles', '', {
                duration: 8000,
              });
            }
          );
        } catch (error) {
          this.snackBar.open('Error fetching job specifications', '', {
            duration: 8000,
          });
        } finally {
          this.loading = false;
        }
      }
      
      
      

      splittingJobProfile(jobProfiles: Profiles[]): void {
        let today = new Date();
    
        const expiredJobs = jobProfiles.filter(res => res.closed === true || new Date(res.closingDate) < today && !res.closedplaced);
        const activeJobs = jobProfiles.filter(res => !res.closed && new Date(res.closingDate) >= today && !res.closedplaced);
        const closedWon = jobProfiles.filter(res => res.closedplaced);
    
        //console.log('Before sorting:', activeJobs.map(job => ({ title: job.title, createdDate: job.createdDate })));
    
        // Sort activeJobs by createdDate, most recent first
        const sortedActiveJobs = activeJobs.sort((a, b) => {
            const dateA = new Date(a.createdDate).getTime();
            const dateB = new Date(b.createdDate).getTime();
            //console.log(`Comparing: ${a.title} (${a.createdDate}) vs ${b.title} (${b.createdDate})`);
            return dateB - dateA;
        });

        const sortedExpiredJobs = expiredJobs.sort((a, b) => {
            const dateA = new Date(a.createdDate).getTime();
            const dateB = new Date(b.createdDate).getTime();
            //console.log(`Comparing: ${a.title} (${a.createdDate}) vs ${b.title} (${b.createdDate})`);
            return dateB - dateA;
        });
    
        //console.log('After sorting:', sortedActiveJobs.map(job => ({ title: job.title, createdDate: job.createdDate })));
    
        this.expiredJobProfiles = sortedExpiredJobs;
        this.activeJobProfiles = sortedActiveJobs;
        this.closedWonJobProfiles = closedWon;
    }
    // checkClosedWon(){
    //     this.closedWon=true;
    // }
    setStep(val: number): void {
        this.jobStep = val;
    }

    ratePerHour() {
        this.router.navigateByUrl('/main/fees');

    }






    /*Delete(identifier: string) {
        // Logic to delete the job profile with the given identifier
        // Implement the desired functionality here
        
    }*/
    Delete(identifier: string) {
        this.http.delete(this.baseUrl + '/profile/job/' + identifier).subscribe(
            () => {
                
                // Perform any additional actions after successful deletion
                window.location.reload();
            },

            (error) => {
                console.error('Error deleting job profile:', error);
                // Handle the error appropriately
            }
        );
    }

}
