import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../environments/environment';

export interface JobDescriptionRequest {
  industry: string;
  job_title: string;
  skills: string;
  years_experience: string;
}

export interface WatsonResponse {
  model_id: string;
  created_at: string;
  results: Array<{
    generated_text: string;
    generated_token_count: number;
    input_token_count: number;
    stop_reason: string;
  }>;
  system: {
    warnings: Array<{
      message: string;
      id: string;
      more_info: string;
    }>;
  };
}

export interface TokenResponse {
  access_token: string;
  token_type: string;
  expires_in: number;
}

@Injectable({
  providedIn: 'root'
})
export class WatsonAIServiceJobDescription {
  private backendUrl = environment.baseUrl;

  constructor(private http: HttpClient) { }

  public generateJobDescription(request: JobDescriptionRequest): Observable<any> {
    console.log('Sending job description request to backend:', request);

    const requestBody = {
      parameters: {
        prompt_variables: request
      }
    };

    return this.http.post(`${this.backendUrl}/profile/job-description`, requestBody, {
      responseType: 'text'
    }).pipe(
      map(response => {
        console.log("Watson API Response:", response);
        return JSON.parse(response);
      }),
      catchError(error => {
        console.error('Error generating job description:', error);
        return throwError('Failed to generate job description');
      })
    );
  }
}