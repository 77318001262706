import { Component, OnInit, OnDestroy, ViewChild, HostListener, AfterViewInit, SimpleChanges, OnChanges } from '@angular/core';
import { PwaService, PwaEvent } from './shared/pwa.service';
import { Subscription } from 'rxjs';
import { ProfilePictureComponent } from './shared/profile-picture/profile-picture.component';
import { SessionService } from './shared/session.service';
import { AuthenticationManagementService } from './authentication-management.service';
import { Router, NavigationEnd } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { SessionStorageService } from './shared/session-storage.service';
import { environment } from 'src/environments/environment';
import { GoogleAnalyticsService } from './google-analytics.service';
import { PayWizardService } from './modules/payments/pay-wizard.service';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { LogoutComponent } from './shared/logout/logout.component';
import { MatSidenav } from '@angular/material/sidenav';
import { SwPush } from '@angular/service-worker';
import { ClientProfileService } from './modules/client-profile/client-profile.service';
import { animate, keyframes, style, transition, trigger } from '@angular/animations';
import { FormControl, Validators } from '@angular/forms';
import { WizardService } from './modules/onboarding-wizard/wizard.service';
import { AppNotification, NotificationService } from './notification.service';
import { NotificationDetailsComponent } from './notification-details/notification-details.component';
import { LoginModalComponent } from './app/shared/login-modal/login-modal.component';
import { Location } from '@angular/common';
import { ContractorOnboardingComponent } from './contractor-onboarding/contractor-onboarding.component';



@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css'],

})
export class AppComponent implements OnInit, OnDestroy, AfterViewInit {


    @ViewChild('sidenav') sidenav!: MatSidenav;

    reason = '';
    isCollapsed =true;
    title: any;
    alertVisible: boolean = false;
    isOffline: boolean = true;
    pwaEventSubscription!: Subscription;
    profilePictureSubscription!: Subscription;
    authenticationEventSubscription!: Subscription;
    routerSubscription!: Subscription;
    subscriptionFeeEventSubscription!: Subscription;
    clientUsers!: Subscription;
    username = '';
    userType = '';
    headerName = 'BLUE';
    closeButtonHidden = true;
    showContactsPopup = false;
    sideBarOpen = false;
    isOverlayVisible = false;
    subscriptionFeeOutstanding = false;
    showToolbar = true;
    showFooter = true;
    onlineText: string = "Consultants Online";
    alternateText: string = "Clients Online";
    isAlternate: boolean = false;
    isSmallScreen: boolean = false;
    isLargerScreen: boolean = true;
    isNavOpen: boolean = false; // Declare isNavOpen property
    isMobile: boolean = false;
    isAdmin: boolean = false;
    notification: AppNotification[] = [];
    showNotificationDropdown: boolean = false;
    unreadNotificationsCount: number = 0;
    notifications: AppNotification[] = [];
    public showNotifications = false;

    @ViewChild(ProfilePictureComponent, { static: false }) profilePictureView!: ProfilePictureComponent;
    filteredNotifications: any;

    constructor(private observer: BreakpointObserver,private clientProfileService: ClientProfileService, private snackBar: MatSnackBar, private pwaService: PwaService, private sessionService: SessionService, private authManagementService: AuthenticationManagementService, private router: Router, private sessionStorage: SessionStorageService, private googleAnalytics: GoogleAnalyticsService, private payWizardService: PayWizardService, private dialog: MatDialog, private wizardService :WizardService  ) {
    }

    scrollToSection(sectionId: string) {
    if (sectionId === 'Contact') {
      // Navigate to the footer section
      const element = document.getElementById('footer');
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    } else {
      // Scroll to other sections as needed
      const element = document.getElementById(sectionId);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }
  }


    ngOnInit(): void {        
        
        
        this.authenticationEventSubscription = this.authManagementService.authenticationEvent$.subscribe(isAuthenticated => {            
            if (isAuthenticated) {
                this.username = this.sessionService.getUsername();
                this.userType = this.sessionService.getUserType();
            }

            this.googleAnalytics.emitEvent(
                'auth-status-change',
                'auth-events',
                isAuthenticated ? 'logged in' : 'logged out',
                'auth-status-change',
                isAuthenticated ? 1 : 0
            );
        });

        this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                this.googleAnalytics.emitPageViewEvent(event.urlAfterRedirects);
            }
        });
        this.subscriptionFeeEventSubscription = this.sessionService.subscrFeeEvent$.subscribe(areSubscFeeDue => {
            if (areSubscFeeDue) {
                this.subscriptionFeeOutstanding = true;
            } else {
                this.subscriptionFeeOutstanding = false;
            }
        });
        this.username = this.sessionService.getUsername();
        this.userType = this.sessionService.getUserType();

        this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                if (event.url === '/landing' || event.urlAfterRedirects === '/landing.html') {
                    this.showToolbar = true;
                    this.showFooter = true;
                } else {
                    this.showToolbar = false;
                    this.showFooter = false;
                }
            }
        });


        setInterval(() => {
            this.updateOnlineText();
        }, 8000); // Change text every 8 seconds

        // this.breakpointObserver.observe([Breakpoints.Small, Breakpoints.Handset]).subscribe(result => {
        //     this.isLargerScreen = !result.matches;
        // });
        this.observer.observe(['(max-width: 800px)']).subscribe((screenSize) => {
            if(screenSize.matches){
              this.isMobile = true;
            } else {
              this.isMobile = false;
            }
          });
    }





    autoCloseMobile() {
        if (this.isMobile) {
          this.close('mobile');
        }
    }



    updateOnlineText() {
        this.isAlternate = !this.isAlternate;
        if (this.isAlternate) {
            this.onlineText = this.generateTextWithRandomNumber(this.alternateText);
        } else {
            this.onlineText = this.generateTextWithRandomNumber("Consultants Online");
        }
    }

    generateTextWithRandomNumber(text: string): string {
        const randomNumber = Math.floor(Math.random() * 20) + 1; // Generate a random number between 1 and 10
        return randomNumber.toString() + " " + text;
    }

    ngAfterViewInit() {
        this.profilePictureSubscription = this.sessionService.userProfilePic$.subscribe(() => this.profilePictureView.reloadImage());
        
    }

    ngOnDestroy(): void {
        if (this.pwaEventSubscription) this.pwaEventSubscription.unsubscribe();
        if (this.profilePictureSubscription) this.profilePictureSubscription.unsubscribe();
        if (this.authenticationEventSubscription) this.authenticationEventSubscription.unsubscribe();
        if(this.clientUsers) this.clientUsers.unsubscribe()
    }

    dismiss() {
        this.alertVisible = false;
    }

    reload() {
        window.location.reload();
    }

    checkForUpdates() {
        this.snackBar.open('Searching for updates...', '', {
            duration: 5000,
        });
        this.pwaService.checkForUpdate();
    }

    openNav() {
        // show closing button
        this.closeButtonHidden = false;
        if (screen.width <= 450) {
            const sideBar: any = document.getElementById("mySidebar");
            sideBar.style.width = "250px";
        } else {
            const sideBar: any = document.getElementById("mySidebar");
            sideBar.style.width = "250px";

            const homeMain: any = document.getElementById("homeMain");
            homeMain.style.marginLeft = "250px";
        }
    }

    closeNav() {
        this.closeButtonHidden = true;
        if (!document.getElementById("mySidebar") || !document.getElementById("homeMain")) {
            return;
        }
        if (screen.width <= 450) {
            const sideBar: any = document.getElementById("mySidebar");
            sideBar.style.width = "0px";

            const homeMain: any = document.getElementById("homeMain");
            homeMain.style.marginLeft = "0px";
        } else {
            const sideBar: any = document.getElementById("mySidebar");
            sideBar.style.width = "60px";

            const homeMain: any = document.getElementById("homeMain");
            homeMain.style.marginLeft = "60px";
        }
    }

    toggleSignupOverlay() {
        this.isOverlayVisible = !this.isOverlayVisible;
    }

    closeSignupOverlay() {
        this.isOverlayVisible = false;
    }

    onMenuItemClicked(headerName: string, route: string) {
        this.headerName = headerName;
        this.router.navigateByUrl(route);
    }

    onClickLogout() {
        const logoutRef = this.dialog.open(LogoutComponent);

        logoutRef.afterClosed().subscribe((result: any) => {
            if (result === true) {
                this.authManagementService.loggedIn = false;
              
                this.wizardService.isContractorFirstTimeRegistration = false;
               this.sessionStorage.clear()
               window.location.reload();

            }
        });
    }

    toggleContactsPopup() {
        this.showContactsPopup = !this.showContactsPopup;
    }

    get swAvailable() {
        return this.pwaService.swAvailable();
    }

    get profileComplete(): boolean {
        return this.sessionService.getUserProfileComplete();

    }

    get isLoggedIn(): boolean {
        return this.authManagementService.isLoggedIn();
    }

    get userInitials(): string {
        let profile = this.sessionStorage.getItem('user-profile');
        if (profile == null || profile.name == null || profile.surname == null) return "BP";
        return profile.name.slice(0, 1) + profile.surname.slice(0, 1);
    }

    get userEmail(): string {
        return this.sessionStorage.getItem('user-name') || "";
    }

    get privacyPolicyUrl(): string {
        return environment.privacyPolicyUrl;
    }

    get isClient(): boolean {
        return this.sessionService.getUserType() === 'client';
    }

    get isContractor(): boolean {
        return this.sessionService.getUserType() === 'contractor';
    }
    get selectedRole(): string {
        return this.sessionStorage.getItem('selected-role') || "";
    }

    get homeLink(): string {
        if(this.selectedRole == "admin")
            return '/dashboardclient';
        if (this.isLoggedIn && this.isClient)
            return '/dashboardclient';

        if (this.isLoggedIn && this.isContractor)
            return '/user-profile';

        return '/landing';
    }

    get versionNumber(): string {
        return environment.versionString;
    }


    // Function to check if the current route is the landing page
    isLandingPage(): boolean {
        return this.router.url === '/landing';
    }


    onToggleSignupOverlay() {
        this.isOverlayVisible = !this.isOverlayVisible;
    }




    // Function to check navbar open or not
    toggleNav() {
        this.isNavOpen = !this.isNavOpen;
    }

    close(reason: string) {
        if(this.isMobile){
            this.sidenav.toggle();
            this.isCollapsed=false;
        }
        else{
            this.sidenav.open();
            this.isCollapsed=!this.isCollapsed;
        }

    }






    //Login modal pop up

    openLoginModal(): void {
        const dialogRef = this.dialog.open(LoginModalComponent, {
          width: '450px', // Set the desired width
          // You can add other options here, like height, data, etc.
        });

        dialogRef.afterClosed().subscribe(() => {
          // Handle any actions after the dialog is closed
          this.ngOnInit()
        });
    }



    openLoginModal2(): void {
        const dialogRef = this.dialog.open(ContractorOnboardingComponent, {
          width: '800px', // Set the desired width
          // You can add other options here, like height, data, etc.
        });

        dialogRef.afterClosed().subscribe(() => {
          // Handle any actions after the dialog is closed
        });
    }




}
