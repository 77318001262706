// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
export const environment = {
    production: false,
    baseUrl: 'https://api.dev.blueapp.blue/v2',
    apiKey: '04PCpMZcVM7RJ9hMtU6BT5JzfipU1ZE65XHAQWFf',
    privacyPolicyUrl: 'https://bluepearl.co.za/blue-privacy-notice/',
    termsUrl: 'https://bluepearl.co.za/blue-terms-and-conditions/',
    aws_project_region: "eu-west-1",
    aws_cognito_identity_pool_id: "eu-west-1:dd101fb6-e3de-490c-8916-a847dbc6f73d",
    aws_cognito_region: "eu-west-1",
    aws_user_pools_id: "eu-west-1_q8lythCqB",
    aws_user_pools_web_client_id: "4vhq881mlav85c6mniiaikmpog",
    oauth: {},
    versionString: "DEV-2.3.0",
    peachUrl: 'https://test.oppwa.com/v1/paymentWidgets.js?checkoutId=',
    IAM: 'ses-smtp-user.20231011-091449',
    AWS_ACCESS_KEY_ID: 'AKIAZMQHHPRBKAFHNYE6',
    AWS_SECRET_ACCESS_KEY: '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',
    AWS_REGION: 'eu-west-1',
    adminemail: 'careers@bluepearl.co.za',
    watsonApiKey: 'e9HX-c8pXfAdjs6O4VEtN5prI-zXGbTZE7EoicnPlR_x'
};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
