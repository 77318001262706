<div class = "container">


    <div class="col-md-6 offset-md-3 ">
       <span class="anchor" id="formChangePassword"></span>
       <hr class="mb-5"> 
   
       <!-- form card change password -->
      <div class="card card-outline-secondary ">
           <div class="card-header pswd">
               <h4 class="mb-0 text-light"> 
              Change Password</h4>
           </div>
           <mat-progress-bar class='custom-loading-bar'  mode="indeterminate" *ngIf="disableButton"></mat-progress-bar>
           <div class="card-body">
               <form  role="form" autocomplete="off" [formGroup]="changePasswordForm">
                   <div class="form-group">
                       <label for="inputPasswordOld">Current Password *</label>
                     <div>
                       <input type="password" class="form-control" id="inputPasswordOld" required="" required pattern="((?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,30})" formControlName="currentPassword" matInput placeholder="Current Password" [type]="currentPasswordType" >
                       <div class="input-group-append">
   <!-- <mat-icon matSuffix (click)="toggleVisibility('currentPassword')" class="password-visibility-toggle-button" *ngIf="!currentPasswordVisible">visibility_off</mat-icon> -->
                       <mat-icon matSuffix (click)="toggleVisibility('currentPassword')" class="password-visibility-toggle-button" *ngIf="currentPasswordVisible">visibility</mat-icon>
                       <div [hidden]="passwordValid" class="required-field small text-muted">
                           Current password is required
                       </div>
                       </div>
                       
                     </div>
                      
                   </div>
                   
                   <div class="form-group row">
                       <label for="inputPasswordNew">New Password *</label>
                       <div class="">
                           <input type="password" class="form-control " id="inputPasswordNew" required="" required pattern="((?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,30})" formControlName="newPassword" matInput placeholder="New Password" [type]="newPasswordType" >
                           <mat-icon matSuffix (click)="toggleVisibility('newPassword')" class="password-visibility-toggle-button" *ngIf="!newPasswordVisible">visibility_off</mat-icon>
                           <mat-icon matSuffix (click)="toggleVisibility('newPassword')" class="password-visibility-toggle-button" *ngIf="newPasswordVisible">visibility</mat-icon>
                   
                       </div>
                      
                       <span [hidden]="newPasswordValid" class="form-text small text-muted">
                               The password must be 8-20 characters, and must <em>not</em> contain spaces.
                           </span>
                           <div [hidden]="newPasswordValid" class="required-field col-sm-12">
                               <!-- New password is required * -->
                           </div>
                   </div>
                   <div class="form-group">
                       <label for="inputPasswordNewVerify">Confirm Password *</label>
                       <input required pattern="((?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,30})" formControlName="confirmPassword" matInput placeholder="Confirm Password" [type]="confirmPasswordType" type="password"required pattern="((?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,30})"  class="form-control" id="inputPasswordNewVerify" required="">
                       <span  class=" required-field small text-muted">
                               To confirm, type the new password again.
                           </span>
                           <div [hidden]="confirmPasswordValid" class="required-field col-sm-12">
                               Passwords need to match
                           </div>
                   </div>
                   <div class="form-group">
                       <button type="submit" class="btn  btn-lg float-right text-light mt-3" 
                        [disabled] = "disableButton"
                         (click)="onChangePassword()" 
                        mat-stroked-button 
                       Tooltip="Change Password">
                           Change
                   </button>
                   </div>
               </form>
           </div> 
       </div>
       <!-- /form card change password -->
   <!-- 
   </div> --> 