import { Component, OnInit, Inject, Input, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ContractorProfile } from 'src/app/shared/user-profile-models';
import { BASE_URL } from 'src/app/app.provider';
import { HttpClient } from '@angular/common/http';
import { ClientMatchRequest } from 'src/app/shared/company-profile-models';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SessionService } from '../session.service';
import { SessionStorageService } from '../session-storage.service';
import { EmailService } from 'src/app/modules/onboarding-wizard/email.service';
import { environment } from 'src/environments/environment';
import { NotificationService } from 'src/app/notification.service';
import { MatAccordion, MatExpansionModule } from '@angular/material/expansion';
import html2pdf from 'html2pdf.js';
import { ClientDetailsComponent } from '../../modules/dashboard/client-details/client-details.component';
import { Qualifications } from '../../home/job-profiles/job-profiles-add/job-profiles-add.component';
@Component({
  selector: 'app-candidate-profile',
  templateUrl: './candidate-profile.component.html',
  styleUrls: ['./candidate-profile.component.css'],
})
export class CandidateProfileComponent implements OnInit {
  @ViewChild(MatAccordion) accordion: MatAccordion;
  @Input()
  candidate!: ContractorProfile;
  @Input()
  clientEmail!: string;
  @Input()
  jobIdentifier!: string;
  avatar: string = '/assets/images/user-avatar-default.png';

  disabled: boolean = false;

  workExperienceTotals = 0;
  groupedSkills: any = [];
  sortedSkills: any = [];
  sortedExperience: any = [];
  sortedCertifications: any = [];
  sortedEducation: any = [];
  panelOpenState = false;
  error = false;
  errorMessage = '';

  constructor(
    @Inject(BASE_URL) protected baseUrl: string,
    private notificationService: NotificationService,
    private router: Router,
    private http: HttpClient,
    private snackBar: MatSnackBar,
    private sessionService: SessionService,
    private sessionStorage: SessionStorageService,
    private emailService: EmailService
  ) {
    /* empty */
  }

  ngOnInit() {
    this.initValues();
  }

  viewTerms() {
    this.router.navigateByUrl('main/job-profiles/sign-contractor-terms');
  }

  onViewNextSteps() {
    this.logAcceptTerms();
    this.RequestInterview(environment.adminemail);
    const userId = this.candidate.email;
    const notificationMessage = 'You have been requested for an Interview!';
  }

  async RequestInterview(email: string) {
    // Fetch the HTML template
    const htmlBody = await this.http
      .get('assets/RequestInterview.html', { responseType: 'text' })
      .toPromise();

    // Replace placeholders with actual content
    const filledHtmlBody = htmlBody
      .replace('{{refrance}}', this.candidate.candidateNum) // Replace {{refrance}} with actual candidate name
      .replace('{{clientname}}', this.clientEmail); // Replace {{clientname}} with actual client name

    const params = {
      subject: 'New Request for interview candidate: ' + this.userInitials,
      htmlBody: filledHtmlBody,
      email: email,
      textBody: filledHtmlBody, // You may adjust this as needed
    };

    try {
      await this.emailService.sendEmail(email!, params.subject, filledHtmlBody);
    } catch (error) {
      console.error('Error sending email:', error);
      // Handle the error appropriately
    }
  }

    async downloadResume() {
      const templateResponse = await this.http.get('assets/Candidate-Resume.html', { responseType: 'text' }).toPromise();

    // Log the candidate object
    console.log('Candidate object:', this.candidate);

    // Populate the template with profile data
    const filledTemplate = this.populateTemplate(
      templateResponse,
      this.candidate
    );

    // Log the filled template
    console.log('Filled template:', filledTemplate);

      // Generate PDF from the filled template
      const options = {
        margin: 1,
        filename: 'Candidate'+ this.candidate.candidateNum,
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' }
      };

    // Generate PDF using html2pdf
    html2pdf().from(filledTemplate).set(options).save();
  }

  populateTemplate(template: string, candidate: any): string {
    // Replace {{candidate.candidateNum}} with the actual value
    if (candidate.candidateNum) {
      template = template.replace(
        /{{candidate.candidateNum}}/g,
        candidate.candidateNum
      );
    } else {
      template = template.replace(/{{candidate.candidateNum}}/g, '');
    }

    // Replace {{candidate.nationality}} with the actual value
    template = template.replace(
      /{{candidate.nationality}}/g,
      candidate.nationality
    );

    // Replace {{candidate.gender}} with the actual value
    template = template.replace(/{{candidate.gender}}/g, candidate.gender);

    // Replace {{candidate.province}} with the actual value
    template = template.replace(/{{candidate.province}}/g, candidate.province);

    // Replace {{candidate.city}} with the actual value
    template = template.replace(/{{candidate.city}}/g, candidate.city);

    // Replace {{candidate.noticePeriod}} with the actual value
    template = template.replace(
      /{{candidate.noticePeriod}}/g,
      candidate.noticePeriod
    );

    // Replace work history
    const workHistoryRegex =
      /\*ngFor="let work of candidate.workHistory">([\s\S]*?)<\/div>/g;
    const workHistoryMatches = template.match(workHistoryRegex);

    if (workHistoryMatches) {
      const workHistoryTemplate = workHistoryMatches[0];
      let workHistoryHtml = '';

      if (Array.isArray(candidate.workHistory)) {
        for (const work of candidate.workHistory) {
          let workHtml = workHistoryTemplate
            .replace(/\*ngFor="let work of candidate.workHistory"/g, '')
            .replace(/\*ngIf="work.to != null"/g, '');
          workHtml = workHtml.replace(/{{work.role}}/g, work.role);
          workHtml = workHtml.replace(/{{work.company}}/g, work.company);

          if (work.to !== null) {
            workHtml = workHtml.replace(
              /{{work.from}}/g,
              this.formatDate(new Date(work.from))
            );
            workHtml = workHtml.replace(
              /{{work.to}}/g,
              this.formatDate(new Date(work.to))
            );
          } else {
            workHtml = workHtml.replace(
              /{{work.from}}/g,
              this.formatDate(new Date(work.from))
            );
            workHtml = workHtml.replace(/{{work.to}}/g, '');
          }

          workHistoryHtml += workHtml;
        }
      } else {
        workHistoryHtml = workHistoryTemplate
          .replace(/\*ngFor="let work of candidate.workHistory"/g, '')
          .replace(/\*ngIf="work.to != null"/g, '');
        workHistoryHtml = workHistoryHtml.replace(/{{work.role}}/g, '');
        workHistoryHtml = workHistoryHtml.replace(/{{work.company}}/g, '');
        workHistoryHtml = workHistoryHtml.replace(/{{work.from}}/g, '');
        workHistoryHtml = workHistoryHtml.replace(/{{work.to}}/g, '');
      }

      template = template.replace(workHistoryRegex, workHistoryHtml);
    }

    // Replace education qualifications
    const educationRegex =
      /\*ngFor="let qualification of candidate.qualifications">([\s\S]*?)<\/div>/g;
    const educationMatches = template.match(educationRegex);

    if (educationMatches) {
      const educationTemplate = educationMatches[0];
      let educationHtml = '';

      if (Array.isArray(candidate.qualifications)) {
        for (const qualification of candidate.qualifications) {
          let qualificationHtml = educationTemplate.replace(
            /\*ngFor="let qualification of candidate.qualifications"/g,
            ''
          );
          qualificationHtml = qualificationHtml.replace(
            /{{qualification.name}}/g,
            qualification.name
          );
          qualificationHtml = qualificationHtml.replace(
            /{{qualification.institution}}/g,
            qualification.institution
          );
          qualificationHtml = qualificationHtml.replace(
            /{{qualification.attained}}/g,
            this.formatDate(new Date(qualification.attained))
          );

          educationHtml += qualificationHtml;
        }
      } else {
        educationHtml = educationTemplate.replace(
          /\*ngFor="let qualification of candidate.qualifications"/g,
          ''
        );
        educationHtml = educationHtml.replace(/{{qualification.name}}/g, '');
        educationHtml = educationHtml.replace(
          /{{qualification.institution}}/g,
          ''
        );
        educationHtml = educationHtml.replace(
          /{{qualification.attained}}/g,
          ''
        );
      }

      template = template.replace(educationRegex, educationHtml);
    }

    // Replace certificates
    const certificateRegex =
      /\*ngFor="let certificate of candidate.certifications">([\s\S]*?)<\/div>/g;
    const certificateMatches = template.match(certificateRegex);

    if (certificateMatches) {
      const certificateTemplate = certificateMatches[0];
      let certificateHtml = '';

      if (Array.isArray(candidate.certifications)) {
        for (const certificate of candidate.certifications) {
          let certHtml = certificateTemplate.replace(
            /\*ngFor="let certificate of candidate.certifications"/g,
            ''
          );
          certHtml = certHtml.replace(
            /{{certificate.name}}/g,
            certificate.name
          );
          certHtml = certHtml.replace(
            /{{certificate.authority}}/g,
            certificate.authority
          );
          certHtml = certHtml.replace(
            /{{certificate.attained}}/g,
            this.formatDate(new Date(certificate.attained))
          );

          certificateHtml += certHtml;
        }
      } else {
        certificateHtml = certificateTemplate.replace(
          /\*ngFor="let certificate of candidate.certifications"/g,
          ''
        );
        certificateHtml = certificateHtml.replace(/{{certificate.name}}/g, '');
        certificateHtml = certificateHtml.replace(
          /{{certificate.authority}}/g,
          ''
        );
        certificateHtml = certificateHtml.replace(
          /{{certificate.attained}}/g,
          ''
        );
      }

      template = template.replace(certificateRegex, certificateHtml);
    }

    // Replace skillss
    const skillRegex =
      /\*ngFor="let skill of candidate.skills">([\s\S]*?)<\/div>/g;
    const skillMatches = template.match(skillRegex);

    if (skillMatches) {
      let skillHtml = '';

      if (Array.isArray(candidate.skills)) {
        for (const skill of candidate.skills) {
          skillHtml += `<p>${skill.description}</p>`;
        }
      }

      template = template.replace(skillRegex, skillHtml);
    }

    return template;
  }

  formatDate(date: Date): string {
    const options: Intl.DateTimeFormatOptions = {
      day: '2-digit',
      month: 'short',
      year: '2-digit',
    };
    return date.toLocaleDateString('en-US', options);
  }

  get isClientUser(): boolean {
    return this.sessionService.getUserType() === 'client';
  }

  get userInitials(): string {
    if (this.candidate.initials != null) return this.candidate.initials;
    if (this.candidate.name == null || this.candidate.surname == null)
      return 'BP';
    return this.candidate.name.slice(0, 1) + this.candidate.surname.slice(0, 1);
  }

  get userEmail(): string {
    return this.candidate.email;
  }

  get hasProfile(): boolean {
    return !this.noProfilesAvailable;
  }

  get noProfilesAvailable(): boolean {
    return this.candidate === null || this.candidate === undefined;
  }

  get getQualifications() {
    if (this.noProfilesAvailable) return [];
    return this.sortedEducation;
  }

  get getCertifications() {
    if (this.noProfilesAvailable) return [];
    return this.sortedCertifications;
  }

  get getSkills() {
    if (this.noProfilesAvailable) return [];
    return this.sortedSkills;
  }

  get getGroupedSkills() {
    if (this.noProfilesAvailable) return [];
    return this.groupedSkills;
  }

  get getWorkHistory() {
    if (this.noProfilesAvailable) return [];
    return this.sortedExperience;
  }

  get getReferences() {
    if (this.noProfilesAvailable) return [];
    return this.candidate.references || [];
  }

  private logAcceptTerms() {
    let data: ClientMatchRequest = {
      identifier: this.jobIdentifier,
      clientEmail: this.clientEmail,
      candidateNum: this.candidate.candidateNum,
    };
    this.error = false;
    this.errorMessage = '';
    this.http.post(this.baseUrl + '/profile/accept-terms', data).subscribe(
      (response) => {
        this.snackBar.open('Terms accepted successfully', '', {
          duration: 3000,
        });
        this.router.navigateByUrl('main/job-profiles/next-steps');
      },
      (error) => {
        this.errorMessage = error.error.message;
        this.error = true;
      }
    );
  }

  private compareDates(
    d1: number,
    d2: number,
    ascending: boolean = false
  ): any {
    let same = d1 === d2;
    if (same) return 0;
    if (ascending) {
      if (d1 > d2) return 1;
      if (d1 < d2) return -1;
    } else {
      if (d1 < d2) return 1;
      if (d1 > d2) return -1;
    }
  }

  private compareSkills(
    skill1: string,
    skill2: string,
    ascending: boolean = false
  ): any {
    let level1 = this.mapSkillLevels(skill1);
    let level2 = this.mapSkillLevels(skill2);
    let same = level1 === level2;
    if (same) return 0;
    if (ascending) {
      if (level1 > level2) return 1;
      if (level1 < level2) return -1;
    } else {
      if (level1 < level2) return 1;
      if (level1 > level2) return -1;
    }
  }

  private mapSkillLevels(skill: string): number {
    if (!skill) return 0;
    switch (skill.toLowerCase()) {
      case 'expert':
        return 4;
      case 'senior':
        return 3;
      case 'intermediate':
        return 2;
      case 'beginner':
        return 1;
      default:
        return 0;
    }
  }

  private groupBy(key: string, array: any[]): any[] {
    let obj = array.reduce((objectsByKeyValue, obj) => {
      const value = obj[key];
      objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);
      return objectsByKeyValue;
    }, {});
    return Object.keys(obj).map(function (key) {
      return { key: key, data: obj[key] };
    });
  }

  private initValues() {
    this.groupedSkills = this.groupBy('level', this.candidate.skills);
    this.sortedSkills =
      this.candidate.skills !== null
        ? this.candidate.skills.sort((a, b) =>
            this.compareSkills(a.level, b.level)
          )
        : [];
    this.sortedEducation =
      this.candidate.qualifications !== null
        ? this.candidate.qualifications.sort((a, b) =>
            this.compareDates(a.attained, b.attained)
          )
        : [];
    this.sortedCertifications =
      this.candidate.certifications !== null
        ? this.candidate.certifications.sort((a, b) =>
            this.compareDates(a.attained, b.attained)
          )
        : [];
    this.sortedExperience =
      this.candidate.workHistory !== null
        ? this.candidate.workHistory.sort((a, b) =>
            this.compareDates(a.from, b.from)
          )
        : [];
    this.getTotalExperience();
  }

  private getTotalExperience() {
    let lastDate = new Date(
      this.sortedExperience[0].to || new Date().getTime()
    );
    let firstDate = new Date(
      this.sortedExperience[this.sortedExperience.length - 1].from
    );
    let months =
      12 -
      firstDate.getMonth() +
      (lastDate.getFullYear() - (firstDate.getFullYear() + 1)) * 12 +
      lastDate.getMonth();
    this.workExperienceTotals = months;
  }

  get getYearsOfExperience(): number {
    return Math.floor(this.workExperienceTotals / 12);
  }
  get getMonthsOfExperience(): number {
    return this.workExperienceTotals % 12;
  }

  get isUserActive(): boolean {
    return (
      this.sessionStorage.getItem('user-profile-state', 'INCOMPLETE') ===
      'COMPLETE'
    );
  }

  isAlternateRow(index: number): boolean {
    return index % 2 !== 0; // Alternates colors based on the row index
  }
}
