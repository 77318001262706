<!-- <mat-progress-bar *ngIf="isLoading" mode="indeterminate"></mat-progress-bar> -->
<mat-progress-bar *ngIf="isLoading" mode="indeterminate"></mat-progress-bar>

<div class="row mb-4 mt-4">
  <div class="col"  *ngIf="hasProfile">
      <h6>Add Users to </h6>
      <h4 style="color: #017DB5;"><b>{{profile.company.name}}</b></h4>
      <h6 class="text-gray-500"> Add users to your organization.</h6>
      
      

  </div>
  <hr>
<div class="col-10">
    
      <!-- show work history -->
      <button class="rounded-pill m-1 mt-3" (click)="onAddUser()" *ngIf="!error" mat-raised-button>
        Add User
      </button>
</div>
<mat-card  [hidden]="addUser">
    <mat-card-header>
        <mat-card-title>Invite New User</mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <div class="row">
            <!-- candidate name -->
            <mat-form-field class="col-md-6">
                <mat-label>Name</mat-label>
                <input [(ngModel)]="newUserName" matInput placeholder="John">
            </mat-form-field>
            <!-- Assign roles-->
            <div class="col-md-6">
              <mat-form-field style="width: 100%;">
                <mat-label>Role</mat-label>
                <mat-select [(ngModel)]="selectedRole" placeholder="Select a role">
                  <mat-option *ngFor="let role of roles" [value]="role.value">
                    {{ role.viewValue }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <!-- email -->
            <div class="col-md-6">
                <mat-form-field style = "width: 100%;">
                    <mat-label>Email</mat-label>
                    <input required pattern="^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" [(ngModel)]="newUserEmail" matInput placeholder="mail@example.com" type="email" #mail="ngModel">
                </mat-form-field>
                <div [hidden]="mail.valid || mail.untouched" class="required-field col-sm-12">
                    Please input correct email address...
                </div>
            </div>
        </div>
    </mat-card-content>
    <mat-card-actions class="text-right">
        <button
            (click) = "onCancelUser()"
            mat-button>CANCEL</button>
        <button 
            (click) = "onInviteUser()"
            mat-button>INVITE</button>
    </mat-card-actions>
</mat-card>
<div>
  <div class=" p-3 mt-5  head" [hidden]="isMobile" >
    <h6 class="MB-1"><strong>Company Users</strong></h6>
</div>
<div class="custom-card-body p-3">
    <div class="clientDetails" *ngIf="hasProfile">
        <div
        [hidden] = "isMobile"
        class="row">
       
        <table  class="table table-hover table-light table-sm table-striped "  >
            <thead >
              <tr>
                <th scope="col">#</th>
                <th scope="col">Name</th>
                <th scope="col">Email</th>
                <th scope="col">Status</th>
                <th scope="col">Super Admin</th>
                <th scope="col">Action</th>
                
              </tr>
            </thead>
            <tbody *ngFor = "let user of users; index as i">
              <pre>{{ user | json }}</pre>

              <tr>
                <th scope="row">{{i+1}}</th>
                <td >{{user.name}}</td>
                <td >{{user.emailAddress}}</td>
                <td ><div *ngIf = "user.invitationStatus == null">
                    <h6 class = "card-h6-element"><strong>INACTIVE</strong></h6>
                </div>
                <h6 class = "card-h6-element"><strong>{{user.invitationStatus}}</strong></h6></td>
                <td >{{user.superAdmin}}</td>
                <td><button  type="button" class="btn btn-light" *ngIf="user.admin!=true" (click)="onDeleteClient(user.emailAddress)">Delete </button></td>
                
              </tr>
              </tbody>
            </table>
    </div>
    </div>
</div>
</div>


<!--User Roles:--> 
<!-- <section class="example-section" [formGroup]="accesslevels">
    <h4>Blue Desk:</h4>
    <div class="row d-flex mt-3">
      <div class=" col">
      <h6>Active job</h6>
      <p><mat-checkbox formControlName="View">View</mat-checkbox></p>
      <p><mat-checkbox formControlName="Edit">Edit</mat-checkbox></p>
      <p><mat-checkbox formControlName="Delete">Delete</mat-checkbox></p>
      </div>
      <div class="col">
        <h6>Expired job</h6>
        <p><mat-checkbox formControlName="View">View</mat-checkbox></p>
        <p><mat-checkbox formControlName="Edit">Edit</mat-checkbox></p>
        <p><mat-checkbox formControlName="Delete">Delete</mat-checkbox></p>
      </div>
      <div class="col">
        <h6>Create Job</h6>
        <p><mat-checkbox formControlName="View">View</mat-checkbox></p>
      </div>
     </div> 
  </section>
  
  <section class="example-section" [formGroup]="accesslevels">
  </section> -->
<!--Timesheets--> 
  <!-- <section class="example-section" [formGroup]="accesslevels">
    <h4>Timesheets:</h4>
    <p><mat-checkbox formControlName="View">View</mat-checkbox></p>
    <p><mat-checkbox formControlName="Edit">Edit</mat-checkbox></p>
    <p><mat-checkbox formControlName="Delete">Delete</mat-checkbox></p>
  </section>

  <section class="example-section" [formGroup]="accesslevels">
  </section> -->
<!--Subscription--> 
  <!-- <section class="example-section" [formGroup]="accesslevels">
    <h4>Subscription:</h4>

    <p><mat-checkbox formControlName="View">View</mat-checkbox></p>
    <p><mat-checkbox formControlName="Edit">Edit</mat-checkbox></p> -->
    <!-- <p><mat-checkbox formControlName="Delete">Delete</mat-checkbox></p>
       -->
    <!-- <p><mat-checkbox formControlName="View">View</mat-checkbox></p>
    <p><mat-checkbox formControlName="Edit">Edit</mat-checkbox></p>
    <p><mat-checkbox formControlName="Delete">Delete</mat-checkbox></p> -->
  <!-- </section>
  <section class="example-section" [formGroup]="accesslevels">
</section> -->
<!--Find A talent:--> 
<!-- <section class="example-section" [formGroup]="accesslevels"> -->
    <!-- <h4> Find A talent:</h4> -->
    <!-- <h4>Find A talent:</h4>
    <div class="row d-flex mt-3">
      <div class=" col">
      <h6>Filter Page</h6>
      <p><mat-checkbox formControlName="View">View</mat-checkbox></p>
      <p><mat-checkbox formControlName="Edit">Edit</mat-checkbox></p>
      <p><mat-checkbox formControlName="Delete">Delete</mat-checkbox></p>
      </div>
      <div class="col"> -->
        <!-- <h6>Request for Interview</h6> -->
        <!-- <p><mat-checkbox formControlName="View">View</mat-checkbox></p> -->
        <!-- <p><mat-checkbox formControlName="Edit">Edit</mat-checkbox></p> -->
        <!-- <p><mat-checkbox formControlName="Delete">Delete</mat-checkbox></p> -->
      <!-- </div>
     
     </div> 
  </section>
  
  <section class="example-section" [formGroup]="accesslevels">
  </section> -->

