import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { SessionService } from 'src/app/shared/session.service';
import { ClientProfileService } from '../client-profile/client-profile.service';

@Component({
  selector: 'app-user-roles',
  templateUrl: './user-roles.component.html',
  styleUrls: ['./user-roles.component.css']
})
export class UserRolesComponent implements OnInit {

  // Define the roles
  roles = [
    { value: 'admin', viewValue: 'Admin' },
    { value: 'general_user', viewValue: 'General User' }
  ];


  isMobile = false;
  addUser = true;
  newUserEmail = '';
  newUserName = '';
  selectedRole = '';  // Define the selectedRole property
  error = "";
  users: any[] = [];
  requestedUsers: any;
  profile: any;
  isLoading!: boolean;

  infoStep = 1;
  accesslevels = this._formBuilder.group({
    View: false,
    Edit: false,
    Delete: false,
  });

  constructor(
    private _formBuilder: FormBuilder,
    private router: Router,
    private sessionClass: SessionService,
    private snackBar: MatSnackBar,
    private clientProfileService: ClientProfileService
  ) { }

  ngOnInit() {
    this.loadProfile();
    this.getUsers();
    this.getRequestedUsers();
    if (window.innerWidth <= 400) {
      this.isMobile = true;
    }
  }

  get username(): string {
    return this.sessionClass.getUsername();
  }

  get hasProfile(): boolean {
    return this.profile != null;
  }

  onAddUser() {
    this.addUser = false;
  }

  onCancelUser() {
    this.addUser = true;
  }

  acceptRequest() {
    this.isLoading = true;

    this.clientProfileService.acceptInvitation().subscribe((res: any) => {
      this.isLoading = false;
      this.router.navigateByUrl('/main', { skipLocationChange: false }).then(() => {
        this.router.navigateByUrl('/main/client-profile')
      })
    }, err => {
      this.isLoading = false;
    })
  }

  onInviteUser() {
    this.clientProfileService.inviteNewUser(this.newUserEmail, this.newUserName, this.selectedRole).subscribe(() => {
      this.addUser = true;
      this.getUsers();
    }, error => {
      this.isLoading = false;
      if (error.status != 404) {
        this.error = error.error.message
      }
    });
  }

  onApproveRequest() {
    this.clientProfileService.approveClientRequest(this.profile.company.name, this.profile.company.registrationNumber).subscribe(() => {
      this.addUser = true;
      this.getUsers();
    }, error => {
      this.isLoading = false;
      if (error.status != 404) {
        this.error = error.error.message
      }
    });
  }

  private loadProfile() {
    this.isLoading = true;
    this.clientProfileService.loadProfile().subscribe(response => {
      this.profile = response;
      this.isLoading = false;
    }, error => {
      this.isLoading = false;
      if (error.status == 404) {
        this.profile = null;
      } else {
        this.error = error.error.message
      }
    });
  }

  private getUsers() {
    this.clientProfileService.getCompanyUsers().subscribe((data) => {
      this.users = data;
    }, error => {
      this.isLoading = false;
      if (error.status != 404) {
        this.error = error.error.message
      }
    });
  }

  private getRequestedUsers() {
    this.clientProfileService.getRequestedUsers().subscribe((data) => {
      this.requestedUsers = data;
    }, error => {
      this.isLoading = false;
      if (error.status != 404) {
        this.error = error.error.message
      }
    });
  }

  setStep(val: number): void {
    this.infoStep = val;
  }

  onDeleteClient(emailAddress: string) {
    this.clientProfileService.deleteclientFromCompany(emailAddress).subscribe((res: any) => {
      window.location.reload();
    }, err => {
    })
  }
}
