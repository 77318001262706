import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, timeout, tap, retryWhen, delay, take } from 'rxjs/operators';
import { environment } from '../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class WatsonAIService {
  private backendUrl = environment.baseUrl; // Update this URL if needed

  constructor(private http: HttpClient) { }

  sendPrompt(jobSpecifications: string, candidateProfiles: string): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    });

    const body = {
      parameters: {
        prompt_variables: {
          job_specifications: jobSpecifications,
          candidate_profiles: candidateProfiles
        }
      }
    };

    return this.http.post(`${this.backendUrl}/profile/job-matching`, body, { headers }).pipe(
      tap(response => console.log('Response from job matching service:', response)),
      timeout(30000), // 30 seconds timeout
      retryWhen(errors =>
        errors.pipe(
          tap(error => console.error('Error sending prompt:', error)),
          delay(2000), // 2 seconds delay before retry
          take(3) // Retry up to 3 times
        )
      ),
      catchError(this.handleError)
    );
  }

  private handleError(error: HttpErrorResponse) {
    let errorMessage = 'An error occurred';
    if (error.error instanceof ErrorEvent) {
      // Client-side error
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // Server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.error(errorMessage);
    return throwError(() => new Error(errorMessage));
  }
}