<h2 mat-dialog-title>Generate Job Description</h2>
<mat-dialog-content>
  <form>
    <mat-form-field>
      <mat-label>Industry</mat-label>
      <input matInput placeholder="Enter industry" [(ngModel)]="industry" name="industry">
    </mat-form-field>
    <mat-form-field>
      <mat-label>Job Title</mat-label>
      <input matInput placeholder="Enter job title" [(ngModel)]="jobTitle" name="jobTitle">
    </mat-form-field>
    <mat-form-field>
      <mat-label>Skills</mat-label>
      <input matInput placeholder="Enter skills" [(ngModel)]="skills" name="skills">
    </mat-form-field>
    <mat-form-field>
      <mat-label>Years of Experience</mat-label>
      <input matInput type="number" placeholder="Enter years of experience" [(ngModel)]="yearsExperience" name="yearsExperience">
    </mat-form-field>
  </form>
  <div *ngIf="isLoading" class="loading-spinner">
    <mat-spinner diameter="50"></mat-spinner>
  </div>
  <div *ngIf="response && !isLoading">
    <h3>Generated Job Description:</h3>
    <pre class="job-description">{{ response }}</pre>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button (click)="onCancel()">Cancel</button>
  <button mat-button *ngIf="!response" (click)="onGenerate()" [disabled]="isLoading">Generate</button>
  <button mat-button *ngIf="response" (click)="copyToClipboard()">Copy</button>
</mat-dialog-actions>
