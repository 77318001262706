<div class="custom-card container-md p-0 my-3 mt-5">
    <div class="">
        <!-- <button mat-raised-button class="Button1"  *ngIf="isContractor" (click)="goBack()">
            <i class="fa-sharp fa-solid fa-circle-arrow-left icon" ></i>Back
        </button> -->
        <div class="col headingmain"  >
            <h4><b>FAQs</b></h4>
            
            <h6 class="font-weight-light" >Frequently asked questions</h6>  
      
        </div>
        <hr>
    </div>
    <!--<nav class="navbar"  *ngIf="isContractor" style="background: linear-gradient(90deg, #40B9F0 0%, rgba(23, 14, 102, 0.90) 100%);">
        <div class="container-fluid d-flex justify-content-center">
            <h4 class="text-white text-weight-bold">Blue Desk</h4>
           
        </div>
    </nav>-->
       
    <div class="custom-card-body p-3 mt-4">
        <!-- <button mat-button (click)="accordion.openAll()" >Expand All</button>
        <button mat-button (click)="accordion.closeAll()" >Collapse All</button> -->
        <mat-accordion multi>
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title><mat-icon>add</mat-icon><b>What is BLUE?</b></mat-panel-title>
                </mat-expansion-panel-header>
                <p>Online platform for ICT Consultans to sell hours to clients.</p>
            </mat-expansion-panel>
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title><mat-icon>add</mat-icon><b>How do I create an account on BLUE?</b>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <p>To create an account on BLUE, you will need to visit the company's website and look for the 
                    "Sign Up" or "Create Account" button. Click on it and follow the prompts that appear on 
                    your screen. You will need to provide your name, email address, and password to complete 
                    the registration process. Alternatively, you can also sign up using your LinkedIn account for 
                    convenience.</p>
            </mat-expansion-panel>
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title><mat-icon>add</mat-icon> <b> Can I reset my password if I forget it?</b></mat-panel-title>
                </mat-expansion-panel-header>
                <p>If you forget your password, you can easily reset it by clicking on the "Forgot Password" link 
                    on the login page of BLUE. You will need to provide the email address associated with your 
                    account, and the company will send you a password reset link to that email address. Follow 
                    the instructions in the email to reset your password.</p>
            </mat-expansion-panel>
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title><mat-icon>add</mat-icon><b> How do I update my account information on BLUE?</b></mat-panel-title>
                </mat-expansion-panel-header>
                <p>To update your account information on BLUE, you will need to log in to your account and go 
                    to the "Profile" section. From there, you can edit your name, email address, contact details, 
                    or any other relevant information. Once you have made the necessary changes, remember 
                    to save them before exiting
                </p> 
                
            </mat-expansion-panel>
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title><mat-icon>add</mat-icon><b> Can I use BLUE on multiple devices? </b></mat-panel-title>
                </mat-expansion-panel-header>
                <p>You can use BLUE on multiple devices. Simply log in to your account on each device using 
                    the same credentials, and your account information and settings will be synchronized across 
                    all devices</p>
            </mat-expansion-panel>
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title><mat-icon>add</mat-icon> <b>How can I contact customer support for assistance?</b></mat-panel-title>
                </mat-expansion-panel-header>
                <p>If you need assistance or have questions, you can contact BLUE's customer support team 
                    through the "Contact Us" or "Support" section on their website or mobile app. You can also 
                    reach out to them via email, phone, or live chat on WhatsApp during their business hours.</p>
            </mat-expansion-panel>
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title><mat-icon>add</mat-icon>	<b>Does BLUE do background checks? </b></mat-panel-title>
                </mat-expansion-panel-header>
                <p>Currently BLUE does not perform background checks on candidates, this feature will be available soon.</p>
            </mat-expansion-panel>
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title><mat-icon>add</mat-icon><b>What payment methods are accepted on BLUE?</b></mat-panel-title>
                </mat-expansion-panel-header>
                <p>BLUE accepts secure online payment gateway. During the checkout process, you can choose 
                    the preferred payment method.
                </p>
            </mat-expansion-panel>
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title><mat-icon>add</mat-icon> <b>How do I request an interview with a consultant on BLUE? </b></mat-panel-title>
                </mat-expansion-panel-header>
                <p>To request an interview with a consultant on BLUE, you will need to browse through their 
                    available consultants, select the one you are interested in, and follow the prompts to 
                    request an interview. You can specify the preferred date, time, and duration for the 
                    interview, and their system will notify the consultant. Once confirmed, the interview details 
                    will be shared with you
                </p>
                
            </mat-expansion-panel>
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title><mat-icon>add</mat-icon><b>Is my personal information secure on BLUE?</b></mat-panel-title>
                </mat-expansion-panel-header>
                <p>
                    BLUE takes the security of your personal information seriously and implements industry - standard security measures to protect your data from unauthorized access, such as 
                    encryption, firewalls, and regular system audits. However, it's important to always use
                </p>
                
            </mat-expansion-panel>
        </mat-accordion>
    </div>
</div>

<!--
    <mat-card class="container">
        <mat-card-header>
            <mat-card-title>Frequently Asked Questions</mat-card-title>
        </mat-card-header>
        <mat-card-content>
            <mat-accordion>
                <mat-expansion-panel>
                    <mat-expansion-panel-header>
                        <mat-panel-title>1.	What is BLUE?</mat-panel-title>
                    </mat-expansion-panel-header>
                    <p>Online platform for ICT Consultans to sell hours to clients.</p>
                </mat-expansion-panel>
                <mat-expansion-panel>
                    <mat-expansion-panel-header>
                        <mat-panel-title>2.	Where is BLUE head Office?</mat-panel-title>
                    </mat-expansion-panel-header>
                    <p>Sloane Street, Bryanston, Johannesburg, South Africa.</p>
                </mat-expansion-panel>
                <mat-expansion-panel>
                    <mat-expansion-panel-header>
                        <mat-panel-title>3.	What is BLUE contact number?</mat-panel-title>
                    </mat-expansion-panel-header>
                    <p>010 824 0933</p>
                </mat-expansion-panel>
                <mat-expansion-panel>
                    <mat-expansion-panel-header>
                        <mat-panel-title>5.	Who is the CEO?</mat-panel-title>
                    </mat-expansion-panel-header>
                    <p>Mr Saireshan Govender</p> 
                    <p>https://www.linkedin.com/in/saireshan-govender-ba682027/</p>
                </mat-expansion-panel>
                <mat-expansion-panel>
                    <mat-expansion-panel-header>
                        <mat-panel-title>6.	How much do I pay to use BLUE (Consultants)?</mat-panel-title>
                    </mat-expansion-panel-header>
                    <p>It is completely free for Consultants to use BLUE.</p>
                </mat-expansion-panel>
                <mat-expansion-panel>
                    <mat-expansion-panel-header>
                        <mat-panel-title>7.	What is BLUE fees?</mat-panel-title>
                    </mat-expansion-panel-header>
                    <p>Two types of fees paid by clients. Licenses fees to access the platform (First 3 months is free) and rate per hour fee for the Consultants ranging from R30.00ph to a max rate of R100.00ph.</p>
                </mat-expansion-panel>
                <mat-expansion-panel>
                    <mat-expansion-panel-header>
                        <mat-panel-title>8.	Does BLUE do background checks?</mat-panel-title>
                    </mat-expansion-panel-header>
                    <p>Currently BLUE does not perform background checks on candidates, this feature will be available soon.</p>
                </mat-expansion-panel>
                <mat-expansion-panel>
                    <mat-expansion-panel-header>
                        <mat-panel-title>9.	What are the upcoming features to be added?</mat-panel-title>
                    </mat-expansion-panel-header>
                    <p>i.   Timesheet Module.</p>
                    <p>ii.	Invoicing Module.</p>
                    <p>iii.	Automatic resume capture module.</p>
                    <p>iv.	Payment Module. </p>
                    <p>v.	Background Checks Module.</p>
                </mat-expansion-panel>
                <mat-expansion-panel>
                    <mat-expansion-panel-header>
                        <mat-panel-title>10. What License plans does BLUE offer to its clients?</mat-panel-title>
                    </mat-expansion-panel-header>
                    <p>a.	Licenses Plans:</p>
                    <ul>
                        <li><p>BLUE Lite.</p></li>
                        <li><p>BLUE Plus.</p></li>
                        <li><p>BLUE Premium.</p></li>
                    </ul>
                </mat-expansion-panel>
            </mat-accordion>
        </mat-card-content>
    </mat-card>
-->