import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';


@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.css']
})
export class LogoutComponent implements OnInit {

  constructor(private router: Router) { } // Inject Router in the constructor

  ngOnInit() {
    //this.logout(); // Automatically call logout when this component is initialized
  }

  logout() {
    // Clear local storage
    localStorage.clear();

    this.router.navigate(['/landing']); 

    
  }
}
