<!-- Notification Button -->
<app-notification-dropdown class="notification-button" #notificationDropdown></app-notification-dropdown>


<div class="alert alert-success app-alert update-alert" [class.alert-visible]="alertVisible" role="alert">
  <strong>Application Updated!</strong> The application has been updated. <a class="alert-link" (click)="reload()">Please click here to reload.</a>
  <button type="button" class="close" (click)="dismiss()" aria-label="Close">
      <span aria-hidden="true">&times;</span>
  </button>
</div>



<mat-toolbar id="mainToolbar" class="shadow-sm custom-font responsive fxShow:sm fxHide:xs" *ngIf="isLandingPage()">
  <mat-toolbar-row class="d-flex justify-content-between align-items-center">
    <div class="d-flex align-items-center">
      <a class="nav-link" (click)="scrollToSection('Home')" alt="">
        <img src="assets/logo-landing-min.png" class="logo" alt="Blueapp.blue" matTooltip="Home">
      </a>
    </div>
    <button class=" btn-primary rounded-pill buttoncounter" mat-raised-button *ngIf="isLargerScreen">
      <i class="fa-solid fa-circle" style="color: #8fe922;"></i>  <span id="onlineText">{{ onlineText }}</span>
    </button>

    <div class="nav mx-auto responsiv" *ngIf="isLargerScreen || isNavOpen">



    </div>
    <div class="nav">
      <button *ngIf="!isLoggedIn"
              type="button"
              class="btn btn-primary"
              data-bs-container="body"
              mat-raised-button
              (click)="openLoginModal()"
              autofocus>
        Login
      </button>

      <span class="button-spacing"></span>

      <button *ngIf="!isLoggedIn" class="btn2 btn-primary" mat-raised-button routerLink="auth/login/">
        Register
      </button>
    </div>


    <div class="dropdown" style="width: 3%;">
      <!-- <button class="j dropdown-toggle" type="button" id="dropdownMenu2" data-bs-toggle="dropdown" aria-expanded="false">
      </button> -->
      <i  type="button" id="dropdownMenu2" data-bs-toggle="dropdown" aria-expanded="false" style="size: 30px;font-size:25px ; " class="fa-solid fa-circle-info mt-3"></i>
      <ul class="dropdown-menu" aria-labelledby="dropdownMenu2" style="width: 200px;color: #383c40!important;">
        <li><a class="mat-button " href="mailto:support@blueapp.blue">
          <span class="mat-button-wrapper desc">
            <mat-icon class="mat-icon notranslate material-icons text-weight-bold" role="img" aria-hidden="true">mail</mat-icon>
            support@blueapp.blue
        </span>
      </a> </li>
        <li>
          <a class="mat-button mat-button-base mt-2 " href="tel:0108240933">
            <span class="mat-button-wrapper align-items-center p-1 text-weight-bold desc" >
                <mat-icon class="mat-icon notranslate material-icons " role="img" aria-hidden="true">call</mat-icon>
                010 824 0933
            </span>
        </a>
        </li>
        <mat-divider></mat-divider>
        <li>
          <a class="mat-button mat-button-base mt-2 " href="Version : 2.3.0" >
            <span class="mat-button-wrapper align-items-center p-1 text-weight-bold desc" >
                <mat-icon class="mat-icon notranslate material-icons " role="img" aria-hidden="true">call</mat-icon>
                Version: {{versionNumber}}
            </span>
        </a>
        </li>
      </ul>
    </div>



  </mat-toolbar-row>
</mat-toolbar>


<mat-sidenav-container class="example-container" autosize="true" [hasBackdrop]="false">
  <mat-sidenav #sidenav
              [ngClass]="!isCollapsed ? 'expand' : ''"
              [mode]="isMobile ? 'over' : 'side'"
              [opened]="isMobile ? false : true"
              class="sidenava"
              (keydown.escape)="close('escape')"
              *ngIf="isLoggedIn || isClient || isContractor">
              <button mat-button (click)="close('toggle button')" class="mt-5">
                <img src="/assets/images/BLUE-Logo-Icon-Large-min.png" style="height:50px; width:50px;margin-top: 5px;" class="logo">
                <!-- <mat-icon>reorder</mat-icon> -->
              </button>
    <div class="m-3" >
      <!-- client profile -->
      <div class="col-md-12 user-profile client-profile" *ngIf="isClient" (click)="onMenuItemClicked('Profile', '/main/client-profile')">

      </div>
      <!-- nav links -->
      <mat-list  class="listsidnav" >

        <div class="sidecardcontainer" (click)="autoCloseMobile()">
          <div  class="d-flex sidecard my-1" *ngIf="profileComplete && isClient" matTooltip="Profile" matTooltipPosition="right"  [routerLink]="['/main/client-profile']" routerLinkActive="active" >
            <mat-icon>account_circle</mat-icon>
              <div class="nav-mat-line mx-2" *ngIf="!isCollapsed"   mat-line>Profile</div>
            </div>
        </div>
          <!-- dashboard -->
              <div class="sidecardcontainer" (click)="autoCloseMobile()">
                <div  class="d-flex sidecard my-1" matTooltip="Dashboard" matTooltipPosition="right"  *ngIf=" profileComplete && isClient" [routerLink]="['/main/dashboardclient']" routerLinkActive="active" >
                  <mat-icon>assessment</mat-icon>
                    <div class="nav-mat-line mx-2" *ngIf="!isCollapsed" mat-line>Dashboard</div>
                  </div>
              </div>
              <!-- <mat-icon>account_circle</mat-icon> -->
              <div class="sidecardcontainer" (click)="autoCloseMobile()">
                <div  class="d-flex sidecard my-1"  matTooltip="Add Users" matTooltipPosition="right" *ngIf="profileComplete && isClient "  [routerLink]="['/main/user-roles']" routerLinkActive="active" >
                  <mat-icon>person_add</mat-icon>
                    <div class="nav-mat-line mx-2" *ngIf="!isCollapsed"  mat-line>Add Users</div>
                  </div>
              </div>
          <!-- job profile -->
              <div class="sidecardcontainer" (click)="autoCloseMobile()">
                <div  class="d-flex sidecard my-1" *ngIf="profileComplete &&  isClient" matTooltip="Blue Desk" matTooltipPosition="right" [routerLink]="['/main/job-profiles']" routerLinkActive="active" >
                    <mat-icon>business_center</mat-icon>
                         <div class="nav-mat-line mx-2" *ngIf="!isCollapsed" mat-line> Blue Desk</div>
                      </div>
              </div>
              <div class="sidecardcontainer" (click)="autoCloseMobile()">
                <div class="d-flex sidecard my-1" *ngIf="profileComplete && isClient" matTooltip="Timesheets" matTooltipPosition="right"   [routerLink]="['/main/timesheets']" routerLinkActive="active">
                  <mat-icon>date_range</mat-icon>
                  <div class="nav-mat-line mx-2" *ngIf="!isCollapsed" mat-line> Timesheets</div>
                    </div>
              </div>
              <div class="sidecardcontainer" (click)="autoCloseMobile()">
                <div class="d-flex sidecard my-1" *ngIf=" profileComplete && isClient" matTooltip="Find a Talent" matTooltipPosition="right" [routerLink]="['/main/dashboard']" routerLinkActive="active">
                  <mat-icon  >search</mat-icon>
                  <div class="nav-mat-line mx-2" *ngIf="!isCollapsed" mat-line> Find a Talent</div>
                    </div>
              </div>
          <!-- help and support -->
              <div class="sidecardcontainer" (click)="autoCloseMobile()">
                <div class="d-flex sidecard my-1" *ngIf=" profileComplete && isClient" matTooltip="Help" matTooltipPosition="right"  [routerLink]="['/main/help']" routerLinkActive="active" >
                  <mat-icon >help</mat-icon>
                    <div class="nav-mat-line mx-2" *ngIf="!isCollapsed" mat-line> Help </div>
                  </div>
              </div>
          <!-- terms and conditions -->
              <div class="sidecardcontainer" (click)="autoCloseMobile()">
                <div class="d-flex sidecard my-1"  *ngIf=" profileComplete && isClient" matTooltip="Ts & Cs" matTooltipPosition="right"  [routerLink]="['/main/terms-and-conditions']" routerLinkActive="active" >
                    <mat-icon  >article</mat-icon>
                    <div class="nav-mat-line mx-2" *ngIf="!isCollapsed" mat-line> Ts & Cs</div>
                    </div>
              </div>
          <!-- payment -->
              <div class="sidecardcontainer" (click)="autoCloseMobile()">
                <div class="d-flex sidecard my-1"  *ngIf="profileComplete && isClient"  matTooltip="Subscriptions" matTooltipPosition="right" [routerLink]="['/main/payments']" routerLinkActive="active"   >
                  <mat-icon  >account_balance</mat-icon>
                    <div class="nav-mat-line mx-2" *ngIf="!isCollapsed" mat-line> Subscriptions</div>
                  </div>
              </div>
          <!-- && isAdmin -->
          <!-- logout -->
          <div class="sidecardcontainer mt-2 mb-2" (click)="onClickLogout()"*ngIf=" isClient " >
            <div class="d-flex sidecard my-1">
                <mat-icon >power_settings_new</mat-icon>
                <div class="nav-mat-line mx-2" *ngIf="!isCollapsed" mat-line> Logout</div>
                </div>
          </div>


          <!--Consulatn/Contractor Navigation-->




          <div class="sidecardcontainer mt-2 mb-2" (click)="autoCloseMobile()">
            <div  class="d-flex sidecard my-1"   *ngIf="isContractor &&isLoggedIn" matTooltip="Dashboard" matTooltipPosition="right"  [routerLink]="['/main/user-profile']" >
              <img  src="assets/home.svg" alt="Blueapp search">
                <div class="nav-mat-line mx-2" *ngIf="!isCollapsed" mat-line>Dashboard</div>
              </div>
          </div>

          <!--Job search-->
          <div class="sidecardcontainer mt-4 mb-4" (click)="autoCloseMobile()">
            <div class="d-flex sidecard my-1" *ngIf="isContractor &&isLoggedIn" matTooltip="Job Search" matTooltipPosition="right"[routerLink]="['/main/user-profile/view-jobs']" routerLinkActive="active">
              <img  src="assets/searchgoogle.svg" alt="Blueapp search">
              <div class="nav-mat-line mx-2" *ngIf="!isCollapsed" mat-line> Job Search</div>
                </div>
          </div>

          <div class="sidecardcontainer mt-4 mb-4" (click)="autoCloseMobile()">
            <div class="d-flex sidecard my-1" *ngIf="isContractor &&isLoggedIn" matTooltip="Timesheets" matTooltipPosition="right"  [routerLink]="['/main/timesheets']" routerLinkActive="active">
              <img  src="assets/timesheet.svg" alt="Blueapp search">
              <div class="nav-mat-line mx-2" *ngIf="!isCollapsed" mat-line> Timesheets</div>
                </div>
          </div>

          <!-- help and support -->
          <div class="sidecardcontainer mt-4 mb-4" (click)="autoCloseMobile()">
            <div class="d-flex sidecard my-1" *ngIf="isContractor &&isLoggedIn"  matTooltip="Support" matTooltipPosition="right"[routerLink]="['/main/help']" routerLinkActive="active" >
              <img  src="assets/support.svg" alt="Blueapp search">
                <div class="nav-mat-line mx-1" *ngIf="!isCollapsed" mat-line> Support </div>
              </div>
          </div>

          <!-- terms and conditions -->
          <div class="sidecardcontainer mt-4 mb-4" (click)="autoCloseMobile()">
            <div class="d-flex sidecard my-1"  *ngIf="isContractor &&isLoggedIn"  matTooltip="T's&C's" matTooltipPosition="right"[routerLink]="['/main/terms-and-conditions']" routerLinkActive="active" >
              <img  src="assets/Terms.svg" alt="Blueapp search">
                <div class="nav-mat-line mx-2" *ngIf="!isCollapsed" mat-line> T's&C's</div>
                </div>
          </div>

          <!-- logout -->
          <div class="sidecardcontainer mt-4 mb-4" (click)="onClickLogout()" matTooltip="Logout" matTooltipPosition="right" *ngIf="isContractor &&isLoggedIn" >
            <div class="d-flex sidecard my-1">
              <img  src="assets/logout.svg" alt="Blueapp search">
                <div class="nav-mat-line mx-2" *ngIf="!isCollapsed" mat-line> Logout</div>
                </div>
          </div>


      </mat-list>
    </div>

  </mat-sidenav>

  <mat-sidenav-content>
<!-- <button mat-button (click)="sidenav.open()"></button> -->

  <div id="homeMain" role="main" [class.public]="!isLoggedIn">
    <div class="main-item-view mainview" [class.top-offset]="!isLoggedIn">
        <!-- charles added this bootsrap alert - the close button does not work -->
        <div *ngIf="subscriptionFeeOutstanding && isLoggedIn" class="alert alert-warning alert-dismissible">
            <strong>Warning!</strong> Your monthly subscription fee is outstanding.
        </div>
        <button mat-button *ngIf="isMobile" (click)="close('toggle button')" class="mt-3">
          <img src="/assets/images/BLUE-Logo-Icon-Large-min.png" style="height:50px; width:50px;" class="logo">
          <!-- <mat-icon>reorder</mat-icon> -->
        </button>
        <router-outlet></router-outlet>
        <div class="footer col-12 pt-1" *ngIf="isLandingPage()">
          <div class="col-md-4 footerFont mb-2">
              <img class="footerimg mb-2" style="margin-left: 10%;" src="/assets/icons/location.png" alt="" srcset="">
              <p class="mb-0 small-text">
                SPACES Waterfall <br>
                Ground &amp; 1st Floor, 22 Magwa Crescent, Gateway West<br>
                Waterfall City, Midrand, Johannesburg, Gauteng
              </p>
            </div>
            <div class="row footerSocial">
              <div class="justify-content-center d-flex">
                 <a href="http://www.facebook.com/"><img class="footerimg mr-2" src="/assets/icons/facebook.png" alt="" srcset=""></a>
                 <a href="http://https://www.instagram.com/"><img class="footerimg mr-2"  src="/assets/icons/instagram.png" alt="" srcset=""></a>
                 <a href="http://www.linkedin.com/company/blueapp-blue/"><img class="footerimg mr-2"  src="/assets/icons/linkedin.png" alt="" srcset=""></a>
              </div>
            </div>
            <div class="footerFont align-items-center">
                  <p class="mb-0 small-text">
                      Developed by <a href="https://bluepearl.co.za" rel="noopener noreferrer" target="_blank">Blue Pearl</a>
                  </p>
                  <p class="mb-0 small-text">
                      <a  href="{{privacyPolicyUrl}}" rel="noopener noreferrer" target="_blank">View our Privacy Policy</a>
                  </p>
            </div>
        </div>
    </div>
  </div>
  </mat-sidenav-content>
</mat-sidenav-container>
