<div class="container pt-3">
    <div class="row pb-3">
      <div class="col-sm-12">
        <button mat-stroked-button (click)="goBack()">
          <mat-icon>arrow_back</mat-icon> Back
        </button>
      </div>
    </div>
  
    <div class="hero-card">
      <div class="hero-card-content">
        <div class="text-left">
          <h3 class="text-left">
            Let’s get going, <br> leave the admin to us <br>We will sort out the following for you!
          </h3>
        </div>
        <div class="alert alert-danger mt-3 text-center" *ngIf="error">
          {{ errorMessage }}
        </div>
      </div>
      <div class="hero-card-image">
        <img src="assets/Hiring-cuate.png" alt="Hero Image" />
      </div>
    </div>
    <div class="row">
      <mat-stepper orientation="vertical" >
        <mat-step *ngFor="let step of steps">
          <ng-template matStepLabel>{{ step.name }}</ng-template>
          <ng-template matStepContent *ngIf="step.stepNo >= 1">
            <button class="ml-5" (click)="requestInterview()" mat-stroked-button *ngIf="step.stepNo == 1">
              <mat-icon>group_add</mat-icon> Request Interview
            </button>
            <!-- <button mat-button matStepperNext></button> -->
          </ng-template>
      </mat-step>
      
      </mat-stepper>
          
            <!-- <div *ngFor="let step of steps" class="col-sm-11 col-md-6 col-lg-7 col-lg-7">
              <mat-card class="custom-card">
               
                <mat-card-content style="background-color: white;box-shadow: 0 2px 6px 0 rgb(218 218 253 / 65%), 0 2px 6px 0 rgb(206 206 238 / 54%);" class="right-side">
                 <div>
                  <div class="d-flex left-side" [style.background-color]="getBackgroundColor(step.stepNo)">
                    <div class="digit-background">{{ step.stepNo }}</div>
                  </div>
                  <div class="cardConten d-flex justify-content-center">
                   
                    <button class="ml-5" (click)="requestInterview()" mat-stroked-button *ngIf="step.stepNo == 1">
                      <mat-icon>group_add</mat-icon> Request Interview
                    </button>
                    <p class="text-center" *ngIf="step.stepNo > 1"><b>{{ step.name }}</b></p>
                  </div>
                 </div>
                 
                </mat-card-content>
              </mat-card>
            
           
         
        </div> -->
    </div>

    <div class="row pb-2 mt-3">
        <div class="col-md-6 text-center">
          <a href="mailto:support@blueapp.blue" mat-flat-button>
            <mat-icon>mail</mat-icon> Support@blueapp.blue
          </a>
        </div>
        <div class="col-md-6 text-center">
          <a href="tel:0108240933" mat-flat-button>
            <mat-icon>phone</mat-icon> 0108240933
          </a>
        </div>
    </div>
      
      
</div>
  