<mat-progress-bar *ngIf = "loading"  mode="indeterminate"></mat-progress-bar>
<div class="container pt-3">
    <!-- back button -->
    <div class="row pb-3">
        <div class="col-sm-12">
            <a mat-stroked-button routerLink="/main/dashboard/client-view/{{companyId}}/jobs"><mat-icon>arrow_back</mat-icon> Back </a>
        </div>
    </div>
    
    <div class="alert alert-danger mt-3 text-center" *ngIf="error">
        Profile Could not be loaded. Please try again
    </div>

    <mat-card *ngIf="loading">
        <mat-card-content>
            <p class="text-center">Please Wait: Loading Profile</p>
        </mat-card-content>
    </mat-card>

    <mat-card *ngIf="!loading && jobProfileExpired" class="mb-3">
        <mat-card-content>
            <p class="text-center">This job profile has either been fulfilled or the closing date has passed.</p>
        </mat-card-content>
    </mat-card>

    <mat-card class="candidate-cv col-md-12 col-lg-12 col-sm-12 p-0" *ngIf="!loading">  
        <mat-card-content>
            <div class="row mx-0 d-flex">
                <div class="col-sm-12 col-md-4 col-lg-3 d-flex flex-column justify-content-start align-content-center cv-cards col-left">
                    <h2>Summary</h2>
                    <h6>Closing Date: {{jobProfile.closingDate | date}} </h6>
                    <h6>Rate: R{{jobProfile.ratePerHour}}/hour</h6>
                    <h6>Contract Duration:{{jobProfile.contractDurationInMonths}} Months</h6>
                </div>
                <div class="col-sm-12 col-md-8 col-lg-9 col-main">
                    <div class="col-sm-12 pt-3">
                        <h1 class="">{{jobProfile.title}}</h1>
                        <h2><strong>Job Description</strong></h2>
                        <p>{{jobProfile.description}}</p>
                        <mat-divider></mat-divider>
                        <div class="row">
                            <div class="col-md-12 mb-2">
                                <h2>Responsibiilities</h2>                            
                                <p><strong>The applying candidate will have the following responsibilities</strong></p>
                                <div *ngFor = "let responsibility of jobProfile.responsibilities">
                                    <p>{{responsibility.description}}</p>
                                </div>
                                <p *ngIf="!jobProfile.responsibilities || jobProfile.responsibilities.length == 0">No responsibilities have been listed</p>
                                <mat-divider></mat-divider>
                            </div>
                            <div class="col-md-12 mb-2">
                                <h2>Skills</h2>                            
                                <p><strong>The applying candidate should have the following skills</strong></p>
                                <div *ngFor = "let skill of jobProfile.skills">
                                    <p>{{skill.description}}</p>
                                </div>
                                <p *ngIf="!jobProfile.skills || jobProfile.skills.length == 0">No certifications have been listed</p>
                                <mat-divider></mat-divider>
                            </div>
                            <div class="col-md-12 mb-2">
                                <h2>Certifications</h2>
                                <p><strong>The applying candidate should have one of the following certifications</strong></p>
                                <div *ngFor = "let certification of jobProfile.certifications">
                                    <p>{{certification.description}}</p>
                                </div>
                                <p *ngIf="!jobProfile.certifications || jobProfile.certifications.length == 0">No certifications have been listed</p>
                                <mat-divider></mat-divider>
                            </div>
                            <div class="col-md-12">
                                <h2>Qualifications</h2>
                                <p><strong>The applying candidate should have one of the following qualifications</strong></p>
                                <div *ngFor = "let qualification of jobProfile.qualifications">
                                    <p>{{qualification.description}}</p>
                                </div>
                                <p *ngIf="!jobProfile.qualifications || jobProfile.qualifications.length == 0">No qualifications have been listed</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mx-0" *ngIf="!jobProfileExpired">
                <div class="col-sm-12">
                    <mat-divider></mat-divider>
                    <p class="text-muted my-3">If you wish to apply for this position, please log in to your account or sign up for a new account below.</p>
                    <div class="sign-contract">
                        <a mat-flat-button routerLink = "/auth/login"> <mat-icon>lock_open</mat-icon> Login </a>
                        <a class="ml-2" mat-raised-button routerLink="/auth/register/consultant"><mat-icon>person_add</mat-icon> Register Consultant Account </a>
                    </div>
                </div>
            </div>
        </mat-card-content>
    </mat-card>
</div>