

<div class="row">

    <div class="col-md-6 col-lg-3 mx-left contractorView" *ngFor="let contractorProfile of contractorProfiles">
        <mat-card>
            <mat-card-header>
                <mat-card-title>{{contractorProfile.id}}</mat-card-title>
                <mat-card-subtitle>{{contractorProfile.title}}</mat-card-subtitle>
            </mat-card-header>
            <img mat-card-image src="{{contractorProfile.image}}" alt="Contractor Photo">
            <mat-card-content>
                <p>{{contractorProfile.description}}</p>
            </mat-card-content>
            <mat-card-actions>
                <button mat-button>VIEW</button>
            </mat-card-actions>
        </mat-card>
    </div>
</div>
  