import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { catchError } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';


export interface AppNotification {
  id: string;
  message: string;
  userId: string;
  read: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  private unreadNotificationsCount = new BehaviorSubject<number>(0);
  unreadCount$: Observable<number> = this.unreadNotificationsCount.asObservable();

  private apiUrl = `${environment.baseUrl}`;

  constructor(private http: HttpClient, private snackBar: MatSnackBar) {}

  getNotifications(userId: string): Observable<AppNotification[]> {
  return this.http.get<AppNotification[]>(`${this.apiUrl}/profile/notifications?userId=${userId}`);
}


markAsRead(ids: string[]) {
  ids.forEach(id => {
    this.http.post(`${this.apiUrl}/profile/mark-as-read?id=${id}`, null, { responseType: 'text' })
      .pipe(
        catchError(error => {
          console.error('Error marking as read:', error);
          this.snackBar.open(`Error marking as read: ${error.message}`, 'Dismiss', {
            duration: 5000, // 5 seconds
            verticalPosition: 'top',
            horizontalPosition: 'right'
          });
          throw error;
        })
      )
      .subscribe(response => {
        this.snackBar.open('Notification marked as read successfully', 'Dismiss', {
          duration: 5000, // 5 seconds
          verticalPosition: 'top',
          horizontalPosition: 'right'
        });
        // Additional handling if needed
      });
  });
}

  addNotification(message: string, userId: string): Observable<any> {
    const notification: AppNotification = {
      message,
      read: false,
      userId,
      id: ''
    };
    return this.http.post(`${this.apiUrl}/profile/addNotification`, notification);
  }

  updateUnreadCount(notifications: AppNotification[]): void {
    const unreadCount = notifications.filter(notification => !notification.read).length;
    this.unreadNotificationsCount.next(unreadCount);
  }
}
