<div class="d-flex ContNotifications">
    <!--When there is not notifications-->
    <div class="container mt-4">
        <div class="row justify-content-center">
          <div class="col-sm-12 col-md-6">
            <div class="card shadow p-4">
              <div class="notification-image">
                <img src="assets/notification-removebg-preview.png" alt="Notification">
              </div>
              <p class="text-center mt-4 mb-0">
                Nothing right now. Check back later!<br>
                This is where we’ll notify you about your job applications and other useful information to help you with your job search.
              </p>
              <div class="text-center mt-4">
                <button mat-raised-button class="findButton" routerLink="/main/job-profiles">
                  Find Jobs
                </button>
              </div>
            </div>
          </div>
        </div>
    </div>
    </div>


