import { HttpClient } from '@angular/common/http';
import { Component, ViewEncapsulation } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { BASE_URL } from 'src/app/app.provider';
import { ClientMatchRequest } from 'src/app/shared/company-profile-models';
import { SessionService } from 'src/app/shared/session.service';
import { Inject } from '@angular/core';
import { style } from '@angular/animations';

@Component({
  selector: 'app-next-steps',
  templateUrl: './next-steps.component.html',
  styleUrls: ['./next-steps.component.css'],
})
export class NextStepsComponent {

  steps: any[] = [
    { stepNo: 1, name: 'Interviewing' },
    { stepNo: 2, name: 'Contracting' },
    { stepNo: 3, name: 'Back ground checks' },
    { stepNo: 4, name: 'Timesheet Management.' },
    { stepNo: 5, name: 'Invoicing and Billing to the client.' },
    { stepNo: 6, name: 'Collection from client. ' },
    { stepNo: 7, name: 'Payroll ' },
    { stepNo: 8, name: 'Payments to SARS. Contracts longer than 3months.' },
    
];

error = false;
errorMessage = "";

constructor(@Inject(BASE_URL) protected baseUrl: string ,private sessionClass: SessionService, private router: Router, private http: HttpClient, private snackBar: MatSnackBar) { }

ngOnInit() {}

  goBack() {
    this.router.navigateByUrl('main/candidate');
  }

  requestInterview() {
    let data: ClientMatchRequest = {
      identifier: this.sessionClass.getCurrentJobDetails(),
      clientEmail: this.sessionClass.getUsername(),
      candidateNum: this.sessionClass.getCandidate().candidateNum
    };
    this.error = false;
    this.errorMessage = '';
    this.http
      .post(this.baseUrl + '/profile/request-interview', data)
      .subscribe(
        (response) => {
          
          this.snackBar.open('Submitted request successfully', '', {
            duration: 8000
          });
        },
        (error) => {
          
          this.errorMessage = error.error.message;
          this.error = true;
        }
      );
  }

  getImageForStep(stepNo: number): string {
    switch (stepNo) {
      case 1:
        return 'assets/Resume-amico (1).png';
      case 2:
        return 'assets/Signing a contract-rafiki.png';
      case 3:
        return 'assets/Fingerprint-cuate.png';
      case 4:
        return 'assets/Time management-amico.png';
      case 5:
        return 'assets/Invoice-pana.png';
      case 6:
        return 'assets/E-Wallet-amico.png';
      case 7:
        return 'assets/Click here-bro.png';
      case 8:
        return 'assets/Tax-bro.png';
      /* case 3:
        return 'assets/Fingerprint-cuate.png'; */
      default:
        return 'assets/BLUE-Logo-Large-removebg-preview 2.png'; // Use a default image if needed
    }
  }

  getBackgroundColor(stepNo: number): string {
    switch (stepNo) {
      case 1:
        return '#27ae60'; // Example hex color code
      /* case 2:
        return '#3498db'; // Example hex color code
      case 3: */
        return '#e74c3c'; // Example hex color code
      // Add cases for other steps and their respective hex color codes
      default:
        return '#3498db'; // Use a default color if needed
    }
  }
  
}