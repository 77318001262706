<div class=" dashboardDiv">
    <div>
        <div class="dashboard dashboard-board-bg container">
            <div class="row">
                <div class="col-sm-12 col-md-12 col-lg-4 d-flex flex-column">                    
                    <mat-card class="dashboard-card"> 
                    </mat-card>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-4 d-flex flex-column">                  
                    <mat-card class="dashboard-card"> 
                    </mat-card>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-4 d-flex flex-column">                   
                    <mat-card class="dashboard-card"> 
                    </mat-card>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12 col-md-12 col-lg-4 d-flex flex-column">                    
                    <mat-card class="dashboard-card"> 
                    </mat-card>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-4 d-flex flex-column">                  
                    <mat-card class="dashboard-card"> 
                    </mat-card>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-4 d-flex flex-column">                   
                    <mat-card class="dashboard-card"> 
                    </mat-card>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12 col-md-12 col-lg-4 d-flex flex-column">                    
                    <mat-card class="dashboard-card"> 
                    </mat-card>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-4 d-flex flex-column">                  
                    <mat-card class="dashboard-card"> 
                    </mat-card>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-4 d-flex flex-column">                   
                    <mat-card class="dashboard-card"> 
                    </mat-card>
                </div>
            </div>
        </div>
    </div> 
</div>