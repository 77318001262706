<div class="row mb-4 mt-4">
    <div class="col">
        <!-- <h4><b>Find a Talent</b></h4> -->
        <h4 style="color: #017DB5;"></h4>
        <h4> Consultant Profile</h4>



    </div>

</div>








<mat-card class="candidateCv col-md-12 col-lg-12 col-sm-12 p-0">

    <div id="profile-section" class="row mx-0 d-flex">
        <div class="col-sm-12 col-md-4 col-lg-3 d-flex flex-column justify-content-start align-content-center cvCards "
            style="background-color: whitesmoke;">
            <div class="bg-white p-3 mt-5"
                style="background-color: white;box-shadow: 0 2px 6px 0 rgb(218 218 253 / 65%), 0 2px 6px 0 rgb(206 206 238 / 54%);">
                <div class="profile-picture-holder p-5">
                    <user-profile-picture [emailAddress]="userEmail"
                        [defaultText]="userInitials"></user-profile-picture>
                </div>
                <!-- <h2>Personal</h2> -->
                <h6 *ngIf="hasProfile">{{candidate.nationality}}</h6>
                <h6 *ngIf="hasProfile">{{candidate.gender}}, {{candidate.title}}</h6>
                <h6 *ngIf="hasProfile">{{candidate.province}}, {{candidate.city}}</h6>
                <h6 *ngIf="hasProfile">
                    <!-- <div class="col-1">
                <i class="bi bi-clock"></i>
            </div> -->
                    Availability: {{candidate.noticePeriod}}
                </h6>
                <!-- <div class=" d-flex justify-content-center">
                <a class="btn  btn-md " style="background-color:white; color: #017DB5;border-color: #017DB5;" href="#" role="button"><b>Hire</b> </a>

            </div> -->
            </div>


        </div>
        <div class="col-sm-12 col-md-8 col-lg-9  outline-none" style="background-color:whitesmoke;">

            <div class="col-sm-12 pt-3">
                <h5 *ngIf="hasProfile" class="">{{candidate.candidateNum}}</h5>
                <!-- <mat-divider></mat-divider> -->
                <div class="row">
                    <div class="col-md-12 mb-2 pb-3">
                        <button class="btn" mat-button (click)="accordion.openAll()">Expand All</button>
                        <button class="btn" mat-button (click)="accordion.closeAll()">Collapse All</button>
                        <button class="btn" mat-button (click)="downloadResume()">Download Resume</button>
                        <mat-accordion multi
                            style="background-color: white;box-shadow: 0 2px 6px 0 rgb(218 218 253 / 65%), 0 2px 6px 0 rgb(206 206 238 / 54%);">
                            <mat-expansion-panel style="color: white;">
                                <mat-expansion-panel-header>
                                    <mat-panel-title>
                                        Skills
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <div class="col-md-12 mb-2 pb-3">


                                    <div *ngIf="getSkills.length > 0">

                                        <div style="color: white;" *ngFor="let skillGroup of getGroupedSkills">
                                            <div class="row ">
                                                <div>
                                                    <span class="badge rounded-pill mx-1 "
                                                        style="background-color: #BCE6FF; color: black;
                                                  box-shadow:  0 2px 6px 0 rgb(218 218 253 / 65%), 0 2px 6px 0 rgb(206 206 238 / 54%)"
                                                        *ngFor="let skill of skillGroup.data">
                                                        {{skill.description}}</span>

                                                </div>
                                                <!-- <div>
                                              <span class="badge rounded-pill mx-1 " style="background-color: #BCE6FF; color: black;
                                              box-shadow:  0 2px 6px 0 rgb(218 218 253 / 65%), 0 2px 6px 0 rgb(206 206 238 / 54%)" *ngFor="let skill of skillGroup.data" class="col-sm-12 col-md-6 col-lg-4">
                                                  <p><strong>{{skill.description}}</strong>
                                                      </p></span>
                                              </div> -->
                                            </div>
                                        </div>
                                    </div>

                                    <p *ngIf="getSkills.length == 0">No skills have been listed by this candidate</p>
                                </div>
                            </mat-expansion-panel>
                            <mat-expansion-panel style="background-color:#017DB5; color:white;">
                                <mat-expansion-panel-header>
                                    <mat-panel-title style="color:white; ">
                                        Certifications
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <div class="col-md-12 mb-2">

                                    <div *ngFor="let certificate of getCertifications">
                                        <p class='title'><strong>{{certificate.name}}</strong></p>
                                        <p style="font-size: 15px;"> {{certificate.authority}}&nbsp;</p>
                                        <p style="font-size: 15px;">Obtained Date: {{certificate.attained | date :
                                            'dd-MMM-yy'}}</p>
                                    </div>
                                    <p *ngIf="getCertifications.length == 0">No certifications have been listed by this
                                        candidate</p>
                                    <!-- <mat-divider></mat-divider> -->
                                </div>
                            </mat-expansion-panel>
                            <mat-expansion-panel>
                                <mat-expansion-panel-header>
                                    <mat-panel-title>
                                        Work History
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <div class="col-md-12 mb-2">

                                    <p><strong>Total Work Experience: &nbsp;</strong>{{getYearsOfExperience}} years,
                                        {{getMonthsOfExperience}} months</p>

                                    <div *ngFor="let work of getWorkHistory let i = index"
                                        [ngClass]="{'alternate-row': isAlternateRow(i)}">
                                        <p class='title mt-3'><strong>{{work.role}}</strong> <br> </p>
                                        <p style="font-size: 15px;">{{work.company}}&nbsp;</p>
                                        <p style="font-size: 15px;" *ngIf="work.to != null">Start Date: {{work.from |
                                            date : 'dd-MMM-yy'}} - End Date: {{work.to |date : 'dd-MMM-yy'}}</p>
                                        <p style="font-size: 15px;" *ngIf="work.to == null">Start Date: {{work.from |
                                            date : 'dd-MMM-yy'}} - End Date: <i>Current</i></p>
                                    </div>
                                    <p *ngIf="getWorkHistory.length == 0">No work history has been listed by this
                                        candidate</p>
                                    <!-- <mat-divider></mat-divider> -->
                                </div>
                            </mat-expansion-panel>
                            <mat-expansion-panel style="background-color:#017DB5;color:white; ">
                                <mat-expansion-panel-header>
                                    <mat-panel-title style="color:white; ">
                                        Qualifications
                                    </mat-panel-title>

                                </mat-expansion-panel-header>
                                <div class="col-md-12" >

                                    <div *ngFor="let qualification of getQualifications">
                                        <p class='title'><strong>{{qualification.name}}</strong></p>
                                        <p style="font-size: 15px;">{{qualification.institution}}&nbsp;</p>
                                        <p style="font-size: 15px;">Obtained Date: {{qualification.attained | date :
                                            'dd-MMM-yy'}}</p>
                                    </div>
                                    <p *ngIf="getQualifications.length == 0">No qualifications have been listed by this
                                        candidate</p>
                                </div>
                            </mat-expansion-panel>
                        </mat-accordion>
                        <!-- <mat-divider></mat-divider> -->

                    </div>


                </div>
            </div>
            <div class="row mx-0 bg-green" style="background-color: whitesmoke;" *ngIf="isClientUser && isUserActive">
                <div class="col-sm-12 mt-3"
                    style="background-color: white;box-shadow: 0 2px 6px 0 rgb(218 218 253 / 65%), 0 2px 6px 0 rgb(206 206 238 / 54%);">
                    <!-- <mat-divider></mat-divider> -->

                    <div class="alert alert-danger mt-3 text-center" *ngIf="error">
                        {{errorMessage}}
                    </div>

                    <p class="text-muted mb-0 mt-3">If you wish to hire this candidate, please read the terms and
                        conditions and then proceed.</p>
                    <div class="signContract">
                        <!-- terms and conditions -->
                        <section class="example-section">
                            <mat-checkbox [(ngModel)]="disabled" class="example-margin">I have read the <a
                                    class="mat-checkbox-label-link" href="javascript:void(0);"
                                    (click)="viewTerms()">Terms and Conditions</a></mat-checkbox>
                        </section>
                        <button [disabled]="!disabled" (click)="onViewNextSteps()" mat-stroked-button>
                            <mat-icon>check</mat-icon> Proceed
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>

</mat-card>
